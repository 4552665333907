import React, { Component } from "react";

import Table from "../Charts/FomatterChars";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Tab";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";
class Charts extends Component {
  constructor() {
    super();
    this.state = {
      value: 0
    };
  }

  handleChangeTab = async (event, newValue) => {
    await this.setState({
      value: newValue
    });
  };
  a11yProps = index => {
    return {
      id: `table-tab-${index}`,
      "aria-controls": `table-tabpanel-${index}`
    };
  };
  render() {
    return (
      <div className={this.props.classes.root}>
        <AppBar position="static" className={this.props.classes.tabHeader}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            aria-label="table tabs example"
          >
            <Tab name="Diario" label="PRIMEROS" {...this.a11yProps(0)} />
            <Tab label="ÚLTIMOS" name="Semanal" {...this.a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0} name="table"
          style= {{overflowY: 'auto'}}
        >
          <Table
            id={this.props.id}
            codigo={this.props.codigo}
            data={this.props.data.dataDESC}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={1} name="table"
          style= {{overflowY: 'auto'}}
        >
          <Table
            id={this.props.id}
            codigo={this.props.codigo}
            data={this.props.data.dataASC}
          />
        </TabPanel>
      </div>
    );
  }
}
export default withStyles(style)(Charts);
