import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import * as ingresosAction from "../Actions/IngresosAcions";
import * as salidasAction from "../Actions/SalidasActions";
import { withStyles } from "@material-ui/core/styles";
import style from "../assets/jss/disal-main/Layouts/Salidas";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { DatePicker, message } from "antd";
import iconMore from "../assets/img/caf48388ba3046a689f94ed8901f39f6.png";
import iconMinus from "../assets/img/icons8-menos-24.png";
import Modals from "../Component/Modal/ModalSalida";
import ModalsView from "../Component/Modal/ModalSalidaView";
import ModalsD from "../Component/Modal/ModalDocuments";
import Pagination from "../Component/Pagination/PaginationPatio";
import "antd/dist/antd.css";
import moment from "moment";
import { URLSalidas, URLDocuments } from "../other/apis";
import LabelDate from "./../Component/LabelDate";
const { GetPatios, GetClients } = ingresosAction;
const { GetSalidasVehicles, GetSalidasStatus } = salidasAction;
class Salidas extends Component {
  constructor() {
    super();
    this.state = {
      idMiningSite: localStorage.getItem("miningSiteId"),
      valueSelectClient: localStorage.getItem("miningSiteId"),
      valueSelectVehiculo: 1,
      valueSelectPatios: 0,
      patios: [],
      pager: 1,
      total: "",
      valueSelectStatus: 0,
      FromDay: moment().subtract(1, "day").format("YYYY-MM-DD"),
      ToDay: moment().format("YYYY-MM-DD"),
      data: [],
      expandedRows: [],
      dataModal: null,
      dataModalD: "",
      openModalDoc: false,
      openModalSal: false,
      openModalConf: false,
      visible: false,
      visibleD: false,
      visibleV: false,
      documentData: [],
      message: "",
      visibleAlert: false,
      order:
        localStorage.getItem("type") === "2" ||
        localStorage.getItem("type") === "5"
          ? 3
          : 4,
      valueModalVehiculo: "",
      valueModalDestino: "",
    };
  }
  async componentDidMount() {
    const { ingresosReducer, salidasReducer } = this.props;
    if (!ingresosReducer.patios.length) {
      await this.props.GetPatios();
    }
    if (!salidasReducer.vehicles.length) {
      await this.props.GetSalidasVehicles();
    }
    if (!ingresosReducer.client.length) {
      await this.props.GetClients();
    }
    if (!salidasReducer.status.length) {
      await this.props.GetSalidasStatus();
    }
    await this.getSalidas();
  }

  componentWillReceiveProps = async () => {
    await this.setState({
      pager: 1,
    });
  };
  componentWillUnmount = async () => {
    await this.setState({
      pager: 1,
    });
  };
  getSalidas = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    const dateOne =
      localStorage.getItem("type") === "2"
        ? 0
        : moment(this.state.FromDay).utc().valueOf();
    const dateSecond =
      localStorage.getItem("type") === "2"
        ? 0
        : moment(this.state.ToDay).utc().valueOf();
    const respuestaSalidas = await axios.get(
      URLSalidas(
        this.state.pager,
        this.state.valueSelectClient,
        this.state.valueSelectPatios,
        this.state.valueSelectStatus,
        dateOne,
        dateSecond,
        this.state.order
      ),
      { headers }
    );
    await this.setState({
      total: respuestaSalidas.data.result.totalPages,
      data: respuestaSalidas.data.result.outputs,
    });
  };
  handleClickOpenModal = async (e) => {
    await this.setState({
      dataModal: e.currentTarget.id,
      valueModalDestino: e.currentTarget.dataset.name,
      valueModalVehiculo: e.currentTarget.name,
      visible: true,
    });
  };
  handleClickOpenModalView = async (e) => {
    await this.setState({
      dataModal: e.currentTarget.id,
      valueModalDestino: e.currentTarget.dataset.name,
      valueModalVehiculo: e.currentTarget.name,
      openModalSal: true,
    });
  };
  handleClickOpenModalDoc = async (e) => {
    const id = e.currentTarget.id;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };

    const respuestaSalidasDocuemts = await axios.get(URLDocuments(id), {
      headers,
    });
    await this.setState({
      documentData: respuestaSalidasDocuemts.data.data,
      dataModal: id,
      openModalDoc: true,
    });
  };
  handleSelectPatios = async (e) => {
    await this.setState({ valueSelectPatios: e.target.value });
    await this.getSalidas();
  };
  handleSelectClient = async (e) => {
    await this.setState({ valueSelectClient: Number(e.target.value) });
    await this.getSalidas();
  };
  handleSelectStatus = async (e) => {
    await this.setState({ valueSelectStatus: e.target.value, pager: 1 });
    await this.getSalidas();
  };
  onChangePage = async (data) => {
    await this.setState({
      pager: data.page,
    });
    await this.getSalidas();
  };
  handleRowClick = (rowID) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowID);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowID)
      : currentExpandedRows.concat(rowID);
    this.setState({ expandedRows: newExpandedRows });
  };

  renderItemsIcon = (rowId) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <img src={iconMinus} alt="icon-table" />;
    } else {
      return <img src={iconMore} alt="icon-table" />;
    }
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleCloseDoc = (e) => {
    this.setState({
      openModalDoc: false,
    });
  };
  handleCloseConf = (e) => {
    this.setState({
      openModalSal: false,
    });
  };
  handleOkV = (e) => {
    this.setState({
      visibleV: false,
    });
  };
  handleCloseView = (e) => {
    this.setState({
      openModalSal: false,
    });
  };
  renderDetailsItem = (item) => {
    return (
      <div className={this.props.classes.detailTable}>
        <Grid container>
          <LabelDate name="SALIDA PROG." value={item.programatedDeparture} />
          <LabelDate name="SALIDA REAL" value={item.departureReal} />
          <LabelDate name="LLEGADA PROG." value={item.programatedArrival} />
          <LabelDate name="LLEGADA REAL" value={item.arrivalReal} />
          <LabelDate name="RETORNO PROG." value={item.programatedReturn} />
          <LabelDate name="RETORNO REAL" value={item.returnReal} />
          <LabelDate name="FIN PROG." value={item.programatedEnd} />
          <LabelDate name="FIN REAL" value={item.endReal} />
        </Grid>
        <Grid container style={{ justifyContent: "flex-end" }}>
          <Grid item sm={2} style={{ textAlign: "right" }}>
            <button
              type="button"
              id={item.outputId}
              style={{ textDecoration: "underline" }}
              onClick={this.handleClickOpenModalDoc}
            >
              Ver documentos
            </button>
          </Grid>

          {item.outputStateId === 1 && localStorage.getItem("type") === "2" ? (
            <Grid item sm={2}>
              <button
                type="button"
                id={item.outputId}
                name={item.vehicleId}
                data-name={item.destinyId}
                style={{ textDecoration: "underline" }}
                onClick={this.handleClickOpenModal}
              >
                Aprobar/Rechazar
              </button>
            </Grid>
          ) : (
            <Grid item sm={2}>
              <button
                type="button"
                id={item.outputId}
                name={item.vehicleId}
                data-name={item.destinyId}
                style={{ textDecoration: "underline", padding: "10px 40px" }}
                onClick={this.handleClickOpenModalView}
              >
                Ver Detalle
              </button>
            </Grid>
          )}
          <Grid item sm={1}></Grid>
        </Grid>
      </div>
    );
  };
  renderItems = (item, index) => {
    const clickCallback = () => this.handleRowClick(index);
    const itemRows = [
      <TableRow key={"row-data-" + index}>
        <TableCell>
          {item.plateNumber !== null ? item.plateNumber : "-"}
        </TableCell>
        <TableCell>
          {item.vehicleType !== null ? item.vehicleType : "-"}
        </TableCell>
        <TableCell>{item.origin !== null ? item.origin : "-"}</TableCell>
        <TableCell>{item.destiny !== null ? item.destiny : "-"}</TableCell>
        <TableCell>
          {item.driverName !== null ? item.driverName : "-"}
        </TableCell>
        <TableCell>
          {item.departureReal !== null ? item.departureReal : "-"}
        </TableCell>
        <TableCell>
          {item.arrivalReal !== null ? item.arrivalReal : "-"}
        </TableCell>
        <TableCell>
          <p
            className={
              "statusTableSalidas " +
              (item.outputStateId === 1
                ? " background-fucsia"
                : item.outputStateId === 7
                ? " background-red"
                : item.outputStateId === 2
                ? " background-yellow-secondary"
                : item.outputStateId === 6
                ? " background-green-light"
                : " background-sky-blue")
            }
            style={{ color: "#fff" }}
          >
            {item.outputState}
          </p>
        </TableCell>
        <TableCell>
          <button className="btn-table-salidas" onClick={clickCallback}>
            {this.renderItemsIcon(index)}
          </button>
        </TableCell>
      </TableRow>,
    ];

    if (this.state.expandedRows.includes(index)) {
      itemRows.push(
        <TableRow
          key={"row-expanded-" + index}
          style={{ background: "#f9f9f9" }}
        >
          <TableCell colSpan="10">{this.renderDetailsItem(item)}</TableCell>
        </TableRow>
      );
    }
    return itemRows;
  };
  handleChangeInputFrom = async (e) => {
    if (moment(this.state.ToDay) >= moment(e)) {
      await this.setState({ FromDay: moment(e).format("YYYY-MM-DD") });
      await this.getSalidas();
    } else {
      message.error(
        `La fecha debe ser igual o menor a la fecha ${this.state.ToDay}`,
        1.5
      );
      await this.setState({ FromDay: this.state.ToDay });
    }
  };
  handleChangeInputTo = async (e) => {
    if (moment(this.state.FromDay) <= moment(e)) {
      await this.setState({ ToDay: moment(e).format("YYYY-MM-DD") });
      await this.getSalidas();
    } else {
      message.error(
        `La fecha debe ser igual o mayor a la fecha ${this.state.FromDay}`,
        1.5
      );
      await this.setState({ ToDay: this.state.FromDay });
    }
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleClose = () => {
    this.setState({ visible: false });
  };
  render() {
    let allItemsRows = [];
    this.state.data.forEach((item, index) => {
      const perItemsRows = this.renderItems(item, index);
      allItemsRows = allItemsRows.concat(perItemsRows);
    });

    return (
      <Grid container className={this.props.classes.ingresos}>
        <h4>
          Revisa {localStorage.getItem("type") === "2" ? "y autoriza " : null}
          los traslados a disposición final
        </h4>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.valueSelectClient}
                  onChange={this.handleSelectClient}
                  disabled={
                    localStorage.getItem("type") === "2" ||
                    localStorage.getItem("type") === "4"
                      ? "disabled"
                      : ""
                  }
                >
                  {this.props.ingresosReducer.client.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.valueSelectPatios}
                  onChange={this.handleSelectPatios}
                >
                  <MenuItem value={0}>Todos los Patios</MenuItem>
                  {this.props.ingresosReducer.patios.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.valueSelectStatus}
                  onChange={this.handleSelectStatus}
                >
                  <MenuItem value={0}>Todos los estados</MenuItem>

                  {this.props.salidasReducer.status.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={2}>
              <DatePicker
                value={moment(moment(this.state.FromDay), "DD/MM/YYYY")}
                onChange={this.handleChangeInputFrom}
                size="large"
                className="header-select"
                placeholder="Desde"
                format="DD/MM/YYYY"
              />
            </Grid>
            <Grid item sm={2}>
              <DatePicker
                value={moment(moment(this.state.ToDay), "DD/MM/YYYY")}
                onChange={this.handleChangeInputTo}
                size="large"
                className="header-select"
                placeholder="Desde"
                format="DD/MM/YYYY"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} className={this.props.classes.mainTable}>
          <h3
            style={{
              color: "#0D0D0D",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            Mostrando traslados pendientes y en curso
          </h3>
          <TableContainer className={this.props.classes.contentTable}>
            <Table
              className={this.props.classes.tableGeneral}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={this.props.classes.cellOperativo}>
                  <TableCell>VEHICULO</TableCell>
                  <TableCell>TIPO DE VEHICULO</TableCell>
                  <TableCell>ORIGEN</TableCell>
                  <TableCell>DESTINO</TableCell>
                  <TableCell>CONDUCTOR</TableCell>
                  <TableCell>SALIDA REAL</TableCell>

                  <TableCell>LLEGADA REAL</TableCell>

                  <TableCell>ESTADO</TableCell>
                  <TableCell>VER MÁS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{allItemsRows}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Pagination
          data={this.state.valueSelectStatus}
          totalRecords={this.state.total}
          pageLimit={15}
          initialPage={1}
          pageToShow={4}
          onChangePage={this.onChangePage}
        />
        <Modals
          idMiningSite={this.state.idMiningSite}
          id={this.state.dataModal}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          data={this.state.data}
          namePlaca={this.state.valueModalVehiculo}
          nameDestino={this.state.valueModalDestino}
          funct={() => this.getSalidas()}
        />
        <ModalsView
          idMiningSite={this.state.idMiningSite}
          id={this.state.dataModal}
          open={this.state.openModalSal}
          handleClose={this.handleCloseView}
          data={this.state.data}
          namePlaca={this.state.valueModalVehiculo}
          nameDestino={this.state.valueModalDestino}
        />
        <ModalsD
          id={this.state.dataModal}
          open={this.state.openModalDoc}
          handleClose={this.handleCloseDoc}
          data={this.state.documentData}
        />
      </Grid>
    );
  }
}
const mapStateToProps = ({ ingresosReducer, salidasReducer }) => {
  return { salidasReducer, ingresosReducer };
};
const mapDispatchToProps = {
  GetPatios,
  GetSalidasVehicles,
  GetClients,
  GetSalidasStatus,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Salidas));
