export const OBTENER_DATA_CIRCULE = "obtener_data_circule";
export const LOADING_DATA_CIRCULE = "loading_data_circule";
export const ERROR_DATA_CIRCULE = "error_data_circule";

export const OBTENER_DATA_BARRAS = "obtener_data_barras";
export const LOADING_DATA_BARRAS = "loading_data_barras";
export const ERROR_DATA_BARRAS = "error_data_barras";

export const OBTENER_DATA_RANK = "obtener_data_rank";
export const LOADING_DATA_RANK = "loading_data_rank";
export const ERROR_DATA_RANK = "error_data_rank";

export const OBTENER_DATA_GENERIC_TYPES = "obtener_data_generic_types";
export const LOADING_DATA_GENERIC_TYPES = "loading_data_generic_types";
export const ERROR_DATA_GENERIC_TYPES = "error_data_generic_types";

export const OBTENER_DATA_PIE = "obtener_data_pie";
export const LOADING_DATA_PIE = "loading_data_pie";
export const ERROR_DATA_PIE = "error_data_pie";
