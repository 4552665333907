import fondo from "../../../img/foto-bn.png";
const appStyle = theme => ({
  wrapper: {
    position: "relative",
    overflow: "hidden",
    cursor: "default",
    minHeight: "100vh"
  },
  logo: {
    position: "absolute",
    width: "100px",
    top: "15px",
    left: "15px"
  },
  contentPresentation: {
    width: "100%",
    background: `url(${fondo}) no-repeat center center`,
    backgroundSize: "cover",
    position: "relative",
    color: "#ffffff",
    display: "flex",
    height: "100%"
  },
  slogan: {
    margin: "auto",
    "& p": {
      fontFamily: "Roboto",
      width: "100%",
      fontSize: "28px",
      fontWeight: "500",
      margin: "0 auto",
      textAlign: "center",
      lineHeight: "38px",
      [theme.breakpoints.up("md")]: {
        width: "650px",
        fontSize: "31px"
      },
      [theme.breakpoints.up("lg")]: {
        width: "740px",
        fontSize: "35px",
        lineHeight: "41px"
      }
    },
    "& hr": {
      width: "60px",
      border: "3px solid #ffffff"
    }
  },
  formLog: {
    margin: "auto",
    width: "80%",
    fontFamily: '"Roboto"'
  },
  footerLogin: {
    position: "absolute",
    bottom: "36PX",
    left: "0",
    right: "0",
    margin: "0 auto",
    textAlign: "center"
  },
  inputForm: {
    backgroundColor: "#fff",
    border: "0",
    borderRadius: "0",
    marginBottom: "7.5px",
    width: "100%"
  },
  btn: {
    backgroundColor: "#009b67",
    color: "#fff",
    textTransform: "unset",
    marginTop: "24px",
    marginBottom: "16px",
    border: "none",
    padding: "16px 0",
    width: "100%",
    "&:hover": {
      background: "#009b67"
    }
  },
  lineSeparateHr: {
    border: " 6px solid #000000",
    width: "50px",
    marginLeft: "0"
  },
  TitleLogin: {
    color: "#000000",
    fontSize: "45px",
    fontWeight: "bold",
    lineHeight: "53px"
  },
  SecondTitle: {
    color: "#313131",
    fontSize: "25px",
    fontWeight: "500",
    lineHeight: "29px"
  },
  TextLabel: {
    color: "#313131",
    fontSize: "16px",
    lineHeight: "19px"
  },
  needHelp: {
    color: "#808080",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px"
  }
});

export default appStyle;
