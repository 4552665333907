export const OBTENER_VEHICLES = "obtener_vehiculos";
export const CARGANDO_VEHICLES = "cargando vehiculos";
export const ERROR_VEHICLES = "error_vehiculos";

export const OBTENER_DATA_TABLE = "obtener_data_table";
export const CARGANDO_DATA_TABLE = "cargando_obtener_Table";
export const ERROR_DATA_TABLE = "error_data_table";

export const UPDATE_DATA_TABLE_GENERAL = "actualizar_data_Table_general";
export const UPDATE_DATA_TABLE_BY_ID = "actualizar_data_Table_vehicle_id";
export const UPDATE_STATUS_TABLE = "actualizar_status_table";
export const UPDATE_OPEN_MAP = "actualizar_open_map";
export const MONITORING_OPERATION = "monitoring_points_operation";
export const CARGANDO_MONITORING = "cargando_monitoring";
