import React, { Component } from "react";
import IconLogo from "../../assets/img/R icon.svg";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import style from "../../assets/jss/disal-main/components/Header";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import * as loginAction from "../../Actions/LoginActions";
const { logOut } = loginAction;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: localStorage.getItem("type") === "4" ? "Salidas" : "Operation",
      anchor: null,
    };
  }
  async componentDidMount() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1],
    });
  }
  async componentWillReceiveProps() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1],
    });
  }

  LogOut = async () => {
    await this.props.logOut();
    await this.props.history.replace("/Login");
  };
  ChangeStatusAdmin = async () => {
    await this.props.history.push("/Main-Sesion/Admin/Gerencia");
    await this.setState({
      anchor: null,
    });
  };
  ChangeStatusPanel = async () => {
    await this.props.history.push("/Main-Sesion/Operation");
    await this.setState({
      anchor: null,
    });
  };
  handleMenu = async (e) => {
    await this.setState({
      name: e.currentTarget.name,
    });
  };
  handleDraw = async (e) => {
    await this.setState({
      anchor: e.currentTarget,
    });
  };
  handleClose = async (e) => {
    await this.setState({
      anchor: null,
    });
  };

  handleOpenSide = () => {};
  render() {
    const open = Boolean(this.state.anchor);

    return (
      <div>
        <AppBar className={this.props.classes.appBar} position="static">
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.logo}>
              <div className={this.props.classes.logoImage}>
                <img
                  src={IconLogo}
                  alt="logo"
                  className={this.props.classes.img}
                />
              </div>
              Resflow
            </div>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.props.handleChange}
                className={this.props.classes.MenuIcon}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleDraw}
                className={this.props.classes.buttonText}
                color="inherit"
              >
                {localStorage.getItem("name")} [
                {localStorage.getItem("miningSite")}]
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                className={this.props.classes.dropDown}
                open={open}
                onClose={this.handleClose}
              >
                {localStorage.getItem("type") === "4" ? null : (
                  <MenuItem onClick={this.ChangeStatusPanel}>
                    <i className="fas fa-power-off"></i>Panel de control
                  </MenuItem>
                )}

                {localStorage.getItem("type") === "4" ||
                localStorage.getItem("type") === "5" ? null : (
                  <MenuItem onClick={this.ChangeStatusAdmin}>
                    <i className="fas fa-power-off"></i>Admin
                  </MenuItem>
                )}
                <MenuItem onClick={this.LogOut}>
                  <i className="fas fa-power-off"></i>Cerrar Sesión
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {
  logOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Header));
