import React, { Component } from "react";
import { connect } from "react-redux";
import * as operatorAction from "../../Actions/OperatorAction";
import { withStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import style from "../../assets/jss/disal-main/components/Modal";
const { DeleteOperator } = operatorAction;

class ModalDelete extends Component {
  handleClickAccept = async () => {
    this.props.DeleteOperator(this.props.id);
    this.props.handleClose();
  };
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.open}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.open}>
          <div className={this.props.classes.paper} style={{ width: "500px" }}>
            <div className="modal-confirm">
              <h3>Confirmación</h3>
              <p>¿Estás seguro que quieres eliminar este usuario?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-modal-salida"
                style={{ textDecoration: "underline", marginRight: "10px" }}
                onClick={() => this.props.handleClose()}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-modal-salida"
                style={{ textDecoration: "underline" }}
                onClick={this.handleClickAccept}
              >
                Confirmar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
const mapStateToProps = ({ operatorReducer }) => {
  return { operatorReducer };
};
const mapDispatchToProps = {
  DeleteOperator
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ModalDelete));
