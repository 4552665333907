import {
  OBTENER_DATA_OPERATORS,
  LOADING_DATA_OPERATORS,
  ERROR_DATA_OPERATORS,
  LOADING_DATA_OPERATORS_TYPE,
  OBTENER_DATA_OPERATORS_TYPE,
  ERROR_DATA_OPERATORS_TYPE,
  CREATE_UPDATE_OPERATOR,
  MESSAGE_DELETE_OPERATOR,
  UPDATE_DATA_OPERATORS
} from "../Types/Operator";

const initialState = {
  operator: [],
  loading: false,
  error: "",
  operator_type: [],
  loading_type: false,
  error_type: "",
  message: "",
  messageCreate: "",
  messageError: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_OPERATORS:
      return {
        ...state,
        loading: true
      };
    case OBTENER_DATA_OPERATORS:
      return {
        ...state,
        operator: action.payload,
        loading: false,
        error: ""
      };
    case UPDATE_DATA_OPERATORS:
      return {
        ...state,
        operator: action.payload
      };
    case ERROR_DATA_OPERATORS:
      return {
        ...state,
        operator: [],
        loading: false,
        error: action.payload
      };
    case LOADING_DATA_OPERATORS_TYPE:
      return {
        ...state,
        loading_type: true
      };
    case OBTENER_DATA_OPERATORS_TYPE:
      return {
        ...state,
        operator_type: action.payload,
        loading_type: false,
        error_type: ""
      };
    case ERROR_DATA_OPERATORS_TYPE:
      return {
        ...state,
        operator_type: [],
        loading_type: false,
        error_type: action.payload
      };
    case MESSAGE_DELETE_OPERATOR:
      return {
        ...state,
        message: action.payload
      };

    case CREATE_UPDATE_OPERATOR:
      return {
        ...state,
        operator: [],
        loading: false,
        error: ""
      };
    default:
      return state;
  }
}
