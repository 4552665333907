import React, { Component } from "react";
import { connect } from "react-redux";

import * as dashboardAction from "../Actions/DashboardActions";
import { withStyles } from "@material-ui/core/styles";
import style from "../assets/jss/disal-main/Layouts/Dash";
import Grid from "@material-ui/core/Grid";
import ChartsBarras from "../Component/Card/CardBarras";

import ChartsTable from "../Component/Card/CardTable";
import CharstPoint from "../Component/Card/CardPoint";
import ChartsCircule from "../Component/Card/CardCircule";
import ChartsBar from "../Component/Card/CardBar";
const {
  GetDataCircule,
  GetDataBarras,
  GetDataBar,
  GetDataPoints,
  GetDataPie
} = dashboardAction;
class Dash extends Component {
  componentDidMount = async () => {
    const {
      GetDataCircule,
      GetDataBarras,
      GetDataBar,
      GetDataPoints,
      GetDataPie
    } = this.props;
    if (!this.props.totalDataCircule.length) {
      await GetDataCircule();
    }
    if (!this.props.totalDataBarras.length) {
      await GetDataBarras();
    }
    if (!this.props.totalDataRank.length) {
      await GetDataBar();
    }
    if (!this.props.totalGenericTypes.length) {
      await GetDataPoints();
    }
    if (!this.props.totalDataPie.length) {
      await GetDataPie();
    }
  };
  componentDidUpdate = async prevProps => {
    const {
      GetDataCircule,
      GetDataBarras,
      GetDataBar,
      GetDataPoints,
      GetDataPie
    } = this.props;
    if (
      this.props.totalDataCircule.length !== prevProps.totalDataCircule.length
    ) {
      await GetDataCircule();
    }
    if (
      this.props.totalDataBarras.length !== prevProps.totalDataBarras.length
    ) {
      await GetDataBarras();
    }
    if (this.props.totalDataRank.length !== prevProps.totalDataRank.length) {
      await GetDataBar();
    }
    if (
      this.props.totalGenericTypes.length !== prevProps.totalGenericTypes.length
    ) {
      await GetDataPoints();
    }
    if (this.props.totalDataPie.length !== prevProps.totalDataPie.length) {
      await GetDataPie();
    }
  };
  render() {
    return (
      <section className={this.props.classes.dashContent}>
        <Grid container>
          <ChartsCircule num={0} />
          <ChartsCircule num={1} />
          <ChartsBarras num={0} />
          <ChartsBarras num={1} />
          <ChartsBar />
          <ChartsTable />
          {/* <ChartsPie /> */}
          <CharstPoint
            id={"01GraphicPoint"}
            title={"Tendencia por tipo de residuo (últimos 7 días)"}
            codigo={"cod-tendencia"}
          />
        </Grid>
      </section>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return dashboardReducer;
};
const mapDispatchToProps = {
  GetDataCircule,
  GetDataBarras,
  GetDataBar,
  GetDataPoints,
  GetDataPie
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Dash));
