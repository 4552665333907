import React from "react";
import logoImage from "../../assets/img/logo disal verde.svg";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import style from "../../assets/jss/disal-main/Layouts/Login";
const LoginImage = props => (
  <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
    <div className={props.classes.contentPresentation}>
      <img
        className={props.classes.logo}
        src={logoImage}
        alt="Logo de la compañia"
      />
      <div className={props.classes.slogan}>
        <p>
          Visualiza información de recolección y segregación en dashboards y
          genera reportes
        </p>
        <hr />
      </div>
      <p className={props.classes.footerLogin}>UNA PLATAFORMA DE DISAL</p>
    </div>
  </Grid>
);
export default withStyles(style)(LoginImage);
