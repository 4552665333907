import React, { Component } from "react";
import { connect } from "react-redux";
import * as dashboardAction from "../../Actions/DashboardActions";
import { VictoryPie, VictoryAnimation, VictoryLabel } from "victory";
const { GetDataCircule } = dashboardAction;
class Charts extends Component {
  constructor() {
    super();
    this.state = {
      percent: 0,
      data: this.getData(0)
    };
  }
  componentDidMount() {
    let { percent } = this.state;
    this.setStateInterval = window.setInterval(() => {
      percent =
        this.props.data.percentage > 100 ? 0 : this.props.data.percentage;
      this.setState({
        percent,
        data: this.getData(percent)
      });
    }, 2000);
  }

  componentWillUnmount() {
    window.clearInterval(this.setStateInterval);
  }

  getData(percent) {
    return [
      { x: 1, y: percent },
      { x: 2, y: 100 - percent }
    ];
  }
  render() {
    if (this.props.dashboardReducer.loadingCircule) {
      return <div>load</div>;
    }
    return (
      <svg viewBox="0 0 400 250" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400}
          height={230}
          data={this.state.data}
          innerRadius={75}
          cornerRadius={20}
          labels={() => null}
          style={{
            data: {
              fill: d => {
                const color =
                  this.props.codigo === "cod-GraphRecolect" && d.y > 0
                    ? "#1c4d9d"
                    : this.props.codigo === "cod-GraphSegre" && d.y > 80
                    ? "#1c4d9d"
                    : "red";
                return d.x === 1 ? color : "transparent";
              }
            }
          }}
        />
        <circle
          cx="200"
          cy="115"
          r="65"
          fill="none"
          stroke="white"
          strokeWidth={3}
        />
        <circle
          cx="200"
          cy="115"
          r="80"
          fill="none"
          stroke="white"
          strokeWidth={3}
        />
        <VictoryAnimation duration={1000} data={this.state}>
          {newProps => {
            return (
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                x={200}
                y={110}
                text={
                  this.props.codigo === "cod-GraphRecolect"
                    ? `${this.props.data.textData}\ntons`
                    : `${this.props.data.textData}%`
                }
                style={{
                  fontSize: 22,
                  fontWeight: "600",
                  labels: { fontSize: 35, fill: "white", fontWeight: "900" }
                }}
              />
            );
          }}
        </VictoryAnimation>
      </svg>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return { dashboardReducer };
};
const mapDispatchToProps = {
  GetDataCircule
};

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
