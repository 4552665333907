import React, { Component } from "react";
import { connect } from "react-redux";
import CardChart from "../CardTab/CardTabBarras";
import Spinner from "../Spinner";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Card";
class Charts extends Component {
  constructor() {
    super();
    this.state = {
      status: "Diario"
    };
  }
  handleChange = val => {
    this.setState({
      status: val
    });
  };
  ponerContenido = () => {
    const { loadingBarras, totalDataBarras } = this.props;

    if (loadingBarras) {
      return <Spinner />;
    }
    if (!totalDataBarras.length) return;
    return (
      <div className={this.props.classes.contentChart}>
        <h4>
          {this.state.status === "Diario" &&
          totalDataBarras[this.props.num].codigo === "cod-recoleecion"
            ? totalDataBarras[this.props.num].title + " (en Kg)"
            : this.state.status === "Semanal" &&
              totalDataBarras[this.props.num].codigo === "cod-recoleecion"
            ? totalDataBarras[this.props.num].title + " (en Tons)"
            : totalDataBarras[this.props.num].title}
        </h4>
        <div>
          <CardChart
            id={totalDataBarras[this.props.num].id}
            title={totalDataBarras[this.props.num].title}
            codigo={totalDataBarras[this.props.num].codigo}
            data={totalDataBarras[this.props.num].data}
            handleChange={this.handleChange}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className={this.props.classes.cardContent}
      >
        {this.ponerContenido()}
      </Grid>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return dashboardReducer;
};

export default connect(mapStateToProps)(withStyles(style)(Charts));
