import React, { Component } from "react";

import "../../App.css";
import { LoadScript } from "@react-google-maps/api";
import PageOperation from "./PageOperation";
import Header from "../../Component/Header/Header";
class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }
  handleChange = () => {
    this.setState({
      open: !this.state.open
    });
  };
  render() {
    return (
      <LoadScript
        googleMapsApiKey={"AIzaSyA4tPX2lSiY6Yq8rqsa74gnbqL-6B40yWE"}
        region="pe"
      >
        <Header history={this.props.history} handleChange={this.handleChange} />
        <PageOperation
          open={this.state.open}
          handleChange={this.handleChange}
          google={this.props.google}
        />
      </LoadScript>
    );
  }
}

export default Dashboard;
