import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./Layouts/Login/Login";
import MainDash from "./Layouts/Main/MainSesion";
import PrivateRouteHandler from "./Routes/PrivateRouters";

const AppRoutes = () => (
  <Switch>
    <Route path="/Login" component={Login} />
    <PrivateRouteHandler path="/Main-Sesion" component={MainDash} />
    <Redirect to="/Login" />
  </Switch>
);
export default AppRoutes;
