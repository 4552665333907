import axios from "axios";
import { getToken } from "./Auth";

const api = axios.create({
  baseURL: "https://api.resflow-dev.com",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.common["Authorization"] = "Bearer " + token;
    return config;
  }
  return config;
});
export default api;
