export const OBTENER_DATA_POINTS = "obtener_data_puntos";
export const LOADING_DATA_POINTS = "loading_data_puntos";
export const ERROR_DATA_POINTS = "error_data_puntos";

export const UPDATE_DATA_POINTS = "actualizar_data_puntos";

export const OBTENER_DATA_POINTS_CONTAINER = "obtener_data_puntos_contenedores";
export const LOADING_DATA_POINTS_CONTAINER = "loading_data_puntos_contenedores";
export const ERROR_DATA_POINTS_CONTAINER = "error_data_puntos_contenedores";

export const OBTENER_DATA_TYPE_CONTAINER = "obtener_data_type_container";
export const LOADING_DATA_TYPE_CONTAINER = "loading_data_type_container";
export const ERROR_DATA_TYPE_CONTAINER = "error_data_type_container";

export const OBTENER_DATA_TYPE_RESIDUE = "obtener_data_type_residue";
export const LOADING_DATA_TYPE_RESIDUE = "loading_data_type_residue";
export const ERROR_DATA_TYPE_RESIDUE = "error_data_type_residue";
// export const OBTENER_DATA_POINTS_ESPECIFIC = "obtener_data_puntos_especific";
// export const LOADING_DATA_POINTS_ESPECIFIC = "loading_data_puntos_especific";
// export const ERROR_DATA_POINTS_ESPECIFIC = "error_data_puntos_especific";

export const MESSAGE_DELETE_POINTS = "message_delete_puntos";
export const CREATE_UPDATE_POINTS = "create_update_puntos";

export const MESSAGE_DELETE_CONTAINER = "message_delete_contenedores";
export const CREATE_UPDATE_CONTAINER = "create_update_contenedores";
export const CREATE_ERROR_CONTAINER = "create_error_contenedores";
