import {
  OPEN_GERENCIA_MODAL,
  OPEN_POINT_MODAL,
  OPEN_USER_MODAL,
  OPEN_VEHICLE_MODAL,
  CLOSE_GERENCIA_MODAL,
  CLOSE_POINT_MODAL,
  CLOSE_USER_MODAL,
  CLOSE_VEHICLE_MODAL,
  OPEN_EXIT_GERENCIA_MODAL,
  OPEN_EXIT_USER_MODAL,
  OPEN_EXIT_VEHICLE_MODAL,
  OPEN_EXIT_POINT_MODAL,
  CLOSE_EXIT_GERENCIA_MODAL,
  CLOSE_EXIT_USER_MODAL,
  CLOSE_EXIT_VEHICLE_MODAL,
  CLOSE_EXIT_POINT_MODAL,
  OPEN_EXIT_ROUTES_MODAL,
  CLOSE_EXIT_ROUTES_MODAL
} from "./../Types/Modal";

const initialState = {
  openGerencia: false,
  openUser: false,
  openVehicle: false,
  openPoints: false,
  openExitGerencia: false,
  openExitUser: false,
  openExitVehicle: false,
  openExitPoints: false,
  openExitRoutes: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_VEHICLE_MODAL:
      return {
        ...state,
        openVehicle: true
      };
    case CLOSE_VEHICLE_MODAL:
      return {
        ...state,
        openVehicle: false
      };
    case OPEN_USER_MODAL:
      return {
        ...state,
        openUser: true
      };
    case CLOSE_USER_MODAL:
      return {
        ...state,
        openUser: false
      };
    case OPEN_POINT_MODAL:
      return {
        ...state,
        openPoints: true
      };
    case CLOSE_POINT_MODAL:
      return {
        ...state,
        openPoints: false
      };
    case OPEN_GERENCIA_MODAL:
      return {
        ...state,
        openGerencia: true
      };
    case CLOSE_GERENCIA_MODAL:
      return {
        ...state,
        openGerencia: false
      };

    case OPEN_EXIT_VEHICLE_MODAL:
      return {
        ...state,
        openExitVehicle: true
      };
    case CLOSE_EXIT_VEHICLE_MODAL:
      return {
        ...state,
        openExitVehicle: false
      };
    case OPEN_EXIT_USER_MODAL:
      return {
        ...state,
        openExitUser: true
      };
    case CLOSE_EXIT_USER_MODAL:
      return {
        ...state,
        openExitUser: false
      };
    case OPEN_EXIT_POINT_MODAL:
      return {
        ...state,
        openExitPoints: true
      };
    case CLOSE_EXIT_POINT_MODAL:
      return {
        ...state,
        openExitPoints: false
      };
    case OPEN_EXIT_GERENCIA_MODAL:
      return {
        ...state,
        openExitGerencia: true
      };
    case CLOSE_EXIT_GERENCIA_MODAL:
      return {
        ...state,
        openExitGerencia: false
      };
    case OPEN_EXIT_ROUTES_MODAL:
      return {
        ...state,
        openExitRoutes: true
      };
    case CLOSE_EXIT_ROUTES_MODAL:
      return {
        ...state,
        openExitRoutes: false
      };
    default:
      return state;
  }
}
