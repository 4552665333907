/** global google */

import React from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { connect } from "react-redux";
import * as operationAction from "../Actions/OperationActions";

import icoPending from "../assets/img/location celeste 2.svg";
import iconProcess from "../assets/img/location verde 2.svg";
import iconFinish from "../assets/img/location naranja 2.svg";
import iconIncident from "../assets/img/location rojo.svg";
const { GetVehiclesOperation, GetVehicleDataTable } = operationAction;

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: [],
      selectedPalce: null,
      markerMap: {},
      mapRef: null
    };
  }
  async componentDidMount() {
    
    if (
      this.props.operationReducer.coordMap.length &&
      this.props.operationReducer.coordMap[0].coord.lat !== 0 &&
      this.props.operationReducer.coordMap[0].coord.lng !== 0
    ) {
      
      await this.setState({ arr: this.props.operationReducer.coordMap });
      await this.fitBounds(this.map.state.map);
    } else {
    }
  }
  componentWillReceiveProps = async nextProps => {
    if (
      nextProps.operationReducer.coordMap.length &&
      nextProps.operationReducer.coordMap[0].coord.lat !== 0 &&
      nextProps.operationReducer.coordMap[0].coord.lng !== 0
    ) {
      console.log(this.map.state);

      await this.setState({ arr: nextProps.operationReducer.coordMap });
      await this.fitBounds(this.map.state.map);
    }
  };
  refmap = ref => {
    this.map = ref;
  };
  refmarker = ref => {
    this.marker = ref;
  };
  fitBounds = async map => {
    if (this.state.arr && this.state.arr.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      this.state.arr.map(place => {
        bounds.extend(place.coord);
        return bounds;
      });
      await map.fitBounds(bounds);
    } else {
    }
  };

  loadHandler = async map => {
    await this.fitBounds(map);
  };

  markerClickHandlerOpen = async (e, data) => {
    await this.setState({ selectedPalce: data });

    // Required so clicking a 2nd marker works as expected
    if (this.state.infoOpen) {
      await this.props.handleInfoOpen(false);
      // this.setState({ infoOpen: false });
    }
    await this.props.handleInfoOpen(true);
    await this.fitBounds(this.map.state.map);
  };
  markerClickHandlerClose = async (e, data) => {
    await this.props.handleInfoOpen(false);
    await this.fitBounds(this.map.state.map);
  };
  markerLoadHandler = async (marker, i) => {
    
  };

  render() {
    return (
      <GoogleMap
        mapContainerClassName="App-map"
        onLoad={this.loadHandler}
        ref={this.refmap}
        center={{ lat: -16.5520373, lng: -71.6475389 }}
        zoom={14}
        options={{
          streetViewControl: false,
          mapTypeId: "satellite"
        }}
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
      >
        {this.props.operationReducer.coordMap.map((dat, i) => {
          const icons =
            dat.status === "Pendiente"
              ? iconFinish
              : dat.status === "Process"
              ? icoPending
              : dat.status === "lastPoint"
              ? iconFinish
              : dat.status === "nextPoint"
              ? iconProcess
              : dat.status === "Incidente"
              ? iconIncident
              : iconProcess;
          let label = dat.id.toString();
          return (
            <Marker
              key={i}
              ref={this.refmarker}
              onLoad={marker => this.markerLoadHandler(marker, dat.id)}
              onClick={e => this.markerClickHandlerOpen(e, dat)}
              title={dat.id.toString()}
              label={{
                text: label,
                color: "#000",
                fontSize: "14px",
                fontWeight: "bold"
              }}
              icon={{
                url: icons,
                size: new window.google.maps.Size(51, 51),
                origin: new window.google.maps.Point(-7, -5),
                anchor: new window.google.maps.Point(77, 74),
                scaledSize: new window.google.maps.Size(40, 40)
              }}
              position={dat.coord}
            ></Marker>
          );
        })}
        {this.props.infoOpen && (
          <InfoWindow
            position={this.state.selectedPalce.coord}
            onCloseClick={this.markerClickHandlerClose}
            options={{
              pixelOffset: new window.google.maps.Size(-50, -75)
            }}
          >
            <div className="info-window">
              <h3 style={{ margin: "0px" }}>{this.state.selectedPalce.id}</h3>
              <h3 style={{ margin: "0px" }}>
                {this.state.selectedPalce.name !== null
                  ? this.state.selectedPalce.name
                  : ""}
              </h3>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  }
}
const mapStateToProps = ({ operationReducer }) => {
  return { operationReducer };
};
const mapDispatchToProps = {
  GetVehiclesOperation,
  GetVehicleDataTable
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
