import {
  CARGANDO_CLIENT,
  OBTENER_CLIENT,
  CARGANDO_PATIOS,
  OBTENER_PATIOS,
} from "../Types/Ingresos";
import {
  EndPointIngresosClientes,
  EndPointPatiosId,
} from "../Services/EndPointIngresos";
export const GetClients = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_CLIENT,
  });

  const respuestaClientes = await EndPointIngresosClientes();

  dispatch({
    type: OBTENER_CLIENT,
    payload: respuestaClientes.data.data,
  });
};

export const GetPatios = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_PATIOS,
  });
  const idMiningSite = localStorage.getItem("miningSiteId");

  const respuestaPatios = await EndPointPatiosId(idMiningSite);

  dispatch({
    type: OBTENER_PATIOS,
    payload: respuestaPatios.data.data,
  });
};
