import {
  LOADING_DATA_POINTS,
  OBTENER_DATA_POINTS,
  ERROR_DATA_POINTS,
  MESSAGE_DELETE_POINTS,
  CREATE_UPDATE_POINTS,
  LOADING_DATA_POINTS_CONTAINER,
  OBTENER_DATA_POINTS_CONTAINER,
  ERROR_DATA_POINTS_CONTAINER,
  LOADING_DATA_TYPE_CONTAINER,
  OBTENER_DATA_TYPE_CONTAINER,
  ERROR_DATA_TYPE_CONTAINER,
  LOADING_DATA_TYPE_RESIDUE,
  OBTENER_DATA_TYPE_RESIDUE,
  ERROR_DATA_TYPE_RESIDUE,
  MESSAGE_DELETE_CONTAINER,
  CREATE_UPDATE_CONTAINER,
  UPDATE_DATA_POINTS,
  CREATE_ERROR_CONTAINER
} from "../Types/PointAcopio";

const initialState = {
  points: [],
  loading: false,
  error: "",
  container: [],
  loading_container: false,
  error_container: "",
  type_container: [],
  loading_type_container: false,
  error_type_container: "",
  type_residue: [],
  loading_type_residue: false,
  error_type_residue: "",
  messageErrorContainer: "",
  messageErrorPoint: "",

  messageCreate: "",
  messageContainer: "",
  messageCreateContainer: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_POINTS:
      return {
        ...state,
        loading: true
      };
    case OBTENER_DATA_POINTS:
      return {
        ...state,
        points: action.payload,
        loading: false,
        error: "",
        messageErrorPoint: ""
      };
    case UPDATE_DATA_POINTS:
      return {
        ...state,
        points: action.payload
      };
    case ERROR_DATA_POINTS:
      return {
        ...state,
        points: [],
        loading: false,
        error: action.payload
      };

    case MESSAGE_DELETE_POINTS:
      return {
        ...state,
        message: action.payload
      };
    case CREATE_UPDATE_POINTS:
      return {
        ...state,
        points: [],
        loading: false,
        error: ""
      };
    case LOADING_DATA_POINTS_CONTAINER:
      return {
        ...state,
        loading_container: true
      };
    case OBTENER_DATA_POINTS_CONTAINER:
      return {
        ...state,
        container: action.payload,
        loading_container: false,
        error_container: ""
      };
    case ERROR_DATA_POINTS_CONTAINER:
      return {
        ...state,
        container: [],
        loading_container: false,
        error_container: action.payload
      };

    case LOADING_DATA_TYPE_CONTAINER:
      return {
        ...state,
        loading_type_residue: true
      };
    case OBTENER_DATA_TYPE_CONTAINER:
      return {
        ...state,
        type_container: action.payload,
        loading_type_residue: false,
        error_type_container: ""
      };
    case ERROR_DATA_TYPE_CONTAINER:
      return {
        ...state,
        type_container: [],
        loading_type_residue: false,
        error_type_container: action.payload
      };

    case LOADING_DATA_TYPE_RESIDUE:
      return {
        ...state,
        loading_type_residue: true
      };
    case OBTENER_DATA_TYPE_RESIDUE:
      return {
        ...state,
        type_residue: action.payload,
        loading_type_residue: false,
        error_type_residue: ""
      };
    case ERROR_DATA_TYPE_RESIDUE:
      return {
        ...state,
        type_residue: [],
        loading_type_residue: false,
        error_type_residue: action.payload
      };
    case MESSAGE_DELETE_CONTAINER:
      return {
        ...state,
        messageContainer: action.payload
      };
    case CREATE_UPDATE_CONTAINER:
      return {
        ...state,
        container: [],
        loading_container: false,
        error_container: ""
      };
    case CREATE_ERROR_CONTAINER:
      return {
        ...state,
        messageErrorContainer: action.payload
      };

    default:
      return state;
  }
}
