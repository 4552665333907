import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
class ModalIngres extends Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.open}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.open}>
          <div className={this.props.classes.paper}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className={this.props.classes.modalHeader}>
                  <h5
                    className={this.props.classes.modalTitle}
                    id="exampleModalLabel"
                  >
                    Detalle de ingreso de residuos
                  </h5>
                  <button
                    className="btn-close"
                    onClick={this.props.handleClose}
                  >
                    <span className="closeSpan big"></span>
                  </button>
                </div>
                <div className="modal-body ">
                  <Grid container>
                    <Grid item xs={3}>
                      <label>FECHA</label>
                      <p>{this.props.data.date}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <label>HORA</label>
                      <p>{this.props.data.time}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <label>VEHICULO</label>
                      <p>{this.props.data.vehicle}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <label>TIPO VEHICULO</label>
                      <p>{this.props.data.vehicleType}</p>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "25px" }}>
                    <h4
                      style={{
                        color: "#050505",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        textAlign: "left",
                        marginBottom: "15px"
                      }}
                    >
                      DETALLE DE RESIDUOS
                    </h4>
                    {this.props.data.subTypes.map(res => {
                      return (
                        <Grid item xs={12}>
                          {/*<SubType data={res}/>*/}
                          <Grid container>
                            <Grid item xs={1}>
                              <div
                                className="circle-ingreso mr-2"
                                style={{
                                  backgroundColor: res.color,
                                  border:
                                    res.name === "Plásticos"
                                      ? "1px solid #6A6A6A"
                                      : "none"
                                }}
                              ></div>
                            </Grid>
                            <Grid item xs={4}>
                              <p className="mb-0 mr-5 text-residuo">
                                {res.name}
                              </p>
                            </Grid>
                            <Grid item xs={5}>
                              <p className="mb-0 number-residuo">
                                {res.amount} Kg
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
export default withStyles(style)(ModalIngres);
