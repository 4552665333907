const appStyle = (theme) => ({
  gerencia: {
    // paddingTop: "35px",
    // paddingBottom: "35px",
    // paddingLeft: "162px",
    // paddingRight: "162px",
    border: "1px solid #efefef",
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "15px ",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  textFielInput: {
    width: "100%",
    minWidth: 250,
    "&.blocked-id": {
      backgroundColor: "#efefef",
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
    padding: "5px",
    marginBottom: "15px",
    borderBottom: "1px solid #efefef",
  },
  content: {
    margin: "50px 162px",
  },
  Listbutton: {
    borderTop: "1px solid #efefef",

    borderBottom: "1px solid #efefef",
    padding: "16px 10px",
  },
  iconClose: {
    border: "1px solid #efefef",
    backgroundColor: "#fba1a1",
    color: "#fff",
    borderRadius: "50%",
    display: "flex",
    padding: "4px",
    "&:hover": {
      backgroundColor: "#f5222d",
    },
  },
  listAdd: {
    position: "relative",
    paddingTop: "15px",
    paddingRight: "10px",
    borderRight: "1px solid #efefef",
    borderTop: "1px solid #efefef",
  },
  iconMore: {
    border: "1px solid #1c4d9d",
    backgroundColor: "#1c4d9d",
    marginLeft: "auto",
    color: "#fff",
    padding: "10px 10px",
    position: "absolute",
    borderRadius: "50%",
    right: "65px",
    bottom: "41px",
    display: "flex",
    boxShadow: "1px 3px 6px 2px #969292",
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
  },
  listGerencia: {
    width: "100%",
    // maxWidth: 360,
    border: "2px solid #efefef",
    borderRadius: "4px",

    padding: "0",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    height: 600,
    maxHeight: 600,
    "& .active-item": {
      backgroundColor: "#efefef",
    },
  },
  formGerencia: {
    padding: "15px 5px 0",
    borderTop: "1px solid #efefef",
  },
  contentButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    "& button": {
      border: "2px solid #009b67",
      backgroundColor: "#fff",
      color: "#3c3a3a",
      fontSize: "15px",
      marginLeft: "15px",
      padding: "10px 25px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#009b67",
        color: "#fff",
      },
    },
  },
  contentForm: {
    height: "calc(100% - 55px)",
    display: "flex",
    paddingTop: "10px",
    flexDirection: "column",
    "& .inputs-form": {
      display: "flex",
      marginBottom: "25px",
      "& p": {
        marginBottom: "0",
        color: "red",
      },
      "& label": {
        marginBottom: "10px",
        fontSize: "16px",
        fontWeight: "500",
        // width: "150px",
        display: "flex",
        alignItems: "center",
      },
      "& .MuiOutlinedInput-input": {
        padding: "8px 5px",
      },
    },
  },
  formControl: {
    width: "100%",
    minWidth: 250,
    "&.back-blocked": {
      backgroundColor: "#efefef",
    },
  },
});

export default appStyle;
