import React, { Component } from "react";
import { connect } from "react-redux";
import * as routesAction from "../Actions/RoutesAction";
import * as pointAction from "../Actions/PointAcopioAction";

import * as vehicleAction from "../Actions/VehicleAction";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import style from "../assets/jss/disal-main/Layouts/PointAcopio";
import { EndPointGetRoutesEspecific } from "../Services/EndPointRoutes";
import FormRoutes from "../Component/Form/Routes";
import Modal from "../Component/Modal/ModalExitoRoutes";

const { GetRoutesList } = routesAction;
const { GetVehiclesAdmin } = vehicleAction;
const { GetPointAcopio } = pointAction;
class Routes extends Component {
  constructor() {
    super();
    this.state = {
      idSelect: null,
      idRoute: null,
      open: false,
      statusForm: false,
      number: null,
      id: null,
      data: {},
      name: null,
      type: null,
    };
  }
  componentDidMount = async () => {
    if (!this.props.vehicleReducer.vehicle.length) {
      this.props.GetVehiclesAdmin();
    }
    await this.props.GetPointAcopio();
    await this.props.GetRoutesList();
  };

  handleOpen = async () => {
    await this.setState({
      open: true,
    });
  };
  HandleSearchId = async (e) => {
    const value = e.target.value;
    const name = this.state.name;
    const number = this.state.number;
    const type = this.state.type;
    if (value.length > 0) {
      await this.props.GetPointAcopioEspecific(value, number, name, type);
      await this.setState({
        id: value,
      });
    } else {
      await this.props.GetPointAcopioEspecific(null, number, name, type);
      await this.setState({
        id: null,
      });
    }
  };
  HandleSearchDni = async (e) => {
    const value = e.target.value;

    const name = this.state.name;
    const id = this.state.id;
    const type = this.state.type;
    if (value.length > 3) {
      await this.props.GetPointAcopioEspecific(id, value, name, type);
      await this.setState({
        number: value,
      });
    } else {
      await this.props.GetPointAcopioEspecific(id, null, name, type);
      await this.setState({
        number: null,
      });
    }
  };
  HandleSearchName = async (e) => {
    const value = e.target.value;

    const number = this.state.number;
    const id = this.state.id;
    const type = this.state.type;
    if (value.length > 3) {
      await this.props.GetPointAcopioEspecific(id, number, value, type);
      await this.setState({
        name: value,
      });
    } else {
      await this.props.GetPointAcopioEspecific(id, number, value, type);
      await this.setState({
        name: null,
      });
    }
  };
  HandleSearchType = async (e) => {
    const value = e.target.value;

    const number = this.state.number;
    const id = this.state.id;
    const name = this.state.type;
    if (value !== 0) {
      await this.props.GetPointAcopioEspecific(id, number, name, value);
      await this.setState({
        type: value,
      });
    } else {
      await this.props.GetPointAcopioEspecific(id, number, name, value);
      await this.setState({
        type: null,
      });
    }
  };
  handleClose = async () => {
    await this.setState({
      open: false,
    });
  };

  handleClean = async () => {
    await this.setState({
      data: {},
      idSelect: null,
    });
  };
  handleCancel = async () => {
    await this.setState({
      statusForm: false,
      data: {},
      idSelect: null,
    });
  };
  handleClickPoint = async (e) => {
    const id = e.currentTarget.id;
    const idRoute = e.currentTarget.dataset.code;
    const result = await EndPointGetRoutesEspecific(id);
    if (result.data.data.length === 0) {
      await this.setState({
        statusForm: true,
        idSelect: Number(id),
        idRoute: Number(idRoute),
        data: [],
      });
    } else {
      await this.setState({
        statusForm: true,
        idSelect: Number(id),
        idRoute: Number(idRoute),
        data: result.data.data[0],
      });
    }
  };

  render() {
  
    return (
      <div className={this.props.classes.content}>
        <Grid container className={this.props.classes.gerencia}>
          <Grid item xs={12}></Grid>
          <Grid item xs={4} className={this.props.classes.listAdd}>
            <h3 className={this.props.classes.title}>Lista de las Rutas</h3>
            <List className={this.props.classes.listGerencia}>
              {this.props.routeReducer.routes.map((res, index) => (
                <ListItem
                  button
                  id={res.routeId}
                  data-code={res.routeId}
                  className={
                    this.props.classes.Listbutton +
                    (this.state.idSelect === res.vehicleId
                      ? " active-item"
                      : null)
                  }
                  onClick={this.handleClickPoint}
                  key={index}
                >
                  <p>
                    {res.description === null ? "" : res.description}
                    {res.plateNumber === null ? "" : ", " + res.plateNumber}
                  </p>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={8} className={this.props.classes.formGerencia}>
            <h3 className={this.props.classes.title}>Datos de la Ruta</h3>
            {this.state.statusForm ? (
              <FormRoutes
                data={this.state.data}
                id={this.state.idSelect}
                idRoute={this.state.idRoute}
                handleCancel={this.handleCancel}
                handleClean={this.handleClean}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No hay ruta seleccionada
              </div>
            )}
          </Grid>
        </Grid>
        <Modal />
        {/* <ModalMessage /> */}
      </div>
    );
  }
}
const mapStateToProps = ({
  routeReducer,
  vehicleReducer,
  pointAcopioReducer,
}) => {
  return { routeReducer, vehicleReducer, pointAcopioReducer };
};
const mapDispatchToProps = {
  GetVehiclesAdmin,
  GetRoutesList,
  GetPointAcopio,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Routes));
