import {
  OBTENER_DATA_DEPARTMENTS,
  LOADING_DATA_DEPARTMENTS,
  MESSAGE_DELETE_DEAPARTMENT,
  CREATE_UPDATE_DEPARTMENT,
  UPDATE_DATA_DEPARTMENTS
} from "../Types/Gerencia";
import {
  EndPointGetDepartment,
  EndPointGetDepartmentID,
  EndPointDeleteDepartment
} from "../Services/EndPointDepartment";
import { HandleOpenGerencia } from "./ModalActions";
export const GetDepartments = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_DEPARTMENTS
  });

  const respuestaDepartments = await EndPointGetDepartment();
  dispatch({
    type: OBTENER_DATA_DEPARTMENTS,
    payload: respuestaDepartments.data.data
  });
};
export const GetDepartmentsId = (id, name) => async (dispatch, getState) => {
  const nombre = name === "" ? null : encodeURIComponent(name);
  const ids = id === "" ? null : id;
  const respuestaDepartments = await EndPointGetDepartmentID(ids, nombre);

  dispatch({
    type: UPDATE_DATA_DEPARTMENTS,
    payload: respuestaDepartments.data.data
  });
};
export const DeleteDepartments = id => async (dispatch, getState) => {
  const respuestaDepartments = await EndPointDeleteDepartment(id);
  if (!respuestaDepartments.data.success) {
    dispatch(HandleOpenGerencia());
    dispatch({
      type: MESSAGE_DELETE_DEAPARTMENT,
      payload: respuestaDepartments.data.message
    });
  } else {
    dispatch(GetDepartments());
  }
};

export const CreateUpdateDepartments = () => async (dispatch, getState) => {
  // const respuestaDepartments = await EndPointCreateUpdateDepartment(data);
  // if (respuestaDepartments.data.success) {
  //   dispatch(HandleOpenExitGerencia());
  //   dispatch(GetDepartments());

  dispatch({
    type: CREATE_UPDATE_DEPARTMENT
  });
  // }
};
