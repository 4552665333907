import api from "./ApiURLBase";
const EndPointGetDepartment = async () =>
  await api.get(`/departments/GetDepartments`);
const EndPointGetDepartmentID = async (id, name) =>
  await api.get(
    `/departments/GetDepartments?departmentId=${id}&departmentDescription=${name}`
  );
const EndPointDeleteDepartment = async id =>
  await api.delete(`/departments/DeleteDepartment/${id}`);
const EndPointCreateUpdateDepartment = async data =>
  await api.post(`/departments/CreateUpdateDepartment/`, data);

export {
  EndPointGetDepartment,
  EndPointDeleteDepartment,
  EndPointGetDepartmentID,
  EndPointCreateUpdateDepartment
};
