import React from "react";
import Typography from "@material-ui/core/Typography";
const TabPanel = props => {
  const { children, value, index, name, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
};

export default TabPanel;
