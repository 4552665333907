import api from "./ApiURLBase";

const EndPointReportDaily = async (fromDate, toDate) => {
  const result = await api.post(
    "/reports/daily",
    {
      fromDate: fromDate,
      toDate: toDate
    },
    {
      responseType: "blob"
    }
  );

  return result;
};

const EndPointReportDaily1 = async (fromDate, toDate) => {
  const result = await api.post(
    "/reports/daily1",
    {
      fromDate: fromDate,
      toDate: toDate
    },
    {
      responseType: "blob"
    }
  );

  return result;
};

const EndPointReportRange = async (valueIn, valueF) => {
  const result = await api.post(
    "/reports/range",
    {
      beginTime: valueIn,
      endTime: valueF
    },
    {
      responseType: "blob"
    }
  );

  return result;
};

const EndPointReportRoadMap = async (vehicleId, day) => {
  const result = await api.post(`/reports/roadmap`,
  {
    vehicleId : vehicleId,
    day: day
  },
  {
    responseType: "blob"
  }
  );
  return result;
}

const EndPointReportIncident = async (dateInitial, dateFinal, id) => {
  const result = await api.get(
    `/incidents/GetIncidentReport?dateFrom=${dateInitial}&dateTo=${dateFinal}&miningSiteId=${id}`,

    {
      responseType: "blob"
    }
  );

  return result;
};

const EndPointReportWarehouse = async (dateFrom, dateTo, miningSiteId, warehouseId) => {
  const result = await api.get(
    `/reports/GetReportTempYardsByWarehouse?dateFrom=${dateFrom}&dateTo=${dateTo}&miningSiteId=${miningSiteId}&warehouseId=${warehouseId}`,

    {
      responseType: "blob"
    }
  );

  return result;
};



export { EndPointReportDaily, EndPointReportRange, EndPointReportIncident, EndPointReportRoadMap, EndPointReportWarehouse, EndPointReportDaily1 };
