import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import style from "../../assets/jss/disal-main/components/Header";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: localStorage.getItem("type") === "4" ? "Salidas" : "Operation",
    };
  }
  async componentDidMount() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1],
    });
  }
  async componentWillReceiveProps() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1],
    });
  }

  handleMenu = async (e) => {
    await this.setState({
      name: e.currentTarget.name,
    });
  };

  render() {
    return (
      <Hidden mdDown>
        <AppBar className={this.props.classes.appBar} position="static">
          {localStorage.getItem("type") === "4" ? (
            <List className={this.props.classes.list}>
              <Link
                to="/Main-Sesion/Salidas"
                className={
                  this.props.classes.item +
                  (this.state.name === "Salidas" ? " selectActive" : "")
                }
                name="Salidas"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Salidas
                </ListItem>
              </Link>
            </List>
          ) : (
            <List className={this.props.classes.list}>
              <Link
                to="/Main-Sesion/Operation"
                className={
                  this.props.classes.item +
                  (this.state.name === "Operation" ? " selectActive" : "")
                }
                name="Operation"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Operación
                </ListItem>
              </Link>
              {localStorage.getItem("type") === "5" ? null : (
                <Link
                  to="/Main-Sesion/Ingresos"
                  className={
                    this.props.classes.item +
                    (this.state.name === "Ingresos" ? " selectActive" : "")
                  }
                  name="Ingresos"
                  onClick={this.handleMenu}
                >
                  <ListItem button className={this.props.classes.itemLink}>
                    Ingresos
                  </ListItem>
                </Link>
              )}

              <Link
                to="/Main-Sesion/Stock"
                className={
                  this.props.classes.item +
                  (this.state.name === "Stock" ? " selectActive" : "")
                }
                name="Stock"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Stock
                </ListItem>
              </Link>
              {localStorage.getItem("type") === "5" ? null : (
                <Link
                  to="/Main-Sesion/Salidas"
                  className={
                    this.props.classes.item +
                    (this.state.name === "Salidas" ? " selectActive" : "")
                  }
                  name="Salidas"
                  onClick={this.handleMenu}
                >
                  <ListItem button className={this.props.classes.itemLink}>
                    Salidas
                  </ListItem>
                </Link>
              )}

              <Link
                to="/Main-Sesion/Report"
                className={
                  this.props.classes.item +
                  (this.state.name === "Report" ? " selectActive" : "")
                }
                name="Report"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Reportes
                </ListItem>
              </Link>
              <Link
                to="/Main-Sesion/Dashboard"
                className={
                  this.props.classes.item +
                  (this.state.name === "Dashboard" ? " selectActive" : "")
                }
                name="Dashboard"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Dashboard
                </ListItem>
              </Link>
            </List>
          )}
        </AppBar>
      </Hidden>
    );
  }
}

export default withStyles(style)(Header);
