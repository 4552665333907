import api from "./ApiURLBase";
const EndPointGetRoutesList = async () =>
  await api.get(`/routes/GetRoutesList`);

const EndPointGetRoutes = async () => await api.get(`/routes/GetRoute`);
const EndPointGetRoutesEspecific = async (id) =>
  await api.get(`/routes/GetRoute?routeIdReq=${id}`);

const EndPointCreateUpdateRoute = async (data) =>
  await api.post(`/routes/CreateUpdateRoute`, data);

export {
  EndPointGetRoutesList,
  EndPointGetRoutes,
  EndPointCreateUpdateRoute,
  EndPointGetRoutesEspecific,
};
