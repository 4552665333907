import React, { Component } from "react";
import { connect } from "react-redux";
import * as ingresosAction from "../Actions/IngresosAcions";
import { withStyles } from "@material-ui/core/styles";
import style from "../assets/jss/disal-main/Layouts/Ingresos";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "../Component/Modal/ModalIngres";
import Pagination from "../Component/Pagination/PaginationPatio";
import { DatePicker, message } from "antd";
import axios from "axios";
import "antd/dist/antd.css";
import moment from "moment";
import { URLIngresosId, URLIngresoSpecficId } from "../other/apis";
const { GetClients, GetPatios } = ingresosAction;
class Ingresos extends Component {
  constructor() {
    super();
    this.state = {
      pager: 1,
      total: "",
      idMiningSite: localStorage.getItem("miningSiteId"),
      ingresos: [],
      openIngreso: false,
      valueSelectPatios: 0,
      valueSlectClient: 0,
      FromDay: moment().subtract(1, "day").format("YYYY-MM-DD"),
      ToDay: moment().format("YYYY-MM-DD"),
      data: [],
      dataModal: "",
      dataDetail: null,
    };
  }

  async componentDidMount() {
    const { ingresosReducer } = this.props;
    if (!ingresosReducer.client.length) {
      await this.props.GetClients();
    }

    if (!ingresosReducer.patios.length) {
      await this.props.GetPatios();
    }

    await this.getIngresos();
  }

  getIngresos = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    const respuestaIngresos = await axios.get(
      URLIngresosId(
        this.state.pager,
        this.state.idMiningSite,
        this.state.valueSelectPatios,
        moment(this.state.FromDay).utc().valueOf(),
        moment(this.state.ToDay).utc().valueOf()
      ),
      { headers }
    );
    await this.setState({
      total: respuestaIngresos.data.result.totalPages,
      data: respuestaIngresos.data.result.inputs,
    });
  };
  onChangePage = async (data) => {
    await this.setState({
      pager: data.page,
    });
    await this.getIngresos();
  };
  handleClickModal = async (e) => {
    const id = e.target.id;
    const idData = e.target.dataset.id;
    await this.setState({ dataModal: id, openIngreso: true });
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    const respuestaIngresos = await axios.get(URLIngresoSpecficId(idData), {
      headers,
    });
    await this.setState({
      dataDetail: respuestaIngresos.data.result,
    });
  };
  handleClose = () => {
    this.setState({
      openIngreso: false,
    });
    this.getIngresos();
    this.setState({
      dataDetail: null,
    })
  };
  handleSelectPatios = async (e) => {
    await this.setState({ valueSelectPatios: e.target.value });
    await this.getIngresos();
  };
  renderItems = (item, index) => {
    return (
      <TableRow key={"row-data-" + index}>
        <TableCell>{item.inputDate !== null ? item.inputDate : "-"}</TableCell>
        <TableCell>{item.inputTime !== null ? item.inputTime : "-"}</TableCell>
        <TableCell>
          {item.vehiclePlateNumber !== null ? item.vehiclePlateNumber : "-"}
        </TableCell>
        <TableCell>
          {item.vehicleTypeName !== null ? item.vehicleTypeName : "-"}
        </TableCell>
        <TableCell>
          {item.operatorName !== null ? item.operatorName : "-"}
        </TableCell>
        <TableCell>{item.totalKG !== null ? item.totalKG : "-"}</TableCell>
        <TableCell>
          <button
            type="button"
            className="btn-ingresos"
            id={index}
            data-id={item.inputId}
            onClick={async (e) => await this.handleClickModal(e)}
          >
            Ver detalle
          </button>
        </TableCell>
      </TableRow>
    );
  };
  handleChangeInputFrom = async (e) => {
    if (moment(this.state.ToDay) >= moment(e)) {
      await this.setState({ FromDay: moment(e).format("YYYY-MM-DD") });
      await this.getIngresos();
    } else {
      message.error(
        `La fecha debe ser igual o menor a la fecha ${this.state.ToDay}`,
        1.5
      );
      await this.setState({ FromDay: this.state.ToDay });
    }
  };
  handleChangeInputTo = async (e) => {
    if (moment(this.state.FromDay) <= moment(e)) {
      await this.setState({ ToDay: moment(e).format("YYYY-MM-DD") });
      await this.getIngresos();
    } else {
      message.error(
        `La fecha debe ser igual o mayor a la fecha ${this.state.FromDay}`,
        1.5
      );
      await this.setState({ ToDay: this.state.FromDay });
    }
  };
  render() {
    return (
      <Grid container className={this.props.classes.ingresos}>
        <h4>Revisa los ingresos de residuo a Patio Temporal</h4>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.idMiningSite}
                  disabled
                >
                  {this.props.ingresosReducer.client.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.valueSelectPatios}
                  onChange={this.handleSelectPatios}
                >
                  <MenuItem value={0}>Todos los Patios</MenuItem>
                  {this.props.ingresosReducer.patios.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <DatePicker
                value={moment(moment(this.state.FromDay), "DD/MM/YYYY")}
                onChange={this.handleChangeInputFrom}
                size="large"
                className="header-select"
                placeholder="Desde"
                format="DD/MM/YYYY"
              />
            </Grid>
            <Grid item sm={2}>
              <DatePicker
                value={moment(moment(this.state.ToDay), "DD/MM/YYYY")}
                onChange={this.handleChangeInputTo}
                size="large"
                className="header-select"
                placeholder="Desde"
                format="DD/MM/YYYY"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} className={this.props.classes.mainTable}>
          <h3
            style={{
              color: "#0D0D0D",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            Mostrando ingresos de residuos para Cliente{" "}
            {localStorage.getItem("miningSite")}
          </h3>
          <TableContainer className={this.props.classes.contentTable}>
            <Table
              className={this.props.classes.tableGeneral}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={this.props.classes.cellOperativo}>
                  <TableCell>FECHA</TableCell>
                  <TableCell>HORA</TableCell>
                  <TableCell>VEHICULO</TableCell>
                  <TableCell>TIPO DE VEHICULO</TableCell>
                  <TableCell>OPERADOR PATIO</TableCell>
                  <TableCell>TOTAL KG</TableCell>

                  <TableCell>DETALLE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data.map((item, index) =>
                  this.renderItems(item, index)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Pagination
          totalRecords={this.state.total}
          pageLimit={15}
          initialPage={1}
          pageToShow={4}
          onChangePage={this.onChangePage}
        />
        {this.state.dataDetail && (
          <Modal
            id={this.state.dataModal}
            data={this.state.dataDetail}
            open={this.state.openIngreso}
            handleClose={this.handleClose}
          />
        )}
      </Grid>
    );
  }
}
const mapStateToProps = ({ ingresosReducer }) => {
  return { ingresosReducer };
};
const mapDispatchToProps = {
  GetClients,
  GetPatios,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Ingresos));
