import api from "./ApiURLBase";
const EndPointIngresosClientes = async () =>
  await api.get(`/tempyards/GetClients`);
const EndPointPatiosId = async id =>
  await api.get(`/tempyards/GetWarehouses/${id}`);
const EndPointUpdateInputDetails = async (data) => {
  await api.post(`/tempyards/UpdateInputDetails`, data);
}

export { 
  EndPointIngresosClientes,
  EndPointPatiosId,
  EndPointUpdateInputDetails };

