const appStyle = theme => ({
  appBar: {
    backgroundColor: "#000000",
    position: "relative",
    boxShadow:
      " 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #ffffff",
    paddingLeft: "75px",
    paddingRight: "75px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px"
    }
  },
  logo: {
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: "bold",
    padding: "0",
    margin: "0",
    display: "flex",
    alignSelf: "center"
  },
  item: {
    "&.selectActive": {
      "& .MuiListItem-button": {
        color: "#ffffff"
      },
      borderBottom: "4px solid #ffffff"
    }
  },
  logoImage: {
    width: "38px",
    height: "38px",
    marginRight: "21px"
  },
  buttonText: {
    display: "block",

    fontFamily: '"Roboto"',
    fontSize: "14px",
    letterSpacing: " 0.4px",
    lineHeight: "16px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  MenuIcon: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block"
    }
  },
  list: {
    paddingLeft: "162px",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0"
    }
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "24px 37.75px 12px",
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 0.5)",
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      padding: "24px 17.75px 12px"
    }
  },
  dropDown: {
    "& .MuiPopover-paper": {
      top: "43px"
    }
  }
});

export default appStyle;
