export const OBTENER_DATA_OPERATORS = "obtener_data_user";
export const LOADING_DATA_OPERATORS = "loading_data_user";
export const ERROR_DATA_OPERATORS = "error_data_user";

export const UPDATE_DATA_OPERATORS = "actualizar_data_user";

export const OBTENER_DATA_OPERATORS_TYPE = "obtener_data_user_type";
export const LOADING_DATA_OPERATORS_TYPE = "loading_data_user_type";
export const ERROR_DATA_OPERATORS_TYPE = "error_data_user_type";

// export const OBTENER_DATA_OPERATORS_ESPECIFIC = "obtener_data_user_especific";
// export const LOADING_DATA_OPERATORS_ESPECIFIC = "loading_data_user_especific";
// export const ERROR_DATA_OPERATORS_ESPECIFIC = "error_data_user_especific";

export const MESSAGE_DELETE_OPERATOR = "message_delete_operator";
export const CREATE_UPDATE_OPERATOR = "create_update_operator";
