import api from "./ApiURLBase";
const EndPointGetPoints = async () =>
  await api.get(`/collectPoints/GetCollectPoints`);
const EndPointGetPointsEspecific = async (id, number, name, type) =>
  await api.get(
    `/collectPoints/GetCollectPoints?collectPointId=${id}&nameDescription=${name}&numberDescription=${number}&departmentId=${type}`
  );
const EndPointDeletePoint = async id =>
  await api.delete(`/collectPoints/DeleteCollectPoint/${id}`);
const EndPointCreateUpdateVehiclePoint = async data =>
  await api.post(`/collectPoints/CreateUpdateCollectPoint`, data);
//  ------------------------- Container ---------------------------------------
const EndPointGetContainers = async id =>
  await api.get(`/containers/GetContainer?collectPointId=${id}`);
const EndPointGetTypeContainer = async () =>
  await api.get(`/containers/GetContainerModel`);
const EndPointGetTypeResidue = async () =>
  await api.get(`/residual-subtypes/GetWasteSubType`);
const EndPointCreateUpdateCollectPoint = async data =>
  await api.post(`/containers/UpdateContainerCollectPoint`, {
    container: data
  });
export {
  EndPointGetPoints,
  EndPointDeletePoint,
  EndPointCreateUpdateVehiclePoint,
  EndPointGetContainers,
  EndPointGetTypeContainer,
  EndPointGetTypeResidue,
  EndPointCreateUpdateCollectPoint,
  EndPointGetPointsEspecific
};
