import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Pagination";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLimit: "",
      totalPages: "",
      currentPage: "",
      initialPage: 1,
      pagesToShow: "",
      status: ""
    };
  }

  async componentDidMount() {
    await this.setState({
      pageLimit: this.props.pageLimit || 10,
      totalPages: this.props.totalRecords,
      pagesToShow: this.props.pagesToShow || 5,
      currentPage: this.props.initialPage || 1,
      status: this.props.data
    });
    await this.setPage(this.props.initialPage);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({
      pageLimit: nextProps.pageLimit || 10,
      totalPages: nextProps.totalRecords,
      pagesToShow: nextProps.pagesToShow || 5,
      status: nextProps.data
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.status !== prevProps.data) {
      await this.setPage(1);
    }
  }

  async setPage(page) {
    var { pageLimit, totalPages } = this.state;
    if (page <= 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }

    await this.setState({
      currentPage: page
    });

    this.props.onChangePage({
      pageLimit,
      totalPages,
      page
    });
  }

  getPager() {
    var { pagesToShow, currentPage, totalPages } = this.state;
    var pages = [],
      startFromNumber;
    if (totalPages <= pagesToShow) {
      startFromNumber = 1;
      pagesToShow = totalPages;
    } else {
      if (currentPage <= Math.ceil(pagesToShow / 2)) {
        startFromNumber = 1;
      } else if (
        currentPage + Math.floor((pagesToShow - 1) / 2) >=
        totalPages
      ) {
        startFromNumber = totalPages - (pagesToShow - 1);
      } else {
        startFromNumber = currentPage - Math.floor(pagesToShow / 2);
      }
    }

    for (let i = 1; i <= pagesToShow; i++) {
      pages.push(startFromNumber++);
    }

    return {
      currentPage,
      totalPages,
      pages
    };
  }

  render() {
    if (this.state.totalPages === 1) return null;
    var pager = this.getPager();
    return (
      <div className={this.props.classes.patio}>
        <ul className="pagination">
          <li className={pager.currentPage === 1 ? "disabled" : ""}>
            <button onClick={() => this.setPage(pager.currentPage - 1)}>
              <ChevronLeftIcon />
            </button>
          </li>
          {pager.pages.map((page, index) => (
            <li
              key={index}
              className={pager.currentPage === page ? "active" : ""}
            >
              <button onClick={() => this.setPage(page)}>{page}</button>
            </li>
          ))}
          <li
            className={pager.currentPage === pager.totalPages ? "disabled" : ""}
          >
            <button onClick={() => this.setPage(pager.currentPage + 1)}>
              <ChevronRightIcon />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default withStyles(style)(Pagination);
