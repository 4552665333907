import React, { Component } from "react";
import { connect } from "react-redux";
import * as gerenciaAction from "../../Actions/GerenciaActions";
import * as modalAction from "../../Actions/ModalActions";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import style from "../../assets/jss/disal-main/components/Modal";
const { GetDepartments, DeleteDepartments } = gerenciaAction;
const { HandleCloseExitVehicle } = modalAction;

class ModalPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      resetPassword: "",
      showPassword: false,
      error: { password: "", resetPassword: "" }
    };
  }

  handleChangePassowrd = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    });
  };
  handleChangeReset = e => {
    const { error } = this.state;
    const name = e.target.name;
    const value = e.target.value;
    if (this.state.password === value) {
      this.setState({
        [name]: value,
        error: { password: "", resetPassword: "" }
      });
    } else {
      error.resetPassword = "* Las contraseñas no coinciden";
      this.setState({
        error,
        [name]: value
      });
    }
  };
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleConfirm = () => {
    const { error } = this.state;

    if (this.state.password === "" || this.state.resetPassword === "") {
      error.resetPassword = "*Completar los campos";
    }

    if (this.state.password !== this.state.resetPassword) {
      error.resetPassword = "*Contraseñas no coinciden";
    }

    this.setState({ error });
    if (
      this.state.password !== "" &&
      this.state.resetPassword !== "" &&
      this.state.password === this.state.resetPassword
    ) {
      this.props.handleUpdatePass(this.state.resetPassword);

      this.handleCancel();
    }
  };
  handleCancel = () => {
    this.setState({
      password: "",
      resetPassword: "",
      showPassword: false,
      error: { password: "", resetPassword: "" }
    });
    this.props.handleClose();
  };
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.open}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.open}>
          <div className={this.props.classes.paper} style={{ width: "500px" }}>
            <div className="modal-confirm">
              <h3>Resetear Contraseña</h3>
            </div>
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <div className="inputs-form">
                    <Grid container>
                      <Grid item xs={4}>
                        <label>Contraseña Nueva</label>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl
                          className={this.props.classes.formControl}
                          variant="outlined"
                        >
                          <OutlinedInput
                            name="password"
                            id="outlined-adornment-password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.password}
                            onChange={this.handleChangePassowrd}
                            inputProps={{
                              maxLength: 50
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="inputs-form">
                    <Grid container>
                      <Grid item xs={4}>
                        <label>Repita Contraseña</label>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl
                          className={this.props.classes.formControl}
                          variant="outlined"
                        >
                          <OutlinedInput
                            name="resetPassword"
                            id="outlined-adornment-password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.resetPassword}
                            onChange={this.handleChangeReset}
                            inputProps={{
                              maxLength: 50
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <p>
                          {this.state.error.resetPassword === ""
                            ? ""
                            : this.state.error.resetPassword}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-modal-salida"
                style={{ textDecoration: "underline", marginRight: "10px" }}
                onClick={() => this.handleCancel()}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn-modal-salida"
                style={{ textDecoration: "underline", marginRight: "10px" }}
                onClick={() => this.handleConfirm()}
              >
                Confirmar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
const mapStateToProps = ({ modalReducer }) => {
  return { modalReducer };
};
const mapDispatchToProps = {
  GetDepartments,
  DeleteDepartments,
  HandleCloseExitVehicle
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ModalPassword));
