import React, { Component } from "react";
import { connect } from "react-redux";
import * as operationAction from "../Actions/OperatorAction";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import style from "../assets/jss/disal-main/Layouts/Gerencia";
import FormOperator from "../Component/Form/Operator";
import Modal from "../Component/Modal/ModalDeleteOperator";
import ModalMessage from "../Component/Modal/ModalMessageDeleteUser";

import Filter from "../Component/Filters/Users";
const { GetOperators, GetOperatorType, GetOperatorsId } = operationAction;
class Users extends Component {
  constructor() {
    super();
    this.state = {
      idSelect: null,
      statusForm: false,
      dni: "",
      id: "",
      name: "",
      type: 0,
      data: {},
      open: false,
    };
  }
  componentDidMount = async () => {
    const { GetOperators } = this.props;
    if (!this.props.operatorReducer.operator.length) {
      await GetOperators();
    }
    await this.props.GetOperatorType();
    await this.props.GetOperatorsId(null, null, null, 0);
  };

  handleClickOperator = async (e) => {
    const id = e.currentTarget.id;

    const filter = this.props.operatorReducer.operator.filter(
      (res) => Number(id) === res.id
    );

    await this.setState({
      statusForm: true,

      idSelect: Number(id),
      data: filter[0],
    });
  };

  handleSaveTemp = (data) => {
    this.setState({
      data,
    });
  };
  HandleSearchId = async (e) => {
    const value = e.target.value;
    const name = this.state.name;
    const dni = this.state.dni;
    const type = this.state.type;

    var regex = /^([1-9])*$/;
    if (regex.test(value)) {
      await this.setState({
        id: value,
      });
      await this.props.GetOperatorsId(value, dni, name, type);
    }
    if (value === "") {
      await this.setState({
        id: value,
      });
      await this.props.GetOperatorsId(null, dni, name, type);
    }
  };
  HandleSearchDni = async (e) => {
    const value = e.target.value;
    const name = this.state.name;
    const id = this.state.id;
    const type = this.state.type;
    if (value.length > 3) {
      await this.setState({
        dni: value,
      });
      await this.props.GetOperatorsId(id, value, name, type);
    } else {
      await this.setState({
        dni: value,
      });
      await this.props.GetOperatorsId(id, null, name, type);
    }
  };
  HandleSearchName = async (e) => {
    const value = e.target.value;

    const dni = this.state.dni;
    const id = this.state.id;
    const type = this.state.type;
    if (value.length > 3) {
      await this.setState({
        name: value,
      });
      await this.props.GetOperatorsId(id, dni, value, type);
    } else {
      await this.setState({
        name: value,
      });
      await this.props.GetOperatorsId(id, dni, null, type);
    }
  };
  HandleSearchType = async (e) => {
    const value = e.target.value;
    const dni = this.state.dni;
    const id = this.state.id;
    const name = this.state.name;
    if (value !== 0) {
      await this.setState({
        type: value,
      });
      await this.props.GetOperatorsId(id, dni, name, value);
    } else {
      await this.setState({
        type: 0,
      });
      await this.props.GetOperatorsId(id, dni, name, value);
    }
  };

  handleOpen = async () => {
    await this.setState({
      open: true,
    });
  };
  handleClose = async () => {
    await this.setState({
      open: false,
    });
  };
  handleStatus = async () => {
    await this.setState({
      idSelect: null,
      statusForm: true,
      data: {},
      open: false,
      dni: "",
      id: "",
      name: "",
      type: 0,
    });
  };
  handleClean = async () => {
    await this.setState({
      idSelect: null,
      data: {},
      dni: "",
      id: "",
      name: "",
      type: 0,
    });
  };
  handleCancel = async () => {
    await this.setState({
      statusForm: false,
      data: {},
      idSelect: null,
      dni: "",
      id: "",
      name: "",
      type: 0,
    });
  };
  render() {
    return (
      <div className={this.props.classes.content}>
        <Grid container className={this.props.classes.gerencia}>
          <Grid item xs={12}>
            <Filter
              dni={this.state.dni}
              id={this.state.id}
              name={this.state.name}
              type={this.state.type}
              HandleSearchDni={this.HandleSearchDni}
              HandleSearchId={this.HandleSearchId}
              HandleSearchName={this.HandleSearchName}
              HandleSearchType={this.HandleSearchType}
            />
          </Grid>
          <Grid item xs={4} className={this.props.classes.listAdd}>
            <h3 className={this.props.classes.title}>Lista de Usuarios</h3>
            <List className={this.props.classes.listGerencia}>
              {this.props.operatorReducer.operator.map((res, index) => (
                <ListItem
                  button
                  id={res.id}
                  className={
                    this.props.classes.Listbutton +
                    (this.state.idSelect === res.id ? " active-item" : null)
                  }
                  onClick={this.handleClickOperator}
                  key={index}
                >
                  <ListItemText primary={res.name} />
                  {this.state.idSelect === res.id ? (
                    <button
                      id={res.id}
                      className={this.props.classes.iconClose}
                      onClick={this.handleOpen}
                    >
                      <CloseIcon />
                    </button>
                  ) : null}
                </ListItem>
              ))}
            </List>
            <button
              className={this.props.classes.iconMore}
              onClick={this.handleStatus}
            >
              <AddIcon />
            </button>
          </Grid>
          <Grid item xs={8} className={this.props.classes.formGerencia}>
            <h3 className={this.props.classes.title}>Datos del Usuario</h3>
            {this.state.statusForm ? (
              <FormOperator
                data={this.state.data}
                handleCancel={this.handleCancel}
                handleSaveTemp={this.handleSaveTemp}
                handleClean={this.handleClean}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No hay usuario seleccionado{" "}
              </div>
            )}
          </Grid>
        </Grid>
        <Modal
          handleClose={this.handleClose}
          open={this.state.open}
          id={this.state.idSelect}
        />
        <ModalMessage />
      </div>
    );
  }
}
const mapStateToProps = ({ operatorReducer }) => {
  return { operatorReducer };
};
const mapDispatchToProps = {
  GetOperators,
  GetOperatorType,
  GetOperatorsId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Users));
