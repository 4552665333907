import api from "./ApiURLBase";
const EndPointGetVehicle = async () => await api.get(`/vehicles`);
const EndPointGetDashWorkVehicle = async () =>
  await api.get(`/dashboard/work/vehicles`);
const EndPointGetDashWorkVehiclesId = async (id) => {
  return api.get(`/dashboard/work/vehicles/${id}`);
};
const EndPointGetDashWorkProgramated = async (id) => {
  return api.get(`/dashboard/work/vehiclesNotProgramated `);
};
const EndPointGetDashWork = async () => await api.get(`/dashboard/work`);
const EndpointDashWorkCollect = async (idV, idP, value) => {
  return api.get(
    `/dashboard/work/vehicles/${idV}/collect-points/${idP}?isProgramated=${value}`
  );
};
export {
  EndPointGetVehicle,
  EndPointGetDashWork,
  EndPointGetDashWorkVehicle,
  EndPointGetDashWorkProgramated,
  EndPointGetDashWorkVehiclesId,
  EndpointDashWorkCollect,
};
