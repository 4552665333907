import moment from "moment";
const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];
const mesesAbrev = [
  "Ene.",
  "Feb.",
  "Mar.",
  "Abr.",
  "May.",
  "Jun.",
  "Jul.",
  "Ago.",
  "Sep.",
  "Oct.",
  "Nov.",
  "Dic."
];
export function getCurrentDate() {
  let newDate = new Date();
  let date = newDate.getDate();
  let monthNumber = ("0" + (newDate.getMonth() + 1)).slice(-2);
  let month = newDate.getMonth();
  let year = newDate.getFullYear();
  let today = date + " " + meses[month];
  return {
    today: today,
    date: date,
    month: month,
    year: year,
    monthNumber: monthNumber
  };
}

export function DateDetail(params) {
  let date = moment(params);
  let day = date.format("DD");
  let month = date.format("MM");
  let textMonth = mesesAbrev[month - 1];
  let hour = date.format("HH");
  let minute = date.format("mm");

  return {
    day,
    textMonth,
    hour,
    minute
  };
}
