import {
  LOADING_DATA_ROUTES,
  OBTENER_DATA_ROUTES,
  CREATE_UPDATE_ROUTES,
} from "../Types/Routes";
import {
  EndPointGetRoutesList,
} from "../Services/EndPointRoutes";

export const GetRoutesList = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_ROUTES,
  });

  const respuestaPoint = await EndPointGetRoutesList();
  dispatch({
    type: OBTENER_DATA_ROUTES,
    payload: respuestaPoint.data.data,
  });
};

// export const GetRoutes = () => async (dispatch, getState) => {
//   dispatch({
//     type: LOADING_DATA_ROUTES,
//   });

//   const respuestaPoint = await EndPointGetRoutes();
//   dispatch({
//     type: OBTENER_DATA_ROUTES,
//     payload: respuestaPoint.data.data,
//   });
// };

export const CreateUpdateRoute = () => async (dispatch, getState) => {
  dispatch({
    type: CREATE_UPDATE_ROUTES,
  });
};
