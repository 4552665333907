import React, { Component } from "react";
import { connect } from "react-redux";
import * as ingresosAction from "../Actions/IngresosAcions";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import style from "../assets/jss/disal-main/Layouts/Stock";
import CardStock from "./../Component/Card/Card-Stock";
import { URLStock } from "../other/apis";
import axios from "axios";
const today = moment().format("DD/MM/YYYY");
const { GetClients, GetPatios } = ingresosAction;


class Stock extends Component {
  constructor() {
    super();
    this.state = {
      valueSelectPatios: 0,
      idMiningSite: localStorage.getItem("miningSiteId"),
      valueSelectClient: localStorage.getItem("miningSiteId"),
      data: [],
    };
  }
  async componentDidMount() {
    const { ingresosReducer } = this.props;

    if (!ingresosReducer.patios.length) {
      await this.props.GetPatios();
    }
    if (!ingresosReducer.client.length) {
      await this.props.GetClients();
    }

    await this.getStock();
  }

  getStock = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    const respuestaStock = await axios.get(
      URLStock(this.state.valueSelectPatios),
      { headers }
    );
    this.setState({ data: respuestaStock.data.data });
  };
  handleSelectClient = async (e) => {
    await this.setState({ valueSelectClient: Number(e.target.value) });
    await this.getStock();
  };

  handleSelectPatios = async (e) => {
    await this.setState({ valueSelectPatios: e.target.value });
    await this.getStock();
  };
  render() {
    return (
      <Grid container className={this.props.classes.ingresos}>
        <h4>Inventario de residuos por patio a la fecha {today}</h4>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.valueSelectClient}
                  onChange={this.handleSelectClient}
                  disabled={
                    localStorage.getItem("type") === "4" ? "" : "disabled"
                  }
                >
                  {this.props.ingresosReducer.client.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={2}>
              <FormControl
                variant="outlined"
                className={this.props.classes.formControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.valueSelectPatios}
                  onChange={this.handleSelectPatios}
                >
                  <MenuItem value={0}>Todos los Patios</MenuItem>
                  {this.props.ingresosReducer.patios.map((res, key) => {
                    return (
                      <MenuItem key={key} value={res.id}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} className={this.props.classes.contentCard}>
          <Grid container>
            {/*
            {this.state.data.map((store, idx) => (
              <Grid item sm={12} xs={12} md={4} key={store.id} className="card-material">
                <div style={{ 
                  background: "#fff", 
                  padding: "20px",
                  boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)"
                   }} >
                  <div className="header-card" style={{
                    color: "#030303",
                    fontFamily: "Roboto",
                    alignItems: "center",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}>
                      {store.name}
                  </div>
                  <hr className="my-0" />
                  {store.genericTypes.map((genericType, idx) => (
                    <div>
                    <div key={genericType.id} className="header-card-stock">
                        <div style={{
                          backgroundColor: `${genericType.color}`,
                          border: '1px solid black'
                          }} className="circle-stock">
                        </div>
                        <div style={{margin: "0 auto"}}>{genericType.name}</div>
                    </div>
                      {genericType.subtypes.map((subtype, idx) => (
                        <div style={{display: "flex", margin: "15px 0px"}} key={subtype.id}>
                          <div style={{flex: 2}}>{subtype.name}</div>
                          <div style={{flex: 1, textAlign: "center"}}>{`kg`}</div>
                          <div style={{flex: 1}}>{subtype.stock}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </Grid>
            ))}
            */}
            

            <Grid item sm={8}>
              <Grid container>
                {this.state.data.map((res, key) => {
                  if (res.subtypes.length === 1) {
                    return (
                      <CardStock
                        key={key}
                        name={res.name}
                        color={res.color}
                        peso={res.stock + " kg"}
                      />
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Grid container>
                {this.state.data.map((res1, key1) => {
                  if (res1.subtypes.length > 1) {
                    const sort = res1.subtypes.sort(
                      (a, b) => b.stock - a.stock
                    );
                    return (
                      <Grid item sm={12} key={key1} className="card-material">
                        <div style={{ background: "#fff" }}>
                          <div className="header-card-stock">
                            <div
                              className="circle-stock"
                              style={{
                                backgroundColor: res1.color,
                              }}
                            ></div>
                            <p className="mb-0">{res1.name}</p>
                          </div>
                          <hr className="my-0" />
                          <div className="content-stock-peligroso">
                            {sort.map((resSubtypes, key) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                  key={key}
                                >
                                  <label
                                    style={{
                                      width: "50%",
                                      textAlign: "left",
                                      marginLeft: "50px",
                                    }}
                                  >
                                    {resSubtypes.name}
                                  </label>
                                  <p style={{ width: "30%" }}>
                                    {resSubtypes.stock + " kg"}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          <hr className="my-0" />
                          <div className="content-result-stock">
                            {res1.stock + " kg"}
                          </div>
                        </div>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = ({ ingresosReducer }) => {
  return { ingresosReducer };
};
const mapDispatchToProps = {
  GetClients,
  GetPatios,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Stock));
