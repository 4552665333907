const appStyle = theme => ({
  titleOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h5": {
      marginBottom: "0",
      color: "#000000",
      fontFamily: "Roboto",
      fontSize: "16px",
      lineHeight: "19px"
    },
    "& button": {
      border: "0",
      background: "none"
    }
  },
  percentage: {
    fontSize: "20px",
    minWidth: "40px",
    fontWeight: "500",
    lineHeight: "16px",
    marginBottom: "0",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px"
    }
  },
  viewGeneral: {
    fontFamily: '"Roboto"',
    fontSize: "16px",
    lineHeight: "19px",
    border: "0",
    borderBottom: "2px solid #000000",
    background: "none",
    marginLeft: "0",
    padding: "0 0 1px",
    cursor: "pointer",
    position: "relative",
    zIndex: "10"
  },
  programated: {
    fontFamily: '"Roboto"',
    fontSize: "16px",
    lineHeight: "19px",
    border: "0",
    borderBottom: "2px solid #000000",
    background: "none",
    marginLeft: "15px",
    padding: "0 0 1px",
    cursor: "pointer",
    position: "relative",
    zIndex: "10"
  },
  transitionSlide: {
    maxHeight: "0",
    transition: "max-height 1s ease-out",
    overflow: "hidden",
    "& .arrow-style": {
      width: "55px",
      height: "55px"
    },
    "& .slick-arrow": {
      borderRadius: "50%",
      height: "65px",
      width: "65px",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
      color: "#ffffff",
      border: "none",
      fontSize: "40px",
      cursor: "pointer",
      outline: "none",
      transition: "all 0.2s linear",
      "&.muisvgicon-root": {
        display: "block",
        margin: "auto"
      }
    },
    "& .slick-prev": {
      left: "0.5%",
      zIndex: "2"
    },
    "& .slick-next": {
      right: "0.5%",
      zIndex: "2"
    },
    "& .slick-next:focus, & .slick-next:hover, & .slick-prev:focus,& .slick-prev:hover ": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      color: "#ffffff"
    },
    " & .slick-prev:before,& .slick-next:before": {
      content: "none"
    },

    "& .slick-dots ": {
      bottom: "-30px"
    },
    "& .slick-dots li button": {
      backgroundColor: "#d6d6d6"
    },
    "& .slick-dots .slick-active button": {
      backgroundColor: "#949494"
    }
  },
  openSlide: {
    maxHeight: "500px",
    transition: "max-height 1s ease-in"
  },
  card: {
    maxWidth: 206,
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset"
    },
    boxShadow: "none",
    cursor: "pointer",
    "&.slider-item": {
      "& .bcContent": {
        color: "#ffffff",
        background: "#1c4d9d"
      }
    }
  },
  cardBody: {
    display: "flex",
    color: "#000000",
    padding: "15px 10px",
    background: "#EDEDED",
    alignItems: "center",
    borderRadius: "0 0 5px 5px",
    "& img": {
      width: "38px",
      height: "38px",
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        width: "18px",
        height: "18px"
      }
    },
    "& .body-content": {
      marginLeft: "10px",

      "& p": {
        fontFamily: "'Roboto'",
        fontWeight: "500"
      },
      "& .main-word": {
        marginBottom: "1px",
        fontSize: "16px",
        lineHeight: "19px",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px"
        }
      },
      "& .total-count": {
        marginBottom: "0",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "16px",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px"
        }
      }
    }
  }
});

export default appStyle;
