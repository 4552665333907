const appStyle = theme => ({
  pagination: {
    marginBottom: "0",
    display: "flex",
    paddingLeft: "0",
    listStyle: "none",
    borderRadius: ".25rem",
    justifyContent: "unset",

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      justifyContent: "center"
    },
    "& li": {
      height: "100%",
      border: "1px solid #ededed",

      "& a": {
        display: "block",
        padding: "5px 14px",
        color: "#000",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "400",
        "&.disabled": {
          cursor: "unset",
          backgroundColor: "#dbd9d9"
        },
        "&.isActive": {
          backgroundColor: "#1c4d9d",
          color: "#fff !important"
        }
      }
    }
  },
  patio: {
    marginTop: "25px",
    display: "flex",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    "& .pagination": {
      marginBottom: "0",
      display: "flex",
      paddingLeft: "0",
      listStyle: "none",
      borderRadius: ".25rem",
      marginLeft: "auto",
      marginRight: "auto",
      alignItems: "center",
      "& li": {
        margin: "0 8px"
      },
      "& li button ": {
        border: "none",
        display: "flex",
        backgroundColor: "#ffffff",
        borderRadius: "100%",
        justifyContent: "center",
        padding: "5px 10px",
        "& .MuiSvgIcon-root": {
          width: "1.5em",
          height: "1.5em"
        }
      },
      "& li.active button ": {
        border: " 1px solid #d11f42",
        color: "#d11f42",
        fontSize: "22px",
        fontWeight: "700",
        width: "50px",
        height: "50px"
      }
    }
  }
});

export default appStyle;
