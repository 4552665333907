const appStyle = theme => ({
  contentTable: {
    height: "600px"
  },
  tableGeneral: {
    "& .MuiTableCell-root": {
      color: "#000000",
      fontFamily: '"Roboto"',
      fontSize: "13px",
      lineHeight: "18px",
      padding: "16px 5px"
    },
    "& .hideCell": {
      display: "none"
    },
    "& button.disab": {
      cursor: "unset",
      background: "#bfbfbf",
      color: "#000",
      border: "1px solid #bfbfbf"
    }
  },
  cellOperativo: {
    "& .MuiTableCell-root": {
      fontWeight: "bold"
    }
  }
});

export default appStyle;
