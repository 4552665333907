import React, { Component } from "react";
import { connect } from "react-redux";
import * as gerenciaAction from "../../Actions/GerenciaActions";
import * as modalAction from "../../Actions/ModalActions";
import { withStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import style from "../../assets/jss/disal-main/components/Modal";
const { GetDepartments, DeleteDepartments } = gerenciaAction;
const { HandleCloseUser } = modalAction;

class ModalMessage extends Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.modalReducer.openUser}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.modalReducer.openUser}>
          <div className={this.props.classes.paper} style={{ width: "500px" }}>
            <div className="modal-confirm">
              <p>{this.props.operatorReducer.message}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-modal-salida"
                style={{ textDecoration: "underline", marginRight: "10px" }}
                onClick={() => this.props.HandleCloseUser()}
              >
                OK
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
const mapStateToProps = ({ operatorReducer, modalReducer }) => {
  return { operatorReducer, modalReducer };
};
const mapDispatchToProps = {
  GetDepartments,
  DeleteDepartments,
  HandleCloseUser
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ModalMessage));
