import React from "react";

const LabelDate = props => {
  return (
    <div className="col-1" style={{ flex: "0 0 12%", maxWidth: "12%" }}>
      <label>{props.name}</label>
      <p>
        {props.value !== null && props.value !== undefined ? props.value : "-"}
      </p>
    </div>
  );
};

export default LabelDate;
