import React, { Component } from "react";
import TableGeneral from "../Table/tableGeneral";
import Hidden from "@material-ui/core/Hidden";
import CardTableGeneral from "../Card/CardTableGeneral";
class ContentGeneral extends Component {
  render() {
    return (
      <div>
        <TableGeneral
          pageOfItems={this.props.pageOfItems}
          open={this.props.open}
        />
        <Hidden mdUp>
          <CardTableGeneral
            pageOfItems={this.props.pageOfItems}
            open={this.props.open}
          />
        </Hidden>
      </div>
    );
  }
}

export default ContentGeneral;
