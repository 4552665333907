import api from "./ApiURLBase";
const EndPointGetVehicle = async () =>
  await api.get(`/vehicles/GetVehicles?travelType=I,A`);
const EndPointGetVehicleID = async (id, plateNumber, type) =>
  await api.get(
    `/vehicles/GetVehicles?travelType=I,A&vehicleId=${id}&plateNumber=${plateNumber}&vehicleTypeId=${type}`
  );
const EndPointDeleteVehicle = async id =>
  await api.delete(`/vehicles/DeleteVehicle/${id}`);
const EndPointCreateUpdateVehicle = async data =>
  await api.post(`/vehicles/CreateUpdateVehicle/`, data);
const EndPointGetVehicleType = async () =>
  await api.get(`/vehicleTypes/GetVehicleTypes`);
export {
  EndPointGetVehicle,
  EndPointDeleteVehicle,
  EndPointCreateUpdateVehicle,
  EndPointGetVehicleType,
  EndPointGetVehicleID
};
