import React, { Component } from "react";
import axios from "axios";
import { Modal } from "antd";

import { URLPutAccept } from "../../other/apis";
class ModalDenied extends Component {
  handleClickAccept = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    await axios.put(URLPutAccept, this.props.data, {
      headers
    });
    this.props.funct();
    this.props.onOkD();
    this.props.handleClean();
  };
  render() {
    return (
      <Modal
        title=" "
        visible={this.props.visibleDenied}
        onOk={() => this.props.onOkD()}
        onCancel={() => this.props.onOkD()}
        cancelText=""
        footer={null}
        okText="Rechazar"
      >
        <div className="modal-confirm">
          <h3>¿Confirma rechazo del traslado?</h3>
          <p>
            Esta solicitud será eliminada de la programación de salidas de
            residuos
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn-modal-salida"
            style={{ textDecoration: "underline", marginRight: "10px" }}
            onClick={() => this.props.onOkD()}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn-modal-salida"
            style={{ textDecoration: "underline" }}
            onClick={this.handleClickAccept}
          >
            Rechazar
          </button>
        </div>
      </Modal>
    );
  }
}

export default ModalDenied;
