export const OBTENER_DATA_VEHICLES = "obtener_data_vehiculos";
export const LOADING_DATA_VEHICLES = "loading_data_vehiculos";
export const ERROR_DATA_VEHICLES = "error_data_vehiculos";

export const UPDATE_DATA_VEHICLES = "actualizar_data_vehiculos";

export const OBTENER_DATA_VEHICLES_TYPE = "obtener_data_vehiculos_type";
export const LOADING_DATA_VEHICLES_TYPE = "loading_data_vehiculos_type";
export const ERROR_DATA_VEHICLES_TYPE = "error_data_vehiculos_type";

// export const OBTENER_DATA_VEHICLES_ESPECIFIC = "obtener_data_vehiculos_especific";
// export const LOADING_DATA_VEHICLES_ESPECIFIC = "loading_data_vehiculos_especific";
// export const ERROR_DATA_VEHICLES_ESPECIFIC = "error_data_vehiculos_especific";

export const MESSAGE_DELETE_VEHICLES = "message_delete_vehiculos";
export const CREATE_UPDATE_VEHICLES = "create_update_vehiculos";
