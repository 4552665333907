import React, { Component } from "react";

import { connect } from "react-redux";
import * as operatorAction from "../../Actions/OperatorAction";
import * as errorAction from "../../Actions/ErrorAction";
import * as modalActions from "../../Actions/ModalActions";

import Grid from "@material-ui/core/Grid";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import style from "../../assets/jss/disal-main/Layouts/Gerencia";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "../Modal/ModalExitoUser";
import ModalPassword from "../Modal/ModalPassword";

import { EndPointCreateUpdateOperator } from "../../Services/EndPointOperator";
const { HandleOpenUser, HandleOpenExitUser } = modalActions;
const { ErrorUser } = errorAction;
const { CreateUpdateOperator, GetOperators } = operatorAction;
var CryptoJS = require("crypto-js");

class Operator extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      dni: "",
      password: "",
      updatePass: "",
      open: false,
      name: "",
      userType: 0,
      showPassword: false,
      error: { dni: "", password: "", name: "", userType: "" }
    };
  }
  async componentDidMount() {
    if (Object.values(this.props.data).length > 0) {
      await this.setState({
        id: this.props.data.id,
        dni: this.props.data.dni,
        password: "*******",
        name: this.props.data.name,
        userType: this.props.data.operator_type_id,
        error: { dni: "", password: "", name: "", userType: "" }
      });
    } else {
      await this.setState({
        id: "",
        dni: "",
        password: "",
        updatePass: "",

        name: "",
        userType: 0,
        error: { dni: "", password: "", name: "", userType: "" }
      });
    }
  }
  componentWillReceiveProps = async nextprops => {
    if (Object.values(nextprops.data).length > 0) {
      await this.setState({
        id: nextprops.data.id,
        dni: nextprops.data.dni,
        password: "*******",
        name: nextprops.data.name,
        userType: nextprops.data.operator_type_id,
        error: { dni: "", password: "", name: "", userType: "" }
      });
    } else {
      await this.setState({
        id: "",
        dni: "",
        password: "",
        updatePass: "",
        name: "",
        userType: 0,
        error: { dni: "", password: "", name: "", userType: "" }
      });
    }
  };
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value
    });
  };
  handleSave = async () => {
    const { error } = this.state;
    let data;

    const KEY = CryptoJS.enc.Utf8.parse("enfasys.tech.000");
    const SECRET_KEY = CryptoJS.enc.Base64.parse("disal.00.resflow");
    let encrypted = CryptoJS.AES.encrypt(this.state.password, KEY, {
      iv: SECRET_KEY
    }).toString();
    if (this.state.id !== "") {
      data = {
        operatorId: this.state.id,
        dni: this.state.dni,
        name: this.state.name,
        operatorTypeId: this.state.userType
      };
    } else {
      data = {
        dni: this.state.dni,
        name: this.state.name,
        password: encrypted,
        operatorTypeId: this.state.userType
      };
    }

    if (this.state.dni === "") {
      error.dni = "*Completar el campo";
    } else {
      error.dni = "";
    }
    if (this.state.name === "") {
      error.name = "*Completar el campo";
    } else {
      error.name = "";
    }
    if (this.state.password === "") {
      error.password = "*Completar el campo";
    } else {
      error.password = "";
    }
    if (this.state.userType === 0) {
      error.userType = "*Selecciona una opción";
    } else {
      error.userType = "";
    }
    this.setState({
      error
    });
    const newData =
      this.state.updatePass !== ""
        ? Object.assign(data, { password: this.state.updatePass })
        : data;
    if (
      this.state.dni !== "" &&
      this.state.name !== "" &&
      this.state.password !== "" &&
      this.state.userType !== 0
    ) {
      const respuestaOperator = await EndPointCreateUpdateOperator(newData);
      if (respuestaOperator.data.success) {
        this.props.HandleOpenExitUser();
        this.props.GetOperators();
        this.props.CreateUpdateOperator();
        this.props.handleClean();
      } else {
        const result = "*" + respuestaOperator.data.message[0].errorMessages[0];
        error.meesageSave = result;
        this.setState({
          error
        });
      }
    }
  };
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleUpdatePass = val => {
    const KEY = CryptoJS.enc.Utf8.parse("enfasys.tech.000");
    const SECRET_KEY = CryptoJS.enc.Base64.parse("disal.00.resflow");
    let encrypted = CryptoJS.AES.encrypt(val, KEY, {
      iv: SECRET_KEY
    }).toString();
    this.setState({
      updatePass: encrypted
    });
  };
  handleCancel = () => {
    this.setState({
      id: "",
      dni: "",
      password: "",
      name: "",
      userType: 0,
      error: { dni: "", password: "", name: "", userType: "" }
    });

    this.props.handleCancel();
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };
  handleClose = () => {
    this.setState({
      open: false
    });
  };
  render() {
    return (
      <div className={this.props.classes.contentForm}>
        <Grid container>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>ID </label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    disabled
                    name="id"
                    variant="outlined"
                    value={this.state.id}
                    className={this.props.classes.textFielInput + " blocked-id"}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>DNI </label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="dni"
                    variant="outlined"
                    inputProps={{
                      maxLength: 8
                    }}
                    value={this.state.dni}
                    className={this.props.classes.textFielInput}
                    onChange={this.handleChange}
                  />
                  <p>
                    {this.state.error.dni === ""
                      ? this.state.error.meesageSave !== undefined
                        ? this.state.error.meesageSave
                        : null
                      : this.state.error.dni}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Nombre</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="name"
                    variant="outlined"
                    inputProps={{
                      maxLength: 100
                    }}
                    value={this.state.name}
                    className={this.props.classes.textFielInput}
                    onChange={this.handleChange}
                  />
                  <p>
                    {this.state.error.name === undefined
                      ? ""
                      : this.state.error.name}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Contraseña</label>
                </Grid>
                <Grid item xs={9}>
                  {this.state.id !== "" ? (
                    <FormControl
                      className={
                        this.props.classes.formControl + " back-blocked"
                      }
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled
                        name="password"
                        id="outlined-adornment-password"
                        type={"text"}
                        value={this.state.password}
                        onChange={this.handleChange}
                        inputProps={{
                          maxLength: 50
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleOpen}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              <EditIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl
                      className={this.props.classes.formControl}
                      variant="outlined"
                    >
                      <OutlinedInput
                        name="password"
                        id="outlined-adornment-password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleChange}
                        inputProps={{
                          maxLength: 50
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  )}

                  <p>
                    {this.state.error.password === undefined
                      ? ""
                      : this.state.error.password}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Tipo de usuario </label>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="userType"
                      value={this.state.userType}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.operatorReducer.operator_type.map(res => {
                        return (
                          <MenuItem value={res.id}>{res.description}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p>
                    {this.state.error.userType === undefined
                      ? ""
                      : this.state.error.userType}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <div className={this.props.classes.contentButton}>
          <button onClick={this.handleCancel}>Cancelar</button>
          <button onClick={this.handleSave}>Guardar</button>
        </div>
        <Modal />
        <ModalPassword
          open={this.state.open}
          handleClose={this.handleClose}
          handleUpdatePass={this.handleUpdatePass}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ errorReducer, operatorReducer }) => {
  return { errorReducer, operatorReducer };
};
const mapDispatchToProps = {
  CreateUpdateOperator,
  HandleOpenUser,
  HandleOpenExitUser,
  ErrorUser,
  GetOperators
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Operator));
