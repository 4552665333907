import React, { Component } from "react";
import axios from "axios";

import { Modal } from "antd";
import { URLPutAccept } from "../../other/apis";
class ModalConfirm extends Component {
  handleClickAccept = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    await axios.put(URLPutAccept, this.props.data, {
      headers
    });
    this.props.funct();
    this.props.onOkA();
    this.props.handleClean();
  };
  render() {
    return (
      <Modal
        title=" "
        visible={this.props.visibleConfirm}
        onOk={() => this.props.onOkA()}
        onCancel={() => this.props.onOkA()}
        cancelText=""
        footer={null}
        okText="Confirmar"
      >
        <div className="modal-confirm">
          <h3>¿Confirma la aprobación?</h3>
          <p>Este traslado se asignará al conductor y vehiculo</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn-modal-salida"
            style={{ textDecoration: "underline", marginRight: "10px" }}
            onClick={() => this.props.onOkA()}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn-modal-salida"
            style={{ textDecoration: "underline" }}
            onClick={this.handleClickAccept}
          >
            Aprobar
          </button>
        </div>
      </Modal>
    );
  }
}

export default ModalConfirm;
