import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Pagination";
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecords: "",
      pageLimit: "",
      totalPages: "",
      currentPage: "",
      initialPage: "",
      pagesToShow: "",
      endIndex: ""
    };
  }

  async componentDidMount() {
    await this.setState({
      totalRecords: this.props.totalRecords,
      pageLimit: this.props.pageLimit || 10,
      totalPages: Math.ceil(this.props.totalRecords / this.props.pageLimit),
      pagesToShow: this.props.pagesToShow || 3,
      currentPage: this.props.initialPage || 1
    });
    await this.setPage(this.props.initialPage);
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({
      totalRecords: nextProps.totalRecords,
      pageLimit: nextProps.pageLimit || 10,
      totalPages: Math.ceil(nextProps.totalRecords / nextProps.pageLimit),
      pagesToShow: nextProps.pagesToShow || 3
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.totalRecords !== prevState.totalRecords ||
      this.state.pageLimit !== prevState.pageLimit
    ) {
      await this.setPage(1);
    }
  }
  componentWillUnmount = async () => {
    this.setPage(1);
  };

  async setPage(page) {
    var { totalRecords, pageLimit, totalPages } = this.state;

    if (page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }
    await this.setState({
      currentPage: page
    });

    var startIndex = (page - 1) * pageLimit;
    var endIndex = Math.min(startIndex + pageLimit - 1, totalRecords - 1);

    await this.props.onChangePage({
      pageLimit,
      totalPages,
      page,
      startIndex,
      endIndex
    });
  }

  getPager() {
    var { pagesToShow, currentPage, totalPages } = this.state;
    var pages = [],
      startFromNumber;

    if (totalPages <= pagesToShow) {
      startFromNumber = 1;
      pagesToShow = totalPages;
    } else {
      if (currentPage <= Math.ceil(pagesToShow / 2)) {
        startFromNumber = 1;
      } else if (
        currentPage + Math.floor((pagesToShow - 1) / 2) >=
        totalPages
      ) {
        startFromNumber = totalPages - (pagesToShow - 1);
      } else {
        startFromNumber = currentPage - Math.floor(pagesToShow / 2);
      }
    }

    for (let i = 1; i <= pagesToShow; i++) {
      pages.push(startFromNumber++);
    }

    return {
      currentPage,
      totalPages,
      pages
    };
  }

  render() {
    if (!this.state.totalRecords || this.state.totalPages === 1) return null;
    var pager = this.getPager();
    return (
      <ul className={this.props.classes.pagination}>
        <li>
          <a
            href="/"
            className={pager.currentPage === 1 ? "disabled" : ""}
            onClick={(e) => 
              {
                e.preventDefault();
                this.setPage(pager.currentPage - 1)
              }
            }
          >
            Anterior
          </a>
        </li>
        {pager.pages.map((page, index) => (
          <li key={index}>
            <a
              href="/"
              onClick={(e) => 
                {
                  e.preventDefault();
                  this.setPage(page)
                }
              }
              className={pager.currentPage === page ? "isActive" : ""}
            >
              {page}
            </a>
          </li>
        ))}

        <li>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              this.setPage(pager.currentPage + 1)
            }}
            className={pager.currentPage === pager.totalPages ? "disabled" : ""}
          >
            Siguiente
          </a>
        </li>
      </ul>
    );
  }
}

export default withStyles(style)(Pagination);
