import {
  OPEN_GERENCIA_MODAL,
  OPEN_POINT_MODAL,
  OPEN_USER_MODAL,
  OPEN_VEHICLE_MODAL,
  CLOSE_GERENCIA_MODAL,
  CLOSE_POINT_MODAL,
  CLOSE_USER_MODAL,
  CLOSE_VEHICLE_MODAL,
  OPEN_EXIT_GERENCIA_MODAL,
  OPEN_EXIT_USER_MODAL,
  OPEN_EXIT_VEHICLE_MODAL,
  OPEN_EXIT_POINT_MODAL,
  CLOSE_EXIT_GERENCIA_MODAL,
  CLOSE_EXIT_USER_MODAL,
  CLOSE_EXIT_VEHICLE_MODAL,
  CLOSE_EXIT_POINT_MODAL,
  OPEN_EXIT_ROUTES_MODAL,
  OPEN_ROUTE_MODAL,
  CLOSE_EXIT_ROUTES_MODAL,
  CLOSE_ROUTE_MODAL
} from "../Types/Modal";

export const HandleOpenGerencia = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_GERENCIA_MODAL
  });
};

export const HandleCloseGerencia = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_GERENCIA_MODAL
  });
};

export const HandleOpenUser = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_USER_MODAL
  });
};

export const HandleCloseUser = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_USER_MODAL
  });
};

export const HandleOpenVehicle = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_VEHICLE_MODAL
  });
};

export const HandleCloseVehicle = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_VEHICLE_MODAL
  });
};

export const HandleOpenPoint = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_POINT_MODAL
  });
};

export const HandleClosePoint = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_POINT_MODAL
  });
};

export const HandleOpenRoute = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_ROUTE_MODAL
  });
};

export const HandleCloseRoute = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_ROUTE_MODAL
  });
};

export const HandleOpenExitGerencia = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_EXIT_GERENCIA_MODAL
  });
};

export const HandleCloseExitGerencia = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_EXIT_GERENCIA_MODAL
  });
};

export const HandleOpenExitUser = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_EXIT_USER_MODAL
  });
};

export const HandleCloseExitUser = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_EXIT_USER_MODAL
  });
};

export const HandleOpenExitVehicle = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_EXIT_VEHICLE_MODAL
  });
};

export const HandleCloseExitVehicle = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_EXIT_VEHICLE_MODAL
  });
};

export const HandleOpenExitPoint = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_EXIT_POINT_MODAL
  });
};

export const HandleCloseExitPoint = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_EXIT_POINT_MODAL
  });
};

export const HandleOpenExitRoute = () => async (dispatch, getState) => {
  dispatch({
    type: OPEN_EXIT_ROUTES_MODAL
  });
};

export const HandleCloseExitRoute = () => async (dispatch, getState) => {
  dispatch({
    type: CLOSE_EXIT_ROUTES_MODAL
  });
};
