import React, { Component } from "react";
import { connect } from "react-redux";

import * as vehicleAction from "../Actions/VehicleAction";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import style from "../assets/jss/disal-main/Layouts/Gerencia";
import FormVehicle from "../Component/Form/Vehicle";
import Modal from "../Component/Modal/ModalDeleteVehicle";
import ModalMessage from "../Component/Modal/ModalMessageDeleteVechicle";

import Filter from "../Component/Filters/Vehiculos";

const {
  GetVehiclesAdmin,
  GetVehicleType,
  GetVehiclesEspecific,
} = vehicleAction;

class Vehicles extends Component {
  constructor() {
    super();
    this.state = {
      idSelect: null,
      open: false,
      statusForm: false,
      data: {},
      id: "",
      name: "",
      type: 0,
    };
  }
  componentDidMount = async () => {
    if (!this.props.vehicleReducer.vehicle.length) {
      await this.props.GetVehiclesAdmin();
    }
    await this.props.GetVehicleType();
    await this.props.GetVehiclesEspecific(null, null, 0);
  };

  handleOpen = async () => {
    await this.setState({
      open: true,
    });
  };
  handleClose = async () => {
    await this.setState({
      open: false,
    });
  };
  handleStatus = async () => {
    await this.setState({
      statusForm: true,
      data: {},
      idSelect: null,
      id: "",
      name: "",
      type: 0,
    });
  };
  handleClean = async () => {
    await this.setState({
      data: {},
      idSelect: null,
      id: "",
      name: "",
      type: 0,
    });
  };
  handleCancel = async () => {
    await this.setState({
      statusForm: false,
      data: {},
      idSelect: null,
      id: "",
      name: "",
      type: 0,
    });
  };
  handleClickVehicle = async (e) => {
    const id = e.currentTarget.id;
    const filter = this.props.vehicleReducer.vehicle.filter(
      (res) => Number(id) === res.vehicleId
    );
    await this.setState({
      statusForm: true,
      idSelect: Number(id),
      data: filter[0],
    });
  };
  HandleSearchId = async (e) => {
    const value = e.target.value;
    const name = this.state.name;
    const type = this.state.type;

    var regex = /^([1-9])*$/;
    if (regex.test(value)) {
      await this.setState({
        id: value,
      });
      await this.props.GetVehiclesEspecific(value, name, type);
    }
    if (value === "") {
      await this.setState({
        id: value,
      });
      await this.props.GetVehiclesEspecific(null, name, type);
    }
  };

  HandleSearchName = async (e) => {
    const value = e.target.value;
    const id = this.state.id;
    const type = this.state.type;
    var regex = /^([a-zA-Z0-9-])*$/;
    if (regex.test(value)) {
      await this.setState({
        name: value,
      });
      await this.props.GetVehiclesEspecific(id, value, type);
    }
    if (value === "") {
      await this.setState({
        name: value,
      });
      await this.props.GetVehiclesEspecific(id, null, type);
    }
  };
  HandleSearchType = async (e) => {
    const value = e.target.value;
    const id = this.state.id;
    const name = this.state.name;
    if (value !== 0) {
      await this.setState({
        type: value,
      });
      await this.props.GetVehiclesEspecific(id, name, value);
    } else {
      await this.setState({
        type: 0,
      });
      await this.props.GetVehiclesEspecific(id, name, value);
    }
  };
  render() {
    return (
      <div className={this.props.classes.content}>
        <Grid container className={this.props.classes.gerencia}>
          <Grid item xs={12}>
            <Filter
              id={this.state.id}
              name={this.state.name}
              type={this.state.type}
              HandleSearchId={this.HandleSearchId}
              HandleSearchName={this.HandleSearchName}
              HandleSearchType={this.HandleSearchType}
            />
          </Grid>
          <Grid item xs={4} className={this.props.classes.listAdd}>
            <h3 className={this.props.classes.title}>Lista de Vehículos</h3>
            <List className={this.props.classes.listGerencia}>
              {this.props.vehicleReducer.vehicle.map((res, index) => (
                <ListItem
                  button
                  id={res.vehicleId}
                  className={
                    this.props.classes.Listbutton +
                    (this.state.idSelect === res.vehicleId
                      ? " active-item"
                      : null)
                  }
                  onClick={this.handleClickVehicle}
                  key={index}
                >
                  <ListItemText primary={res.plateNumber} />
                  {this.state.idSelect === res.vehicleId ? (
                    <button
                      id={res.vehicleId}
                      className={this.props.classes.iconClose}
                      onClick={this.handleOpen}
                    >
                      <CloseIcon />
                    </button>
                  ) : null}
                </ListItem>
              ))}
            </List>
            <button
              className={this.props.classes.iconMore}
              onClick={this.handleStatus}
            >
              <AddIcon />
            </button>
          </Grid>
          <Grid item xs={8} className={this.props.classes.formGerencia}>
            <h3 className={this.props.classes.title}>Datos del Vehículo</h3>
            {this.state.statusForm ? (
              <FormVehicle
                data={this.state.data}
                handleCancel={this.handleCancel}
                handleClean={this.handleClean}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No hay vehículo seleccionado{" "}
              </div>
            )}
          </Grid>
        </Grid>
        <Modal
          handleClose={this.handleClose}
          open={this.state.open}
          id={this.state.idSelect}
          handleClean={this.handleClean}
        />
        <ModalMessage />
      </div>
    );
  }
}
const mapStateToProps = ({ vehicleReducer }) => {
  return { vehicleReducer };
};
const mapDispatchToProps = {
  GetVehiclesAdmin,
  GetVehicleType,
  GetVehiclesEspecific,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Vehicles));
