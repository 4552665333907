import {
  LOADING_DATA_POINTS,
  OBTENER_DATA_POINTS,
  MESSAGE_DELETE_POINTS,
  CREATE_UPDATE_POINTS,
  LOADING_DATA_POINTS_CONTAINER,
  OBTENER_DATA_POINTS_CONTAINER,
  LOADING_DATA_TYPE_CONTAINER,
  OBTENER_DATA_TYPE_CONTAINER,
  LOADING_DATA_TYPE_RESIDUE,
  OBTENER_DATA_TYPE_RESIDUE,
  UPDATE_DATA_POINTS
} from "../Types/PointAcopio";
import {
  EndPointGetPoints,
  EndPointGetContainers,
  EndPointGetTypeContainer,
  EndPointGetTypeResidue,
  EndPointGetPointsEspecific
} from "../Services/EndPointAcopio";

export const GetPointAcopio = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_POINTS
  });

  const respuestaPoint = await EndPointGetPoints();
  dispatch({
    type: OBTENER_DATA_POINTS,
    payload: respuestaPoint.data.data
  });
};
export const GetPointAcopioEspecific = (id, number, name, value) => async (
  dispatch,
  getState
) => {
  const type = value === 0 ? null : value;
  const ids = id === "" ? null : id;
  const numero = number === "" ? null : encodeURIComponent(number);
  const nombre = name === "" ? null : encodeURIComponent(name);
  const respuestaPoint = await EndPointGetPointsEspecific(
    ids,
    numero,
    nombre,
    type
  );
  dispatch({
    type: UPDATE_DATA_POINTS,
    payload: respuestaPoint.data.data
  });
};
export const DeletePoint = message => async (dispatch, getState) => {
  dispatch({
    type: MESSAGE_DELETE_POINTS,
    payload: message
  });
};

export const GetPointAcopioContainer = id => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_POINTS_CONTAINER
  });

  const respuestaPoint = await EndPointGetContainers(id);
  dispatch({
    type: OBTENER_DATA_POINTS_CONTAINER,
    payload: respuestaPoint.data.data
  });
};
export const GetPointAcopioTypeContainer = id => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_TYPE_CONTAINER
  });

  const respuestaPoint = await EndPointGetTypeContainer();
  dispatch({
    type: OBTENER_DATA_TYPE_CONTAINER,
    payload: respuestaPoint.data.data
  });
};

export const GetPointAcopioTypeResidue = id => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_TYPE_RESIDUE
  });

  const respuestaPoint = await EndPointGetTypeResidue();
  dispatch({
    type: OBTENER_DATA_TYPE_RESIDUE,
    payload: respuestaPoint.data.data
  });
};

export const CreateUpdatePoint = () => async (dispatch, getState) => {
  dispatch({
    type: CREATE_UPDATE_POINTS
  });
};
