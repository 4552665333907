import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/Layouts/Route";
import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";
class Column extends Component {
  render() {
    return (
      <Droppable droppableId={"column-1"}>
        {provided => {
          return (
            <Grid
              container
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {this.props.addData.map((res, index) => {
                return (
                  <Task
                    key={res.collectPointId}
                    val={res}
                    index={index}
                    handleChangeCheckNew={this.props.handleChangeCheckNew}
                    handleDelete={this.props.handleDelete}
                  />
                );
              })}
              {provided.placeholder}
            </Grid>
          );
        }}
      </Droppable>
    );
  }
}
export default withStyles(style)(Column);
