import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import TableContainer from "@material-ui/core/TableContainer";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fade from "@material-ui/core/Fade";
class Modals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id
    };
  }
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.openModal1}
        onClose={this.props.handleClose}
        closeAfterTransition
        disableScrollLock
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.openModal1}>
          <div className={this.props.classes.paper}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className={this.props.classes.modalHeader}>
                  <h5
                    className={this.props.classes.modalTitle}
                    id="exampleModalLabel"
                  >
                    Mostrando resultados para el Punto Generador{" "}
                    <span>
                      {this.props.namePoint === 0 ? "-" : this.props.namePoint}
                    </span>
                  </h5>
                  <button
                    className="btn-close"
                    onClick={this.props.handleClose}
                  >
                    <span className="closeSpan big"></span>
                  </button>
                </div>
                <div className="modal-body table-responsive">
                  <TableContainer className={this.props.classes.contentModal}>
                    <Table className={this.props.classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell> #</TableCell>
                          <TableCell>TIPO DE CONTENEDOR</TableCell>
                          <TableCell>MATERIAL</TableCell>
                          <TableCell>VOLUMEN</TableCell>
                          <TableCell>PESO</TableCell>
                          <TableCell>SEGREGACIÓN</TableCell>
                          <TableCell>FOTO</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.datamodal.map((dat, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>{dat.containerModel}</TableCell>
                            <TableCell>{dat.residualSubtype}</TableCell>
                            <TableCell>{dat.volume}</TableCell>
                            <TableCell>{dat.weight}</TableCell>
                            <TableCell>
                              <span className="statusModal">
                                {dat.hasIncident}
                              </span>
                            </TableCell>
                            <TableCell>
                              {dat.image !== undefined ? (
                                dat.image === null ? (
                                  ""
                                ) : (
                                  <a
                                    href={dat.image !== null ? dat.image : ""}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Ver
                                  </a>
                                )
                              ) : (
                                ""
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
export default withStyles(style)(Modals);
