import React, { Component } from "react";
import Modal from "../Modal/Modal";
import ModalIncidencias from "../Modal/ModalIncident";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/TableOperativo";
import ModalQR from "../Modal/ModalQR";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { URLDashIncident } from "../../other/apis";
import Hidden from "@material-ui/core/Hidden";

import {
  EndpointDashWorkCollect,
} from "../../Services/EndPointOperation";
class table extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      number: "",
      dataModal: [],
      dataIncident: [],
      openModal1: false,
      openModal2: false,
      openModal3: false,
    };
  }
  handleOpenModal = async (e, idV, idP) => {
    const idPoint = e.target.dataset.id;
    const namePoint = e.target.dataset.namepoint;
    await this.setState({
      openModal1: true,
      id: idPoint,
      name: namePoint,
    });
    const valueProgram = this.props.status === "programated" ? 0 : 1;

    if (idV === 0 && idP === 0) {
      await this.setState({ dataModal: [] });
    } else {
      const result = await EndpointDashWorkCollect(idV, idP, valueProgram);
      const reestructure = result.data.containers.map((res) => {
        let tempData = {
          containerId: res.containerId,
          containerModel: res.containerModel,
          residualSubtype: res.residualSubtype,
          weight: res.weight,
          volume: res.volume,
          hasIncident: res.hasIncident ? "Mal Segregado" : "Correcta",
          image: res.photo,
        };
        return tempData;
      });

      await this.setState({ dataModal: reestructure });
    }
  };
  handleClickModalIncident = async (e, idP) => {
    const idPoint = e.target.dataset.id;
    const namePoint = e.target.dataset.namepoint;
    const numberPoint = e.target.dataset.numberpoint;

    await this.setState({
      openModal3: true,
      id: idPoint,
      name: namePoint,
      number: numberPoint,
    });
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    if (idP === 0) {
      await this.setState({ dataIncident: [] });
    } else {
      await axios.get(URLDashIncident(idPoint), { headers }).then((res) => {
        this.setState({
          dataIncident: res.data.data.length === 0 ? [] : res.data.data[0],
        });
      });
    }
  };
  handleClose = () => {
    this.setState({
      openModal1: false,
    });
  };
  handleOpenModal2 = async (e) => {
    const idPoint = e.target.dataset.id;
    const namePoint = e.target.dataset.namepoint;
    await this.setState({
      openModal2: true,
      id: idPoint,
      name: namePoint,
    });
  };
  handleCloseModal2 = () => {
    this.setState({
      openModal2: false,
    });
  };
  handleCloseModal3 = () => {
    this.setState({
      openModal3: false,
    });
  };
  render() {
    return (
      <Hidden smDown>
        <TableContainer className={this.props.classes.contentTable}>
          <Table
            className={this.props.classes.tableGeneral}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={this.props.classes.cellOperativo}>
                <TableCell>#</TableCell>
                <TableCell style={{ width: "200px" }}>GERENCIA</TableCell>
                <TableCell className="text-overflow" style={{ width: "200px" }}>
                  PUNTO GENERADOR
                </TableCell>
                {this.props.status === "programated" ? (
                  <TableCell>VEHICULO</TableCell>
                ) : null}
                <TableCell
                  className={!this.props.open ? "showCell" : "hideCell"}
                >
                  VOLUMEN
                </TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  PESO
                </TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  COMPLETADOS
                </TableCell>

                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  SEGREGACIÓN
                </TableCell>
                {this.props.status === "programated" ? null : (
                  <TableCell>ESTATUS</TableCell>
                )}

                {this.props.status === "programated" ? null : (
                  <TableCell
                    className={!this.props.open ? " showCell" : " hideCell"}
                  >
                    INC.
                  </TableCell>
                )}
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  VER
                </TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  CODIGO QR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.pageOfItems.map((data, index) => {
                let idP = data.collectPointId;
                let idV =
                  this.props.status === "programated"
                    ? data.vehicleId
                    : data.idVehicles;
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {data.collectPointNumber}
                    </TableCell>
                    <TableCell>{data.departmentName}</TableCell>
                    <TableCell>{data.collectPointName} </TableCell>
                    {this.props.status === "programated" ? (
                      <TableCell>{data.plateNumber} </TableCell>
                    ) : null}
                    <TableCell
                      className={!this.props.open ? "showCell" : "hideCell"}
                    >
                      {data.totalVolume}
                    </TableCell>
                    <TableCell
                      className={!this.props.open ? "showCell" : "hideCell"}
                    >
                      {data.totalWeight}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={!this.props.open ? "showCell" : "hideCell"}
                    >
                      {data.complete}
                    </TableCell>
                    <TableCell
                      className={!this.props.open ? "showCell" : "hideCell"}
                    >
                      {data.segregatio}
                    </TableCell>
                    {this.props.status === "programated" ? null : (
                      <TableCell>
                        <span
                          className={
                            "status" +
                            (data.hasIncidents === 0
                              ? data.estatus === "Pendiente"
                                ? " background-yellow-secondary"
                                : data.estatus === "Finalizado"
                                ? " background-greenDark"
                                : data.estatus === "En proceso"
                                ? " background-sky-blue"
                                : " "
                              : " background-incident")
                          }
                          style={{
                            color: data.estatus === "-" ? "#000" : "#fff",
                          }}
                        >
                          {data.hasIncidents === 0 ? data.estatus : "Incidente"}
                        </span>
                      </TableCell>
                    )}

                    {this.props.status === "programated" ? null : (
                      <TableCell
                        className={
                          "cell-button " +
                          (!this.props.open ? "showCell" : "hideCell")
                        }
                      >
                        {data.collectPointNumber === "-" ? null : (
                          <div>
                            <button
                              type="button"
                              data-id={data.collectPointId}
                              data-namepoint={data.collectPointName}
                              data-numberpoint={data.collectPointNumber}
                              className={
                                "btn-table " +
                                (data.hasIncidents === 0 ? "disab" : " ")
                              }
                              disabled={data.hasIncidents === 0 ? true : false}
                              onClick={async (e) =>
                                await this.handleClickModalIncident(e, idP)
                              }
                            >
                              Ver
                            </button>
                          </div>
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      className={
                        "cell-button " +
                        (!this.props.open ? "showCell" : "hideCell")
                      }
                    >
                      {data.collectPointNumber === "-" ? null : (
                        <div>
                          <button
                            type="button"
                            data-id={data.collectPointId}
                            data-namepoint={data.collectPointName}
                            className="btn-table"
                            onClick={async (e) =>
                              await this.handleOpenModal(e, idV, idP)
                            }
                          >
                            Ver
                          </button>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      className={
                        "cell-button " +
                        (!this.props.open ? "showCell" : "hideCell")
                      }
                    >
                      {data.collectPointNumber === "-" ? null : (
                        <div>
                          <button
                            type="button"
                            data-id={data.collectPointNumber}
                            data-namepoint={data.collectPointName}
                            className="btn-table"
                            onClick={async (e) =>
                              await this.handleOpenModal2(e)
                            }
                          >
                            Ver
                          </button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Modal
            openModal1={this.state.openModal1}
            handleClose={this.handleClose}
            id={this.state.id}
            namePoint={this.state.name}
            datamodal={this.state.dataModal}
          />
          <ModalQR
            openModal2={this.state.openModal2}
            handleClose={this.handleCloseModal2}
            id={this.state.id}
            namePoint={this.state.name}
          />
          <ModalIncidencias
            openModal3={this.state.openModal3}
            handleClose={this.handleCloseModal3}
            id={this.state.id}
            pointNumber={this.state.number}
            namePoint={this.state.name}
            datamodal={this.state.dataIncident}
          />
        </TableContainer>
      </Hidden>
    );
  }
}
export default withStyles(style)(table);
