import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import style from "../../assets/jss/disal-main/components/Filters";

class Vehiculo extends Component {
  render() {
    return (
      <Grid container className={this.props.classes.filter}>
        <Grid item xs={4} className={this.props.classes.contentInput}>
          <Paper className={this.props.classes.root}>
            <InputBase
              className={this.props.classes.input}
              value={this.props.id}
              placeholder="Buscar por id"
              onChange={this.props.HandleSearchId}
            />
            <IconButton
              type="button"
              className={this.props.classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={4} className={this.props.classes.contentInput}>
          <Paper className={this.props.classes.root}>
            <InputBase
              className={this.props.classes.input}
              value={this.props.name}
              placeholder="Buscar por matrícula"
              onChange={this.props.HandleSearchName}
            />
            <IconButton
              type="button"
              className={this.props.classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <FormControl
            variant="outlined"
            className={this.props.classes.formControl}
          >
            <Select
              name="vehicleType"
              value={this.props.type}
              onChange={this.props.HandleSearchType}
            >
              <MenuItem value={0}>Selecciona un tipo</MenuItem>
              {this.props.vehicleReducer.vehicle_type.map((res) => {
                return (
                  <MenuItem value={res.vehicleTypeId}>
                    {res.vehicleTypeDescription}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ vehicleReducer }) => {
  return { vehicleReducer };
};

export default connect(mapStateToProps, null)(withStyles(style)(Vehiculo));
