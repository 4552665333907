import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardSlide from "../Card/cardSlide";
import * as operationAction from "../../Actions/OperationActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@material-ui/core/Grid";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import style from "../../assets/jss/disal-main/components/Slide";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
const {
  GetVehiclesOperation,
  UpdateVehicleTable,
  GetVehicleById,
  UpdateStatusTable,
  GetVehicleNoProgramated
} = operationAction;
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      className={className}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon className="arrow-style" />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      className={className}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <ArrowBackIosIcon className="arrow-style" />
    </button>
  );
}
class Slide extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  handleCard = async (ids, types, names, index, val) => {
    const valores = {
      type: types,
      name: names,
      id: ids,
      activeIndex: index
    };
    await this.props.GetVehicleById(valores);
  };
  handleProgramated = async () => {
    await this.props.GetVehicleNoProgramated();
  };
  handleStatus = async () => {
    await this.props.UpdateVehicleTable();
  };
  handleOpen = async () => {
    await this.setState({
      open: !this.state.open
    });
  };
  render() {
    const settings = {
      dots: true,
      infinite: this.props.operationReducer.arrayVehicle.length > 5,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      customPaging: i => (
        <button
          style={{
            width: "9px",
            height: "9px",
            marginRight: "16px",
            borderRadius: "100%",
            textIndent: "-9999px"
          }}
        >
          {i + 1}
        </button>
      ),
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
            dots: false
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false
          }
        }
      ]
    };

    return (
      <Grid
        container
        className={this.props.classes.contentSlide}
        style={{ display: "block" }}
      >
        <Grid item md={12} className={this.props.classes.titleOptions}>
          <h5>Escoge el equipo deseado: </h5>
          <button onClick={this.handleOpen}>
            {!this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </button>
        </Grid>
        <Grid item sm={12} md={12}>
          <div
            className={
              this.props.classes.transitionSlide +
              " " +
              (!this.state.open ? this.props.classes.openSlide : " ")
            }
          >
            <Slider ref={c => (this.slider = c)} {...settings}>
              {this.props.operationReducer.arrayVehicle.map((item, index) => {
                return (
                  <CardSlide
                    key={index}
                    index={index}
                    activeIndex={this.props.operationReducer.activeIndex}
                    statusTable={this.props.operationReducer.statusTable}
                    item={item}
                    handleCard={this.handleCard}
                  />
                );
              })}
            </Slider>
            <button
              className={this.props.classes.viewGeneral}
              onClick={this.handleStatus}
            >
              Vista General
            </button>
            <button
              className={this.props.classes.programated}
              onClick={this.handleProgramated}
            >
              No programados
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = ({ operationReducer }) => {
  return { operationReducer };
};
const mapDispatchToProps = {
  GetVehiclesOperation,
  UpdateVehicleTable,
  GetVehicleById,
  UpdateStatusTable,
  GetVehicleNoProgramated
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Slide));
