import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import style from "../../assets/jss/disal-main/components/Sidebar";
import { Link } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: localStorage.getItem("type") === "4" ? "Salidas" : "Operation",
      open: false
    };
  }
  async componentDidMount() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1]
    });
  }
  async componentWillReceiveProps() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1]
    });
  }

  handleMenu = async e => {
    await this.setState({
      name: e.currentTarget.name
    });
  };

  handleClick = async () => {
    await this.setState({
      open: !this.state.open
    });
  };
  render() {
    return (
      <Hidden lgUp>
        <Drawer
          open={this.props.open}
          onClose={this.props.handleChange}
          className={this.props.classes.sidebar}
        >
          <div>
            <button onClick={this.props.handleChange} className={"btnCloseMap"}>
              <span className="closeSpan big"></span>
            </button>
          </div>
          <List>
            <ListItem className={this.props.classes.item}>
              Cerrar Mendoza [Cerro Verde]
            </ListItem>
            <ListItem
              button
              onClick={this.handleClick}
              className={this.props.classes.textModule}
            >
              <ListItem className={this.props.classes.item}>Modulos</ListItem>

              {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={this.state.open}
              timeout="auto"
              unmountOnExit
              className={this.props.classes.contentModule}
            >
              <List component="div" disablePadding>
                <Link
                  to="/Main-Sesion/Operation"
                  className={
                    this.props.classes.item +
                    (this.state.name === "Operation" ? " selectActive" : "")
                  }
                  name="Operation"
                  onClick={this.handleMenu}
                >
                  <ListItem>Operación</ListItem>
                </Link>
                <Link
                  to="/Main-Sesion/Dashboard"
                  className={
                    this.props.classes.item +
                    (this.state.name === "Dashboard" ? " selectActive" : "")
                  }
                  name="Dashboard"
                  onClick={this.handleMenu}
                >
                  <ListItem>Dashboard</ListItem>
                </Link>
              </List>
            </Collapse>

            <ListItem className={this.props.classes.item}>
              Cerrar Sesión
            </ListItem>
          </List>
        </Drawer>
      </Hidden>
    );
  }
}

export default withStyles(style)(Sidebar);
