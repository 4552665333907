import api from "./ApiURLBase";
const EndPointGetDashToday = async () => await api.get(`/dashboard/today`);
const EndPointGetCollectPointDaily = async () =>
  await api.get(`/dashboard/collect-points/daily`);
const EndPointGetCollectPointWeekly = async () =>
  await api.get(`/dashboard/collect-points/weekly`);
const EndPointGetDepartmentAscDsc = async val =>
  await api.get(`/dashboard/departments?sort=${val}`);
const EndPointGetGenericTypes = async () => await api.get(`/generic-types`);
const EndPointGetGenericTypesDash = async () =>
  await api.get(`/dashboard/generic-types`);

const EndPointGetGenericTypesId = async id =>
  await api.get(`/dashboard/generic-types/${id}`);

export {
  EndPointGetDashToday,
  EndPointGetCollectPointDaily,
  EndPointGetDepartmentAscDsc,
  EndPointGetCollectPointWeekly,
  EndPointGetGenericTypesId,
  EndPointGetGenericTypesDash,
  EndPointGetGenericTypes
};
