import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Draggable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/Layouts/Route";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";

class Task extends Component {
  render() {
    return (
      <Draggable
        draggableId={this.props.val.collectPointId.toString()}
        index={this.props.index}
      >
        {provided => {
          return (
            <Grid
              item
              xs={12}
              className={this.props.classes.detailItem}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <Grid container>
                <Grid item xs={1}>
                  <p>{this.props.val.pointNumber}</p>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingRight: "10px"
                  }}
                >
                  <p>{this.props.val.collectPointName}</p>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingLeft: "10px"
                  }}
                >
                  <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Lu</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={0}
                            checked={this.props.val.days[0].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.props.handleChangeCheckNew}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Ma</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={1}
                            checked={this.props.val.days[1].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.props.handleChangeCheckNew}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Mi</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={2}
                            checked={this.props.val.days[2].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.props.handleChangeCheckNew}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Ju</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={3}
                            checked={this.props.val.days[3].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.props.handleChangeCheckNew}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Vi</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={4}
                            checked={this.props.val.days[4].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.props.handleChangeCheckNew}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Sa</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={5}
                            checked={this.props.val.days[5].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.props.handleChangeCheckNew}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={this.props.classes.contentCheckBox}>
                      <Grid>
                        <Grid>Do</Grid>
                        <Grid>
                          <Checkbox
                            name={this.props.index}
                            id={6}
                            checked={this.props.val.days[6].checked}
                            onChange={this.props.handleChangeCheckNew}
                            className={this.props.classes.checkBox}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <button
                    id={this.props.index}
                    onClick={this.props.handleDelete}
                    className={this.props.classes.iconDelete}
                  >
                    <CloseIcon />
                  </button>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Draggable>
    );
  }
}
export default withStyles(style)(Task);
