import {
  CARGANDO_SALIDAS_VEHICLES,
  OBTENER_SALIDAS_VEHICLES,
  CARGANDO_SALIDAS_STATUS,
  OBTENER_SALIDAS_STATUS
} from "../Types/Salidas";
import {
  EndPointSalidasVehiculosId,
  EndPointSalidasStatus
} from "../Services/EndPointSalidas";
export const GetSalidasVehicles = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_SALIDAS_VEHICLES
  });
  const idMiningSite = localStorage.getItem("miningSiteId");

  const respuestaClientes = await EndPointSalidasVehiculosId(idMiningSite);
  dispatch({
    type: OBTENER_SALIDAS_VEHICLES,
    payload: respuestaClientes.data.result
  });
};

export const GetSalidasStatus = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_SALIDAS_STATUS
  });
  const respuestaStatus = await EndPointSalidasStatus();
  dispatch({
    type: OBTENER_SALIDAS_STATUS,
    payload: respuestaStatus.data.result
  });
};
