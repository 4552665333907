import {
  LOADING_DATA_CIRCULE,
  OBTENER_DATA_CIRCULE,
  OBTENER_DATA_BARRAS,
  LOADING_DATA_BARRAS,
  LOADING_DATA_RANK,
  OBTENER_DATA_RANK,
  OBTENER_DATA_GENERIC_TYPES,
  LOADING_DATA_GENERIC_TYPES,
  LOADING_DATA_PIE,
  OBTENER_DATA_PIE
} from "../Types/Dashboard";

import {
  EndPointGetDashToday,
  EndPointGetCollectPointDaily,
  EndPointGetCollectPointWeekly,
  EndPointGetDepartmentAscDsc,
  EndPointGetGenericTypesId,
  EndPointGetGenericTypesDash,
  EndPointGetGenericTypes
} from "../Services/EndPointDash";

export const GetDataCircule = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_CIRCULE
  });
  const resultData = await EndPointGetDashToday();
  const newfiltertData = Object.keys(resultData.data).map(res => {
    let data = { data: {} };
    data.id = "01GraphicCircule";
    data.title =
      res === "recollection"
        ? "Recolección de hoy"
        : "Segregación correcta de hoy";
    data.codigo =
      res === "recollection" ? "cod-GraphRecolect" : "cod-GraphSegre";
    data.data.textData =
      res === "recollection" ? resultData.data[res] : resultData.data[res];
    data.data.percentage = res === "recollection" ? 100 : resultData.data[res];
    return data;
  });

  dispatch({
    type: OBTENER_DATA_CIRCULE,
    payload: newfiltertData
  });
};
export const GetDataPie = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_PIE
  });
  const resultData = await EndPointGetGenericTypesDash();
  
  let data = { data: {} };
  data.id = "01GraphicPie";
  data.title = "Recolección de hoy ";
  data.codigo = "cod-GraphPie";
  data.data = resultData.data.data;

  dispatch({
    type: OBTENER_DATA_PIE,
    payload: [data]
  });
};
export const GetDataBarras = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_BARRAS
  });

  const promises = [
    EndPointGetCollectPointDaily(),
    EndPointGetCollectPointWeekly()
  ];
  const resultDataBarras = await Promise.all(promises);
  const [resDaily, resWeekly] = resultDataBarras;
  const newfiltertDataDayli = Object.keys(resDaily.data).map(res => {
    let data = { data: {} };
    data.id = "01GraphicBarras";
    data.title =
      res === "recollections"
        ? "Recolección desde puntos generadores"
        : "Segregación correcta en puntos generadores (en %)";
    data.codigo = res === "recollections" ? "cod-recoleecion" : "cod-calidad";
    data.data.dataDayli =
      res === "recollections"
        ? resDaily.data.recollections
        : resDaily.data.segregations;
    data.data.dataWeekly =
      res === "recollections"
        ? resWeekly.data.recollections
        : resWeekly.data.segregations;
    return data;
  });

  dispatch({
    type: OBTENER_DATA_BARRAS,
    payload: newfiltertDataDayli
  });
};

export const GetDataBar = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_RANK
  });

  const promises = [
    EndPointGetDepartmentAscDsc("ASC"),
    EndPointGetDepartmentAscDsc("DESC")
  ];
  const resultDataBarras = await Promise.all(promises);
  const [resASC, resDESC] = resultDataBarras;
  const newfiltertDataDayli = Object.keys(resASC.data).map(res => {
    let data = { data: {} };
    data.id = "01GraphicBars";
    data.title =
      res === "recollections"
        ? "Ranking de recolección por Gerencia (últimos 7 días)"
        : "Ranking de segregación correcta por Gerencia (últimos 7 días)";
    data.codigo =
      res === "recollections"
        ? "cod-rankingGeneracion"
        : "cod-rankingSegregación";
    data.data.dataASC =
      res === "recollections"
        ? resASC.data.recollections
        : resASC.data.segregations;
    data.data.dataDESC =
      res === "recollections"
        ? resDESC.data.recollections
        : resDESC.data.segregations;
    return data;
  });

  dispatch({
    type: OBTENER_DATA_RANK,
    payload: newfiltertDataDayli
  });
};

export const GetDataPoints = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_GENERIC_TYPES
  });

  const resultGenericTypes = await EndPointGetGenericTypes();
  let requestGeneric = await resultGenericTypes.data.genericTypes.map(
    async ids => {
      ids.idType = ids.id;
      ids.nameType = ids.name;
      ids.data = await EndPointGetGenericTypesId(ids.id);
      return ids;
    }
  );
  const resultAll = await Promise.all(requestGeneric);
  const sortResultGeneric = resultAll.sort((a, b) => {
    return a.idType - b.idType;
  });

  dispatch({
    type: OBTENER_DATA_GENERIC_TYPES,
    payload: sortResultGeneric
  });
};
