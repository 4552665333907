import {
  USER_LOADING,
  LOGIN_FAIL,
  LOGIN_SUCCES,
  LOGOUT_SUCCESS
} from "../Types/Login";

import { EndPointLogin } from "../Services/EndpointSesion";

export const loginUser = data => async (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  const resultLogin = await EndPointLogin(data);
  if (resultLogin.data.code === 0) {
    localStorage.setItem("name", resultLogin.data.result.name);
    localStorage.setItem("dni", resultLogin.data.result.dni);
    localStorage.setItem("id", resultLogin.data.result.id);
    localStorage.setItem("miningSite", resultLogin.data.result.miningSite);
    localStorage.setItem("miningSiteId", resultLogin.data.result.miningSiteId);
    localStorage.setItem("type", resultLogin.data.result.type);
    dispatch({
      type: LOGIN_SUCCES,
      payload: resultLogin.data.result
    });
  } else {
    const errorMessage =
      resultLogin.data.code === 5
        ? "* Usuario no existe"
        : resultLogin.data.code === 4
        ? "* Dni inválido"
        : resultLogin.data.code === 6
        ? "* Contraseña inválida"
        : resultLogin.data.code === 7
        ? "* Usuario no tiene acceso"
        : "* Error de Servidor";
    dispatch({ type: LOGIN_FAIL, payload: errorMessage });
  }
};

export const logOut = () => async (dispatch, getState) => {
  localStorage.removeItem("id_token");
  localStorage.removeItem("name");
  localStorage.removeItem("dni");
  localStorage.removeItem("miningSite");
  localStorage.removeItem("id");
  localStorage.removeItem("miningSiteId");
  localStorage.removeItem("type");
  dispatch({
    type: LOGOUT_SUCCESS
  });
};
