import {
  OBTENER_CLIENT,
  CARGANDO_CLIENT,
  ERROR_CLIENT,
  CARGANDO_PATIOS,
  OBTENER_PATIOS,
  ERROR_PATIOS
} from "./../Types/Ingresos";

const initialState = {
  client: [],
  loading: false,
  error: "",
  patios: [],
  loading_patios: false,
  error_patios: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CARGANDO_CLIENT:
      return {
        ...state,
        loading: true
      };
    case OBTENER_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false,
        error: ""
      };
    case ERROR_CLIENT:
      return {
        ...state,
        client: [],
        loading: false,
        error: action.payload
      };
    case CARGANDO_PATIOS:
      return {
        ...state,
        loading_patios: true
      };
    case OBTENER_PATIOS:
      return {
        ...state,
        patios: action.payload,
        loading_patios: false,
        error_patios: ""
      };
    case ERROR_PATIOS:
      return {
        ...state,
        patios: [],
        loading_patios: false,
        error_patios: action.payload
      };
    default:
      return state;
  }
}
