const appStyle = theme => ({
  sidebar: {
    backgroundColor: "rgba(0,0,0,0.18)",
    "& .MuiPaper-root": {
      backgroundColor: "#000",
      color: "#fff"
    },
    "& .MuiList-padding": {
      paddingTop: "35px"
    },
    "& .btnCloseMap": {
      zIndex: "5",
      width: "50px",
      height: "50px",
      backgroundColor: "#000000",
      border: "0",
      padding: "0",

      "& .closeSpan": {
        width: "30px",
        height: "40px",
        position: "relative",
        display: "inline-block",
        overflow: "hidden",
        opacity: "1",
        "&::before, &::after": {
          backgroundColor: "#ffffff",
          content: '""',
          position: "absolute",
          height: "2px",
          width: "100%",
          top: "50%",
          left: "0",
          marginTop: "-1px"
        }
      }
    }
  },
  contentModule: {
    "& .MuiList-root": {
      paddingLeft: "25px",
      paddingRight: "0"
    },
    "& a": {
      color: "#fff"
    }
  },
  textModule: {
    paddingLeft: "0"
    // paddingRight: "0"
  }
});

export default appStyle;
