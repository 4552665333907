import {
  OBTENER_DATA_VEHICLES,
  LOADING_DATA_VEHICLES,
  LOADING_DATA_VEHICLES_TYPE,
  OBTENER_DATA_VEHICLES_TYPE,
  MESSAGE_DELETE_VEHICLES,
  CREATE_UPDATE_VEHICLES,
  UPDATE_DATA_VEHICLES
} from "../Types/Vehicles";
import {
  EndPointGetVehicle,
  EndPointDeleteVehicle,
  EndPointGetVehicleType,
  EndPointGetVehicleID
} from "../Services/EndPointVehicle";
import { HandleOpenVehicle } from "./ModalActions";

export const GetVehiclesAdmin = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_VEHICLES
  });

  const respuestaVehicle = await EndPointGetVehicle();
  dispatch({
    type: OBTENER_DATA_VEHICLES,
    payload: respuestaVehicle.data.data
  });
};

export const GetVehiclesEspecific = (id, plateNumber, type) => async (
  dispatch,
  getState
) => {
  const value = type === 0 ? null : type;
  const name = plateNumber === "" ? null : encodeURIComponent(plateNumber);
  const ids = id === "" ? null : id;
  const respuestaVehicle = await EndPointGetVehicleID(ids, name, value);
  dispatch({
    type: UPDATE_DATA_VEHICLES,
    payload: respuestaVehicle.data.data
  });
};
export const GetVehicleType = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_VEHICLES_TYPE
  });

  const respuestaVehicleType = await EndPointGetVehicleType();
  dispatch({
    type: OBTENER_DATA_VEHICLES_TYPE,
    payload: respuestaVehicleType.data.data
  });
};
export const DeleteVehicle = id => async (dispatch, getState) => {
  const respuestaOperator = await EndPointDeleteVehicle(id);
  if (!respuestaOperator.data.success) {
    dispatch(HandleOpenVehicle());
    dispatch({
      type: MESSAGE_DELETE_VEHICLES,
      payload: respuestaOperator.data.message
    });
  } else {
    dispatch(GetVehiclesAdmin());
  }
};

export const CreateUpdateVehicle = () => async (dispatch, getState) => {
  // const respuestaVehicle = await EndPointCreateUpdateVehicle(data);
  // if (respuestaVehicle.data.success) {
  //   dispatch(HandleOpenExitVehicle());
  //   dispatch(GetVehiclesAdmin());
  dispatch({
    type: CREATE_UPDATE_VEHICLES
  });
  // } else {
  //   const result =
  //     respuestaVehicle.data.message[0].details.field === "plateNumber"
  //       ? "*La  matrícula ya exite"
  //       : respuestaVehicle.data.message[0].details.errorMessages[0];
  //   // dispatch({
  //   //   type: CREATE_ERROR_VEHICLES,
  //   //   payload: result
  //   // });
  // }
};
