import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import style from "../../assets/jss/disal-main/components/Filters";

const Gerencia = props => {
  return (
    <Grid container className={props.classes.filter}>
      <Grid item xs={3} className={props.classes.contentInput}>
        <Paper className={props.classes.root}>
          <InputBase
            className={props.classes.input}
            value={props.id}
            placeholder="Buscar por id"
            onChange={props.handleSearchId}
          />
          <IconButton
            type="button"
            className={props.classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={props.classes.root}>
          <InputBase
            className={props.classes.input}
            placeholder="Buscar por el nombre"
            value={props.name}
            onChange={props.handleSearchName}
          />
          <IconButton
            type="button"
            className={props.classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Gerencia);
