import React from "react";

const VerResultados = props => {
  return (
    <p>
      Mostrando {""}
      {props.rowsPerPage.length === 0
        ? "-"
        : props.rowsPerPage[0].idPosition} a {""}
      {props.rowsPerPage.length === 0
        ? "-"
        : props.rowsPerPage[props.rowsPerPage.length - 1].idPosition}{" "}
      de{" "}
      {props.totalRowsPerPage.length === 0
        ? "-"
        : props.totalRowsPerPage.length}{" "}
      entradas
    </p>
  );
};

export default VerResultados;
