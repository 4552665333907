export const OBTENER_DATA_DEPARTMENTS = "obtener_data_gerencia";
export const LOADING_DATA_DEPARTMENTS = "loading_data_gerencia";
export const ERROR_DATA_DEPARTMENTS = "error_data_gerencia";

export const UPDATE_DATA_DEPARTMENTS = "actualizar_data_gerencia";

export const OBTENER_DATA_DEPARTMENTS_ESPECIFIC = "obtener_data_especific";
export const LOADING_DATA_DEPARTMENTS_ESPECIFIC = "loading_data_especific";
export const ERROR_DATA_DEPARTMENTS_ESPECIFIC = "error_data_especific";

export const MESSAGE_DELETE_DEAPARTMENT = "message_delete_department";
export const CREATE_UPDATE_DEPARTMENT = "create_update_department";
export const CREATE_ERROR_DEPARTMENT = "create_error_department";
