import React, { Component } from "react";
import Chart from "react-google-charts";
class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graficsBarras: [
        [
          "Fecha",
          props.title,
          {
            role: "annotation"
          }
        ]
      ]
    };
  }
  componentWillMount() {
    const { graficsBarras } = this.state;
    if (this.props.data !== undefined) {
      this.props.data.forEach(v => {
        graficsBarras.push([v.date, v.value, v.value]);
      });
      this.setState({ graficsBarras });
    }
  }
  render() {
    if (this.props.data.length === 0) return null;

    return (
      <Chart
        className="charts"
        width={"100%"}
        height={"250px"}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={this.state.graficsBarras}
        options={{
          chartArea: {
            width: "70%"
          },
          bar: { groupWidth: "18%" },
          legend: { position: "none" },
          animation: {
            startup: false,
            easing: "linear",
            duration: 1500
          },
          vAxis: {
            minValue: 0,
            maxValue: this.props.codigo === "cod-calidad" ? 1 : 500,
            format: this.props.codigo === "cod-calidad" ? "#'%'" : "",
            direction: 1,
            gridlines: {
              count: 0
            },
            textStyle: {
              fontSize: 12,
              color: "#999999"
            }
          },
          hAxis: {
            maxTextLines: 2,
            textStyle: {
              fontSize: 12,
              color: "#999999",
              bold: true
            }
          },
          annotations: {
            alwaysOutside: false
          },
          colors: ["#ed1e79"]
        }}
        // For tests
        rootProps={{ "data-testid": "2" }}
        legendToggle
      />
    );
  }
}
export default Charts;
