import React, { Component } from "react";
import { QRCode } from "react-qr-svg";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
class Modals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id
    };
  }
  render() {
    const minigsite = localStorage.getItem("miningSiteId");
    const id =
      this.props.id !== undefined
        ? this.props.id.toString().length === 1
          ? "000" + this.props.id
          : this.props.id.toString().length === 2
          ? "00" + this.props.id
          : this.props.id.toString().length === 3
          ? "0" + this.props.id
          : this.props.id
        : "";

    const idMina =
      minigsite !== undefined || minigsite !== null
        ? minigsite.toString().length === 1
          ? "00" + minigsite
          : minigsite.toString().length === 2
          ? "0" + minigsite
          : minigsite
        : "";

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.openModal2}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.openModal2}>
          <div className={this.props.classes.paper}>
            <div className="modal-dialog" role="document">
              <div className="modal-content ">
                <div className={this.props.classes.modalHeader}>
                  <button
                    className="btn-close"
                    onClick={this.props.handleClose}
                  >
                    <span className="closeSpan big"></span>
                  </button>
                </div>
                <div
                  className="modal-body modalQR"
                  style={{
                    textAlign: "center",
                    paddingBottom: "30px"
                  }}
                >
                  <h4>Codigo QR del punto {this.props.id}</h4>
                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    style={{ width: 256 }}
                    value={`PE${idMina}${id}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
export default withStyles(style)(Modals);
