import React, { Component } from "react";
import TableOperat from "../Table/TableOperation";
import Hidden from "@material-ui/core/Hidden";
import CardTableOperat from "../Card/CardTableOperat";
class ContentOperation extends Component {
  render() {
    return (
      <div>
        <TableOperat
          pageOfItems={this.props.pageOfItems}
          open={this.props.open}
          status={this.props.status}
        />
        <Hidden mdUp>
          <CardTableOperat
            pageOfItems={this.props.pageOfItems}
            open={this.props.open}
          />
        </Hidden>
      </div>
    );
  }
}

export default ContentOperation;
