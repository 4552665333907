import React, { Component } from "react";
import { connect } from "react-redux";
import CardChart from "../CardTab/CardTabTable";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Card";
class Charts extends Component {
  ponerContenido = () => {
    const { loadingRank, totalDataRank } = this.props;

    if (loadingRank) {
      return <div>load</div>;
    }
    if (!totalDataRank.length) return;
    return (
      <div className={this.props.classes.contentChart}>
        <h4>{totalDataRank[1].title}</h4>
        <div>
          <CardChart
            id={totalDataRank[1].id}
            title={totalDataRank[1].title}
            codigo={totalDataRank[1].codigo}
            data={totalDataRank[1].data}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className={this.props.classes.cardContent}
      >
        {this.ponerContenido()}
      </Grid>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return dashboardReducer;
};

export default connect(mapStateToProps)(withStyles(style)(Charts));
