import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/CardTable";
import Grid from "@material-ui/core/Grid";

class CardTableOperat extends Component {
  render() {
    return (
      <Grid container>
        {this.props.pageOfItems.map((data, index) => {
          return (
            <Grid item xs={12} sm={6}>
              <div className={this.props.classes.contentCard}>
                <h3 className={this.props.classes.title}>
                  {data.id} {data.plateNumber}
                </h3>
                <Grid container>
                  <Grid item xs={12} className={this.props.classes.content1}>
                    <p>Tipo</p>
                    <p>{data.type}</p>
                  </Grid>
                  <Grid item xs={12} className={this.props.classes.content2}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        style={{ borderRight: "1px solid #E5E5E5" }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <p>Programados</p>
                            <p>{data.totalPoints}</p>
                          </Grid>
                          <Grid item xs={12}>
                            <p>Pendientes</p>
                            <p>{data.totalPending}</p>
                          </Grid>
                          <Grid item xs={12}>
                            <p>Finalizados</p>
                            <p>{data.totalDone}</p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={8}
                        style={{
                          borderLeft: "1px solid #E5E5E5",
                          paddingLeft: "10px"
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <p>Ultimo Punto</p>
                            <p className="status background-greenDark">
                              {data.lastCollectPoint === null
                                ? "-"
                                : data.lastCollectPoint.pointNumber}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p>Nombre del Ultimo Punto</p>
                            <p>
                              {data.lastCollectPoint === null
                                ? "-"
                                : data.lastCollectPoint.name}
                              {data.lastCollectPointName}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p>Próximo Punto</p>
                            <p className="status background-yellow-secondary">
                              {data.nextCollectPoint === null
                                ? "-"
                                : data.nextCollectPoint.pointNumber}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p>Nombre del Próximo Punto</p>
                            <p>
                              {data.nextCollectPoint === null
                                ? "-"
                                : data.nextCollectPoint.pointNumber}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default withStyles(style)(CardTableOperat);
