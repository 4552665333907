import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import logoImage from "../../assets/img/logo disal verde.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import style from "../../assets/jss/disal-main/Layouts/Login";
import * as loginAction from "../../Actions/LoginActions";
var CryptoJS = require("crypto-js");

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      dni: "",
      password: "",
      errors: {},
    };
  }
  keyPress = async (e) => {
    const { dni, password, errors } = this.state;
    const KEY = CryptoJS.enc.Utf8.parse("enfasys.tech.000");
    const SECRET_KEY = CryptoJS.enc.Base64.parse("disal.00.resflow");
    let encrypted = CryptoJS.AES.encrypt(password, KEY, {
      iv: SECRET_KEY,
    }).toString();
    const data = {
      dni: dni,
      password: encrypted,
      isWeb: true,
    };
    const name = e.target.name;
    if (name === "password") {
      if (e.keyCode === 13) {
        if (dni !== "" && password !== "") {
          await this.props.loginUser(data);
        } else {
          errors.message = "*Completar todos los campos";
          await this.setState({ errors });
        }
      }
    }
  };
  handleInputChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value === "" ? "" : e.target.value;
    await this.setState({ [name]: value });
  };
  componentDidUpdate = async (prevProps) => {
    const { errors } = this.state;
    if (prevProps.loginReducer.error !== this.props.loginReducer.error) {
      errors.message = this.props.loginReducer.error;
      await this.setState({ errors });
    }
  };
  componentWillReceiveProps(newProps) {
    const token = localStorage.getItem("id_token");

    const { loginReducer } = newProps;
    loginReducer.isAuthenticated &&
      token &&
      this.props.history.push("/Main-Sesion");
  }
  onHandleLorgin = async () => {
    const { dni, password, errors } = this.state;

    const KEY = CryptoJS.enc.Utf8.parse("enfasys.tech.000");
    const SECRET_KEY = CryptoJS.enc.Base64.parse("disal.00.resflow");
    let encrypted = CryptoJS.AES.encrypt(password, KEY, {
      iv: SECRET_KEY,
    }).toString();
    const data = {
      dni: dni,
      password: encrypted,
      isWeb: true,
    };
    if (dni !== "" && password !== "") {
      await this.props.loginUser(data);
    } else {
      errors.message = "*Completar todos los campos";
      await this.setState({ errors });
    }
  };

  render() {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={3}
        style={{ display: "flex" }}
      >
        <Hidden mdUp>
          <img
            className={this.props.classes.logo}
            src={logoImage}
            alt="Logo de la compañia"
          />
        </Hidden>

        <form className={this.props.classes.formLog} noValidate>
          <p className={this.props.classes.TitleLogin}>Resflow</p>
          <hr className={this.props.classes.lineSeparateHr} />
          <p className={this.props.classes.SecondTitle}>Panel Web</p>
          <label className={this.props.classes.TextLabel}>
            Ingresa un número DNI registrado:
          </label>
          <TextField
            name="dni"
            placeholder="DNI"
            variant="outlined"
            onKeyDown={this.keyPress}
            onChange={this.handleInputChange}
            value={this.state.dni}
            className={this.props.classes.inputForm}
            style={{ marginTop: "17px" }}
          />
          <TextField
            variant="outlined"
            type="password"
            name="password"
            className={this.props.classes.inputForm}
            placeholder="Password"
            onKeyDown={this.keyPress}
            onChange={this.handleInputChange}
            value={this.state.name}
          />

          {Object.values(this.state.errors).length !== 0 ? (
            <p>{this.state.errors.message}</p>
          ) : null}

          <Button
            fullWidth
            className={this.props.classes.btn}
            onClick={this.onHandleLorgin}
          >
            Ingresar
          </Button>
        </form>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(
  mapStateToProps,
  loginAction
)(withStyles(style)(LoginForm));
