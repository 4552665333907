//const URLDomain = "https://disal.enfasys-dev.com";
const URLDomain = "https://ambipar-ws.ambipar-app.com";



export const URLAuthLogin = `${URLDomain}/auth/login`;

export const URLDashToday = `${URLDomain}/dashboard/today`;

export const URLDashPointDaily = `${URLDomain}/dashboard/collect-points/daily`;

export const URLDashPointWeekly = `${URLDomain}/dashboard/collect-points/weekly`;

export const URLDashDepartamentASC = `${URLDomain}/dashboard/departments?sort=ASC`;

export const URLDashDepartamentDESC = `${URLDomain}/dashboard/departments?sort=DESC`;

export const URLGenericTypes = `${URLDomain}/generic-types`;

export const URLVehicles = `${URLDomain}/vehicles`;

export const URLDashWork = `${URLDomain}/dashboard/work`;

export const URLDashWorkVehicles = `${URLDomain}/dashboard/work/vehicles`;
// ---------------------------- APIS ID ------------------------------------
export function URLDashWorkVehiclesId(id) {
  return `${URLDomain}/dashboard/work/vehicles/${id}`;
}
export function URLDashIncident(id) {
  return `${URLDomain}/incidents/GetIncidents?collectPointId=${id}`;
}
export function URLDashIncidentDoucment(id) {
  return `${URLDomain}/incidents/GetIncidentDocument?incidentId=${id}`;
}
export function URLDashIncidentComment(id) {
  return `${URLDomain}/incidents/GetIncidentComment/${id}`;
}
export function URLDashWorkCollect(idV, idP) {
  return `${URLDomain}/dashboard/work/vehicles/${idV}/collect-points/${idP}`;
}

export function URLGenericTypesId(id) {
  return `${URLDomain}/dashboard/generic-types/${id}`;
}

export const URLReportDaily = `${URLDomain}/reports/daily`;
export const URLReportRange = `${URLDomain}/reports/range`;
export const URLReportIncident = (dateInitial, dateFinal, id) => {
  return `${URLDomain}/incidents/GetIncidentReport?dateFrom=${dateInitial}&dateTo=${dateFinal}&miningSiteId=${id}`;
};

export const URLIngresosClientes = `${URLDomain}/tempyards/GetClients`;
export const URLStatus = `${URLDomain}/tempyards/GetOutputStates`;

export function URLPatiosId(id) {
  return `${URLDomain}/tempyards/GetWarehouses/${id}`;
}
export function URLIngresosId(Page, miningSite, warehouse, dateFrom, dateTo) {
  return `${URLDomain}/tempyards/GetInputsPaginated/${Page}/${miningSite}/${warehouse}/${dateFrom}/${dateTo}`;
}
export function URLIngresoSpecficId(id) {
  return `${URLDomain}/tempyards/GetInputDetail/${id}`;
}

export function URLStock(warehouse) {
  return `${URLDomain}/tempyards/ListStockByClientAndYard/${warehouse}`;
}

export function URLSalidas(
  Page,
  miningSiteId,
  patioId,
  stateId,
  dateFromReq,
  dateToReq,
  filterId
) {
  return `${URLDomain}/tempyards/GetOutputsPaginated/${Page}/${miningSiteId}/${patioId}/${stateId}/${dateFromReq}/${dateToReq}/${filterId}`;
}

export function URLDocuments(outputId) {
  return `${URLDomain}/tempyards/GetOutputDocuments/${outputId}`;
}

export function URLGetAccept(outputId) {
  return `${URLDomain}/tempyards/GetOutputDetail/${outputId}`;
}

export const URLPutAccept = `${URLDomain}/tempyards/ApprovedOutput`;

export function URLVehiculo(miningSiteId) {
  return `${URLDomain}/tempyards/GetVehicles/${miningSiteId}`;
}
export function URLDestinity(miningSiteId) {
  return `${URLDomain}/tempyards/ListMineDispositionsByClient/${miningSiteId}`;
}

export function URLConductor(miningSiteId) {
  return `${URLDomain}/tempyards/GetDrivers/${miningSiteId}`;
}
