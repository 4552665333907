import {
  OBTENER_DATA_CIRCULE,
  LOADING_DATA_CIRCULE,
  ERROR_DATA_CIRCULE,
  OBTENER_DATA_BARRAS,
  LOADING_DATA_BARRAS,
  ERROR_DATA_BARRAS,
  OBTENER_DATA_RANK,
  LOADING_DATA_RANK,
  ERROR_DATA_RANK,
  OBTENER_DATA_GENERIC_TYPES,
  LOADING_DATA_GENERIC_TYPES,
  ERROR_DATA_GENERIC_TYPES,
  LOADING_DATA_PIE,
  OBTENER_DATA_PIE,
  ERROR_DATA_PIE
} from "./../Types/Dashboard";

const initialState = {
  totalDataCircule: [],
  totalDataBarras: [],
  totalDataRank: [],
  totalDataPie: [],
  totalGenericTypes: [],
  loadingGeneric_types: [],
  errorGeneric_types: [],
  loadingRank: false,
  errorRank: "",
  loadingBarras: false,
  errorBarras: "",
  loadingPie: false,
  errorPie: "",
  loading: false,
  errorCircule: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_CIRCULE:
      return {
        ...state,
        loading: true
      };
    case OBTENER_DATA_CIRCULE:
      return {
        ...state,
        totalDataCircule: action.payload,
        loading: false,
        errorCircule: ""
      };
    case ERROR_DATA_CIRCULE:
      return {
        ...state,
        totalDataCircule: [],
        loading: false,
        errorCircule: action.payload
      };

    case LOADING_DATA_BARRAS:
      return {
        ...state,
        loadingBarras: true
      };
    case OBTENER_DATA_BARRAS:
      return {
        ...state,
        totalDataBarras: action.payload,
        loadingBarras: false,
        errorBarras: ""
      };
    case ERROR_DATA_BARRAS:
      return {
        ...state,
        totalDataBarras: [],
        loadingBarras: false,
        errorBarras: action.payload
      };
    case LOADING_DATA_RANK:
      return {
        ...state,
        loadingRank: true
      };
    case OBTENER_DATA_RANK:
      return {
        ...state,
        totalDataRank: action.payload,
        loadingRank: false,
        errorRank: ""
      };
    case ERROR_DATA_RANK:
      return {
        ...state,
        totalDataRank: [],
        loadingRank: false,
        errorRank: action.payload
      };
    case LOADING_DATA_GENERIC_TYPES:
      return {
        ...state,
        loadingGeneric_types: true
      };
    case OBTENER_DATA_GENERIC_TYPES:
      return {
        ...state,
        totalGenericTypes: action.payload,
        loadingGeneric_types: false,
        errorGeneric_types: ""
      };
    case ERROR_DATA_GENERIC_TYPES:
      return {
        ...state,
        totalGenericTypes: [],
        loadingGeneric_types: false,
        errorGeneric_types: action.payload
      };
    case LOADING_DATA_PIE:
      return {
        ...state,
        loadingPie: true
      };
    case OBTENER_DATA_PIE:
      return {
        ...state,
        totalDataPie: action.payload,
        loadingPie: false,
        errorPie: ""
      };
    case ERROR_DATA_PIE:
      return {
        ...state,
        totalDataPie: [],
        loadingPie: false,
        errorPie: action.payload
      };
    default:
      return state;
  }
}
