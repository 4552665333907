import api from "./ApiURLBase";
const EndPointGetOperators = async () =>
  await api.get(`/operators/GetOperator`);
const EndPointGetOperatorsEspecific = async (id, dni, name, type) =>
  await api.get(
    `/operators/GetOperator?operatorId=${id}&dniDescription=${dni}&nameDescription=${name}&operatorTypeId=${type}`
  );
const EndPointGetOperatorType = async () =>
  await api.get(`/operators/GetOperatorType`);
const EndPointCreateUpdateOperator = async data =>
  await api.post(`/operators/CreateUpdateOperator/`, data);
const EndPointDeleteOperator = async id =>
  await api.delete(`/operators/DeleteOperator/${id}`);

export {
  EndPointGetOperators,
  EndPointGetOperatorType,
  EndPointDeleteOperator,
  EndPointGetOperatorsEspecific,
  EndPointCreateUpdateOperator
};
