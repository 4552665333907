import {
  USER_LOADING,
  LOGIN_FAIL,
  LOGIN_SUCCES,
  LOGOUT_SUCCESS
} from "../Types/Login";
import { login, logout } from "./../Services/Auth";
const initialState = {
  isAuthenticated: null,
  isLoading: false,
  user: null,
  error: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case LOGIN_SUCCES:
      login(action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        error: ""
      };
    case LOGIN_FAIL:
      return {
        isAuthenticated: false,
        isLoading: false,
        user: null,
        error: action.payload
      };
    case LOGOUT_SUCCESS:
      logout();
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        error: ""
      };
    default:
      return state;
  }
}
