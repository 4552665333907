import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Slide";
import Media from "react-media";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CardMedia from "@material-ui/core/CardMedia";
class cardSlide extends Component {
  render() {
    return (
      <Card
        className={
          this.props.classes.card +
          (this.props.index === this.props.activeIndex &&
          this.props.statusTable === "byId"
            ? " slider-item"
            : " hide")
        }
        onClick={async () =>
          await this.props.handleCard(
            this.props.item.id,
            this.props.item.type,
            this.props.item.plateNumber,
            this.props.index,
            false
          )
        }
      >
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)",
          }}
        >
          {(matches) =>
            matches.small ? (
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="115"
                image={this.props.item.url}
                title="Contemplative Reptile"
              />
            ) : (
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="135"
                image={this.props.item.url}
                title="Contemplative Reptile"
              />
            )
          }
        </Media>
        <div
          className={
            this.props.classes.cardBody +
            (this.props.index === this.props.activeIndex &&
            this.props.statusTable === "byId"
              ? " bcContent"
              : " hide")
          }
        >
          <Grid container>
            <Grid item xs={2}>
              <img src={this.props.item.url} alt="Card cap" />
            </Grid>
            <Grid item xs={7}>
              <div className="body-content">
                <p className="main-word mb-0">{this.props.item.plateNumber}</p>
                <p className="total-count mb-0">{this.props.item.type}</p>
              </div>
            </Grid>
            <Grid item xs={3}>
              <p className={this.props.classes.percentage}>
                {parseFloat(this.props.item.donePercentage).toFixed(0)}%
              </p>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  }
}

export default withStyles(style)(cardSlide);
