const appStyle = theme => ({
  ingresos: {
    paddingTop: "35px",
    paddingBottom: "35px",
    paddingLeft: "162px",
    paddingRight: "162px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    "& h4": {
      color: "#000000",
      fontFamily: '"Roboto"',
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "24px",
      marginBottom: "28px"
    }
  },
  formControl: {
    width: "90%",
    backgroundColor: "#fff",
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: "#e2e2e2"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 32px 10.5px 16px"
    }
  },
  mainTable: {
    marginTop: "50px",
    backgroundColor: "#fff",
    padding: "29px 20px 50px"
  },
  contentTable: {
    backgroundColor: "#fff",
    "& .MuiTableCell-root": {
      color: "#000000",
      fontFamily: '"Roboto"',
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "400",

      "& .statusTableSalidas": {
        display: "inline-block",
        padding: "5px",
        marginBottom: "0"
      }
    },
    "& .MuiTableCell-head": {
      fontWeight: "700"
    }
  },
  detailTable: {
    "& button ": {
      margin: "0 10px",
      backgroundColor: "#ffffff",
      border: "1px solid #424344",
      borderRadius: "10px",
      padding: "10px 20px",
      color: "#080808",
      fontFamily: '"Roboto"',
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "18px",
      textAlign: "left"
    }
  }
});

export default appStyle;
