import React, { Component } from "react";
import { connect } from "react-redux";
import * as pointAcopioAction from "../Actions/PointAcopioAction";
import * as gerenciaAction from "../Actions/GerenciaActions";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import style from "../assets/jss/disal-main/Layouts/Gerencia";

import FormAcopio from "../Component/Form/PointAcopio";
import Modal from "../Component/Modal/ModalDeletePointAcopio";
import ModalMessage from "../Component/Modal/ModalMessageDeletePoint";

import Filter from "../Component/Filters/PointAcopio";

const {
  GetPointAcopio,
  GetPointAcopioContainer,
  GetPointAcopioTypeContainer,
  GetPointAcopioTypeResidue,
  GetPointAcopioEspecific,
} = pointAcopioAction;
const { GetDepartments } = gerenciaAction;

class PointAcopio extends Component {
  constructor() {
    super();
    this.state = {
      idSelect: null,
      open: false,
      statusForm: false,
      data: {},
      number: "",
      id: "",
      name: "",
      type: 0,
    };
  }
  componentDidMount = async () => {
    if (!this.props.gerenciaReducer.department.length) {
      await this.props.GetDepartments();
    }
    if (!this.props.pointAcopioReducer.points.length) {
      await this.props.GetPointAcopio();
    }

    await this.props.GetPointAcopioTypeContainer();
    await this.props.GetPointAcopioTypeResidue();
    await this.props.GetPointAcopioEspecific(null, null, null, 0);
  };

  handleOpen = async () => {
    await this.setState({
      open: true,
    });
  };

  HandleSearchId = async (e) => {
    const value = e.target.value;
    const name = this.state.name;
    const number = this.state.number;
    const type = this.state.type;

    var regex = /^([1-9])*$/;
    if (regex.test(value)) {
      await this.setState({
        id: value,
      });
      await this.props.GetPointAcopioEspecific(value, number, name, type);
    }
    if (value === "") {
      await this.setState({
        id: value,
      });
      await this.props.GetPointAcopioEspecific(null, number, name, type);
    }
  };

  HandleSearchNumber = async (e) => {
    const value = e.target.value;
    const name = this.state.name;
    const id = this.state.id;
    const type = this.state.type;
    var regex = /^([0-9])*$/;
    if (regex.test(value)) {
      await this.setState({
        number: value,
      });
      await this.props.GetPointAcopioEspecific(id, value, name, type);
    }
    if (value === "") {
      await this.setState({
        number: value,
      });
      await this.props.GetPointAcopioEspecific(id, null, name, type);
    }
  };

  HandleSearchName = async (e) => {
    const value = e.target.value;
    const number = this.state.number;
    const id = this.state.id;
    const type = this.state.type;
    if (value.length > 3 && value !== "") {
      await this.setState({
        name: value,
      });
      await this.props.GetPointAcopioEspecific(id, number, value, type);
    } else {
      await this.setState({
        name: value,
      });
      if (value.length === 3) {
        await this.props.GetPointAcopioEspecific(id, number, null, type);
      }
    }
  };

  HandleSearchType = async (e) => {
    const value = e.target.value;
    const number = this.state.number;
    const id = this.state.id;
    const name = this.state.name;
    if (value !== 0) {
      await this.setState({
        type: value,
      });
      await this.props.GetPointAcopioEspecific(id, number, name, value);
    } else {
      await this.setState({
        type: 0,
      });
      await this.props.GetPointAcopioEspecific(id, number, name, value);
    }
  };
  handleClose = async () => {
    await this.setState({
      open: false,
    });
  };
  handleStatus = async () => {
    await this.setState({
      statusForm: true,
      data: {},
      idSelect: null,
      number: "",
      id: "",
      name: "",
      type: 0,
    });
  };
  handleClean = async () => {
    await this.setState({
      data: {},
      idSelect: null,
      number: "",
      id: "",
      name: "",
      type: 0,
    });
  };
  handleCancel = async () => {
    await this.setState({
      statusForm: false,
      data: {},
      idSelect: null,
      number: "",
      id: "",
      name: "",
      type: 0,
    });
  };
  handleClickPoint = async (e) => {
    const id = e.currentTarget.id;
    const filter = this.props.pointAcopioReducer.points.filter(
      (res) => Number(id) === res.collectPointId
    );
    this.props.GetPointAcopioContainer(id);
    await this.setState({
      statusForm: true,
      idSelect: Number(id),
      data: filter[0],
    });
  };

  render() {
    return (
      <div className={this.props.classes.content}>
        <Grid container className={this.props.classes.gerencia}>
          <Grid item xs={12}>
            <Filter
              id={this.state.id}
              number={this.state.number}
              name={this.state.name}
              type={this.state.type}
              HandleSearchNumber={this.HandleSearchNumber}
              HandleSearchId={this.HandleSearchId}
              HandleSearchName={this.HandleSearchName}
              HandleSearchType={this.HandleSearchType}
            />
          </Grid>
          <Grid item xs={4} className={this.props.classes.listAdd}>
            <h3 className={this.props.classes.title}>Lista de los Puntos</h3>
            <List className={this.props.classes.listGerencia}>
              {this.props.pointAcopioReducer.loading ? (
                <div>Loading</div>
              ) : (
                this.props.pointAcopioReducer.points.map((res, index) => (
                  <ListItem
                    button
                    id={res.collectPointId}
                    className={
                      this.props.classes.Listbutton +
                      (this.state.idSelect === res.collectPointId
                        ? " active-item"
                        : null)
                    }
                    onClick={this.handleClickPoint}
                    key={index}
                  >
                    <ListItemText
                      primary={
                        res.pointNumber + ", " + res.collectPointDescription
                      }
                    />
                    {this.state.idSelect === res.collectPointId ? (
                      <button
                        id={res.collectPointId}
                        className={this.props.classes.iconClose}
                        onClick={this.handleOpen}
                      >
                        <CloseIcon />
                      </button>
                    ) : null}
                  </ListItem>
                ))
              )}
            </List>
            <button
              className={this.props.classes.iconMore}
              onClick={this.handleStatus}
            >
              <AddIcon />
            </button>
          </Grid>
          <Grid item xs={8} className={this.props.classes.formGerencia}>
            <h3 className={this.props.classes.title}>Datos del Punto</h3>
            {this.state.statusForm ? (
              <FormAcopio
                data={this.state.data}
                handleCancel={this.handleCancel}
                handleClean={this.handleClean}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No hay punto seleccionado{" "}
              </div>
            )}
          </Grid>
        </Grid>
        <Modal
          handleClose={this.handleClose}
          handleClean={this.handleClean}
          open={this.state.open}
          id={this.state.idSelect}
        />
        <ModalMessage />
      </div>
    );
  }
}
const mapStateToProps = ({ pointAcopioReducer, gerenciaReducer }) => {
  return { pointAcopioReducer, gerenciaReducer };
};
const mapDispatchToProps = {
  GetPointAcopio,
  GetDepartments,
  GetPointAcopioContainer,
  GetPointAcopioTypeContainer,
  GetPointAcopioTypeResidue,
  GetPointAcopioEspecific,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PointAcopio));
