import React, { Component } from "react";
import Point from "../Charts/PointsChart";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Tab";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";
class Charts extends Component {
  constructor(props) {
    super();
    this.state = {
      residuos: [
        "GENERALES",
        "METÁLICOS",
        "PLÁSTICOS",
        "ORGANICOS",
        "PAPEL Y CARTÓN",
        "VIDRIO",
        "PELIGROSOS",
        "ARENA"
      ],
      value: 0
    };
  }
  handleChangeTab = async (event, newValue) => {
    await this.setState({
      value: newValue
    });
  };
  a11yProps = index => {
    return {
      id: `bar-tab-${index}`,
      "aria-controls": `bar-tabpanel-${index}`
    };
  };

  render() {
    return (
      <div className={this.props.classes.root}>
        <AppBar position="static" className={this.props.classes.tabHeader}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            aria-label="point tabs example"
          >
            {this.state.residuos.map((data, i) => (
              <Tab label={data} {...this.a11yProps(i)} />
            ))}
          </Tabs>
        </AppBar>
        {this.props.data.map((data, i) => (
          <TabPanel value={this.state.value} index={i} name="point">
            <Point
              title={`Tendencia por tipo de residuo: ${data.name.toUpperCase()}`}
              data={data.data.data.recollections}
            />
          </TabPanel>
        ))}
      </div>
    );
  }
}

export default withStyles(style)(Charts);
