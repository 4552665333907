import {
  OBTENER_VEHICLES,
  CARGANDO_VEHICLES,
  ERROR_VEHICLES,
  OBTENER_DATA_TABLE,
  CARGANDO_DATA_TABLE,
  ERROR_DATA_TABLE,
  UPDATE_DATA_TABLE_BY_ID,
  UPDATE_DATA_TABLE_GENERAL,
  UPDATE_OPEN_MAP,
  MONITORING_OPERATION,
  CARGANDO_MONITORING
} from "./../Types/Operation";

const initialState = {
  arrayVehicle: [],
  dataTableGeneral: [],
  dataTableById: [],
  coordMap: [],
  loading: false,
  open: false,
  statusTable: "general",
  loading_data_Table: false,
  error_loading_Table: "",
  error: "",
  loading_monitoring: false,
  total: "",
  finished: "",
  pending: "",
  noProgramated: "",
  nameVehicle: "",
  activeIndex: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CARGANDO_VEHICLES:
      return {
        ...state,
        loading: true
      };
    case OBTENER_VEHICLES:
      return {
        ...state,
        arrayVehicle: action.payload,
        loading: false,
        error: ""
      };
    case ERROR_VEHICLES:
      return {
        ...state,
        arrayVehicle: [],
        loading: false,
        error: action.payload
      };
    case CARGANDO_DATA_TABLE:
      return {
        ...state,
        loading_data_Table: true
      };
    case OBTENER_DATA_TABLE:
      return {
        ...state,
        dataTableGeneral: action.payload.dataTable,
        coordMap: action.payload.coordMap,
        loading_data_Table: false,
        error_loading_Table: ""
      };
    case ERROR_DATA_TABLE:
      return {
        ...state,
        dataTableGeneral: [],
        coordMap: [],
        loading_data_Table: false,
        error: action.payload
      };
    case UPDATE_DATA_TABLE_GENERAL:
      return {
        ...state,
        dataTableGeneral: action.payload.dataTable,
        coordMap: action.payload.coordMap,
        statusTable: "general"
      };
    case UPDATE_DATA_TABLE_BY_ID:
      return {
        ...state,
        dataTableById: action.payload.dataTable,
        coordMap: action.payload.coordMap,
        nameVehicle: action.payload.nameVehicle,
        activeIndex: action.payload.activeIndex,
        statusTable: action.payload.status,
        loading_data_Table: false,
      };

    case UPDATE_OPEN_MAP:
      return {
        ...state,
        open: action.payload
      };
    case CARGANDO_MONITORING:
      return {
        ...state,
        loading_monitoring: true
      };
    case MONITORING_OPERATION:
      return {
        ...state,
        total: action.payload.total,
        finished: action.payload.finished,
        pending: action.payload.pending,
        noProgramated: action.payload.noProgramated,
        loading_monitoring: false
      };
    default:
      return state;
  }
}
