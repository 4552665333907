import {
  OBTENER_DATA_DEPARTMENTS,
  LOADING_DATA_DEPARTMENTS,
  ERROR_DATA_DEPARTMENTS,
  OBTENER_DATA_DEPARTMENTS_ESPECIFIC,
  LOADING_DATA_DEPARTMENTS_ESPECIFIC,
  ERROR_DATA_DEPARTMENTS_ESPECIFIC,
  MESSAGE_DELETE_DEAPARTMENT,
  CREATE_UPDATE_DEPARTMENT,
  UPDATE_DATA_DEPARTMENTS
} from "./../Types/Gerencia";

const initialState = {
  department: [],
  loading: false,
  error: "",
  depart_especific: {},
  loading_especific: false,
  error_especific: "",
  message: "",
  messageCreate: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_DEPARTMENTS:
      return {
        ...state,
        loading: true
      };
    case OBTENER_DATA_DEPARTMENTS:
      return {
        ...state,
        department: action.payload,
        loading: false,
        error: ""
      };
    case UPDATE_DATA_DEPARTMENTS:
      return {
        ...state,
        department: action.payload
      };
    case ERROR_DATA_DEPARTMENTS:
      return {
        ...state,
        department: [],
        loading: false,
        error: action.payload
      };
    case LOADING_DATA_DEPARTMENTS_ESPECIFIC:
      return {
        ...state,
        loading_especific: true
      };
    case OBTENER_DATA_DEPARTMENTS_ESPECIFIC:
      return {
        ...state,
        depart_especific: action.payload,
        loading_especific: false,
        error_especific: ""
      };
    case ERROR_DATA_DEPARTMENTS_ESPECIFIC:
      return {
        ...state,
        depart_especific: [],
        loading_especific: false,
        error_especific: action.payload
      };
    case MESSAGE_DELETE_DEAPARTMENT:
      return {
        ...state,
        message: action.payload
      };
    case CREATE_UPDATE_DEPARTMENT:
      return {
        ...state,
        department: [],
        loading: false,
        error: ""
      };
    default:
      return state;
  }
}
