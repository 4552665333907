import React, { Component } from "react";
import PieChart from "../Charts/PieChart";
import { connect } from "react-redux";
import Spinner from "../Spinner";

import { getCurrentDate } from "../date";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Card";
class Charts extends Component {
  ponerContenido = () => {
    const { loading, totalDataCircule } = this.props;

    if (loading) {
      return <Spinner />;
    }
    if (!totalDataCircule.length) return;
    return (
      <div className={this.props.classes.contentChart}>
        <h4>
          {totalDataCircule[this.props.num].codigo === "cod-GraphRecolect"
            ? totalDataCircule[this.props.num].title +
              " " +
              getCurrentDate().today +
              " (en tons)"
            : totalDataCircule[this.props.num].title +
              " " +
              getCurrentDate().today +
              " (en %)"}
        </h4>
        <div className={this.props.classes.borderTop}>
          <PieChart
            id={totalDataCircule[this.props.num].id}
            title={this.props.totalDataCircule[this.props.num].title}
            codigo={this.props.totalDataCircule[this.props.num].codigo}
            data={this.props.totalDataCircule[this.props.num].data}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className={this.props.classes.cardContent}
      >
        {this.ponerContenido()}
      </Grid>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return dashboardReducer;
};

export default connect(mapStateToProps)(withStyles(style)(Charts));
