import {
  OBTENER_SALIDAS_VEHICLES,
  CARGANDO_SALIDAS_VEHICLES,
  ERROR_SALIDAS_VEHICLES,
  OBTENER_SALIDAS_STATUS,
  CARGANDO_SALIDAS_STATUS,
  ERROR_SALIDAS_STATUS
} from "./../Types/Salidas";

const initialState = {
  vehicles: [],
  loading: false,
  error: "",
  status: [],
  loading_status: false,
  error_status: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CARGANDO_SALIDAS_VEHICLES:
      return {
        ...state,
        loading: true
      };
    case OBTENER_SALIDAS_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
        loading: false,
        error: ""
      };
    case ERROR_SALIDAS_VEHICLES:
      return {
        ...state,
        vehicles: [],
        loading: false,
        error: action.payload
      };
    case CARGANDO_SALIDAS_STATUS:
      return {
        ...state,
        loading_status: true
      };
    case OBTENER_SALIDAS_STATUS:
      return {
        ...state,
        status: action.payload,
        loading_status: false,
        error_status: ""
      };
    case ERROR_SALIDAS_STATUS:
      return {
        ...state,
        status: [],
        loading_status: false,
        error_status: action.payload
      };
    default:
      return state;
  }
}
