import {
  CREATE_ERROR_POINTS,
  CREATE_ERROR_OPERATOR,
  CREATE_ERROR_VEHICLES
} from "../Types/Error";

const initialState = {
  errorDepartment: "",
  errorVehicles: "",
  errorUser: "",
  errorPoint: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_ERROR_VEHICLES:
      return {
        ...state,
        errorVehicles: action.payload
      };
    case CREATE_ERROR_OPERATOR:
      return {
        ...state,
        errorUser: action.payload
      };
    case CREATE_ERROR_POINTS:
      return {
        ...state,
        errorPoint: action.payload
      };

    default:
      return state;
  }
}
