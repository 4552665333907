const appStyle = theme => ({
  dashContent: {
    width: "1200px",
    paddingTop: "35px",
    paddingBottom: "35px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",

      paddingLeft: "10px",
      paddingRight: "10px"
    }
  }
});

export default appStyle;
