import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { DatePicker, Modal, TimePicker, Button } from "antd";
import { URLDestinity, URLConductor, URLGetAccept } from "../../other/apis";
import axios from "axios";
import "antd/dist/antd.css";
import moment from "moment";
import ModalConfirm from "./ModalConfirm";
import ModalDenied from "./ModalDenied";
const today = moment();
const format = "HH:mm";
class ModalSalida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      destinity: [],
      driver: [],
      detail: {},
      valueSelectVehicle: "0",
      valueSelectDestinity: "0",
      visibleConfirm: false,
      visibleDenied: false,
      outDate: moment().format("YYYY-MM-DD"),
      arrivalDate: moment().format("YYYY-MM-DD"),
      returnDate: moment().format("YYYY-MM-DD"),
      finDate: moment().format("YYYY-MM-DD"),
      outHour: moment(),
      arrivalHour: moment(),
      returnHour: moment(),
      finHour: moment(),
      valueSelectDriver: "0",
      inputsErroneos: [],
      dataModalConfirmDenied: {}
    };
  }
  componentWillReceiveProps = async props => {
    if (props.id !== null) {
      await this.setState({
        id: props.id,
        valueSelectVehicle: props.namePlaca,
        valueSelectDestinity: props.nameDestino
      });
      await this.getDetail();
    }
  };
  async componentDidMount() {
    await this.getDestinity();
    await this.getDriver();
  }

  getDetail = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDetail = await axios.get(URLGetAccept(this.state.id), {
      headers
    });

    this.setState({ detail: respuestDetail.data.data });
  };
  getDestinity = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDestinity = await axios.get(
      URLDestinity(this.props.idMiningSite),
      { headers }
    );
    this.setState({ destinity: respuestDestinity.data.data });
  };
  getDriver = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDriver = await axios.get(
      URLConductor(this.props.idMiningSite),
      { headers }
    );
    this.setState({ driver: respuestDriver.data.result });
  };
  handleSlectValue = async e => {
    const name = e.target.name;
    const value = e.target.value;
    await this.setState({ [name]: value });
  };
  handleDateOut = async e => {
    await this.setState({
      outDate:
        e === null
          ? moment().format("YYYY-MM-DD")
          : moment(e).format("YYYY-MM-DD")
    });
  };
  handleDateArrival = async e => {
    await this.setState({
      arrivalDate:
        e === null
          ? moment().format("YYYY-MM-DD")
          : moment(e).format("YYYY-MM-DD")
    });
  };
  handleDateReturn = async e => {
    await this.setState({
      returnDate:
        e === null
          ? moment().format("YYYY-MM-DD")
          : moment(e).format("YYYY-MM-DD")
    });
  };
  handleDateFin = async e => {
    await this.setState({
      finDate:
        e === null
          ? moment().format("YYYY-MM-DD")
          : moment(e).format("YYYY-MM-DD")
    });
  };
  handleHourOut = async e => {
    await this.setState({
      outHour: e === null ? moment() : moment(e)
    });
  };
  handleHourArrival = async e => {
    await this.setState({
      arrivalHour: e === null ? moment() : moment(e)
    });
  };
  handleHourReturn = async e => {
    await this.setState({
      returnHour: e === null ? moment() : moment(e)
    });
  };
  handleHourFin = async e => {
    await this.setState({
      finHour: e === null ? moment() : moment(e)
    });
  };

  handleAccept = async () => {
    let currentExpandedRows = this.state.inputsErroneos;
    let data = {
      stateId: 2,
      vehicleId: Number(this.state.valueSelectVehicle),
      mineDispositionId: Number(this.state.valueSelectDestinity),
      estimatedDepartureFromMineTime: moment(
        this.state.outDate + " " + this.state.outHour.format("HH:mm")
      )
        .utc()
        .valueOf(),
      estimatedArrivalDestinationTime: moment(
        this.state.arrivalDate + " " + this.state.arrivalHour.format("HH:mm")
      )
        .utc()
        .valueOf(),
      outputId: Number(this.props.id),
      driverId: Number(this.state.valueSelectDriver),
      estimatedReturnMineTime: moment(
        this.state.returnDate + " " + this.state.returnHour.format("HH:mm")
      )
        .utc()
        .valueOf(),
      estimatedEndMineTime: moment(
        this.state.finDate + " " + this.state.finHour.format("HH:mm")
      )
        .utc()
        .valueOf()
    };

    if (
      this.state.valueSelectVehicle !== "0" &&
      this.state.valueSelectDriver !== "0" &&
      this.state.valueSelectDestinity !== "0" &&
      moment(this.state.outDate + " " + this.state.outHour.format("HH:mm")) <=
        moment(
          this.state.arrivalDate + " " + this.state.arrivalHour.format("HH:mm")
        ) &&
      moment(
        this.state.arrivalDate + " " + this.state.arrivalHour.format("HH:mm")
      ) <=
        moment(
          this.state.returnDate + " " + this.state.returnHour.format("HH:mm")
        ) &&
      moment(
        this.state.returnDate + " " + this.state.returnHour.format("HH:mm")
      ) <= moment(this.state.finDate + " " + this.state.finHour.format("HH:mm"))
    ) {
      currentExpandedRows = currentExpandedRows.filter(
        id => typeof id !== "number"
      );

      currentExpandedRows = currentExpandedRows.filter(
        id => typeof id !== "string"
      );

      await this.setState(previousState => {
        return {
          inputsErroneos: currentExpandedRows
        };
      });
      await this.setState({
        visibleConfirm: true,
        dataModalConfirmDenied: data
      });
      // this.showPropsConfirm(data);
    } else {
      if (this.state.valueSelectVehicle === "0") {
        currentExpandedRows.push("vehicle");
      } else {
        currentExpandedRows = currentExpandedRows.filter(
          id => id !== "vehicle"
        );
      }
      if (this.state.valueSelectDestinity === "0") {
        currentExpandedRows.push("destiny");
      } else {
        currentExpandedRows = currentExpandedRows.filter(
          id => id !== "destiny"
        );
      }
      if (this.state.valueSelectDriver === "0") {
        currentExpandedRows.push("driver");
      } else {
        currentExpandedRows = currentExpandedRows.filter(id => id !== "driver");
      }
      if (
        moment(this.state.outDate + " " + this.state.outHour.format("HH:mm")) >
        moment(
          this.state.arrivalDate + " " + this.state.arrivalHour.format("HH:mm")
        )
      ) {
        currentExpandedRows.push(2);
      } else {
        currentExpandedRows = currentExpandedRows.filter(id => id !== 2);
      }
      if (
        moment(
          this.state.arrivalDate + " " + this.state.arrivalHour.format("HH:mm")
        ) >
        moment(
          this.state.returnDate + " " + this.state.returnHour.format("HH:mm")
        )
      ) {
        currentExpandedRows.push(3);
      } else {
        currentExpandedRows = currentExpandedRows.filter(id => id !== 3);
      }
      if (
        moment(
          this.state.returnDate + " " + this.state.returnHour.format("HH:mm")
        ) >
        moment(this.state.finDate + " " + this.state.finHour.format("HH:mm"))
      ) {
        currentExpandedRows.push(4);
      } else {
        currentExpandedRows = currentExpandedRows.filter(id => id !== 4);
      }

      await this.setState({ inputsErroneos: currentExpandedRows });
    }
  };

  handleDenied = () => {
    // this.props.onOk();
    let data = {
      stateId: 7,
      vehicleId: null,
      mineDispositionId: null,
      estimatedDepartureFromMineTime: null,
      estimatedArrivalDestinationTime: null,
      outputId: Number(this.props.id),
      driverId: null,
      estimatedReturnMineTime: null,
      estimatedEndMineTime: null
    };
    this.setState({
      visibleDenied: true,
      dataModalConfirmDenied: data
    });
  };

  handleClean = () => {
    this.props.onOk();

    this.setState({
      id: null,
      detail: {},
      valueSelectVehicle: "0",
      valueSelectDestinity: "0",
      outDate: moment(today).format("YYYY-MM-DD"),
      arrivalDate: moment(today).format("YYYY-MM-DD"),
      returnDate: moment(today).format("YYYY-MM-DD"),
      finDate: moment(today).format("YYYY-MM-DD"),
      outHour: moment(today),
      arrivalHour: moment(today),
      returnHour: moment(today),
      finHour: moment(today),
      valueSelectDriver: "0",
      inputsErroneos: []
    });
  };

  onOkA = () => {
    this.setState({ visibleConfirm: false });
  };
  onCancelA = () => {
    this.setState({ visibleConfirm: false });
  };
  onOkD = () => {
    this.setState({ visibleDenied: false });
  };
  onCancelD = () => {
    this.setState({ visibleDenied: false });
  };

  render() {
    return (
      <Modal
        title=" Aprobar/Rechazar salida de residuo"
        visible={this.props.visible}
        onOk={this.handleClean}
        onCancel={this.handleClean}
        cancelText="Aprobar"
        footer={null}
        okText="Rechazar"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body table-responsive">
              <div className="salidas-modal" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div>
                      <Grid container>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <label className="title-section">VEHICULO</label>
                          <FormControl
                            variant="outlined"
                            className={this.props.classes.formControl}
                          >
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              name="valueSelectVehicle"
                              id="demo-simple-select-outlined"
                              value={this.state.valueSelectVehicle}
                              onChange={this.handleSlectValue}
                            >
                              <MenuItem value={0}>
                                Selecciona una opcion
                              </MenuItem>
                              {this.props.salidasReducer.vehicles.length !== 0
                                ? this.props.salidasReducer.vehicles.map(
                                    (res, keyV) => {
                                      return (
                                        <MenuItem key={keyV} value={res.id}>
                                          {res.plate_number}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                : null}
                            </Select>
                          </FormControl>

                          {this.state.inputsErroneos.includes("vehicle") ? (
                            <p className="error-message">Vehiculo incorrecto</p>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <label className="title-section">DESTINO</label>
                          <FormControl
                            variant="outlined"
                            className={this.props.classes.formControl}
                          >
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              name="valueSelectDestinity"
                              id="demo-simple-select-outlined"
                              value={this.state.valueSelectDestinity}
                              onChange={this.handleSlectValue}
                              disabled
                            >
                              <MenuItem value={0}>
                                Selecciona una opcion
                              </MenuItem>
                              {this.state.destinity.map((dest, keyD) => {
                                return (
                                  <MenuItem key={keyD} value={dest.id}>
                                    {dest.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          {this.state.inputsErroneos.includes("destiny") ? (
                            <p className="error-message">Destino incorrecto</p>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <div>
                            <label className="title-section">
                              SALIDA PROGRAMADA
                            </label>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "10px"
                              }}
                            >
                              <DatePicker
                                value={moment(
                                  moment(this.state.outDate),
                                  "DD/MM/YYYY"
                                )}
                                onChange={this.handleDateOut}
                                size="large"
                                className="input-antd"
                                placeholder="Desde"
                                style={{
                                  zIndex: "1200"
                                }}
                                format="DD/MM/YYYY"
                              />
                              <TimePicker
                                value={moment(this.state.outHour, format)}
                                onChange={this.handleHourOut}
                                size="large"
                                className="height-antd-time"
                                format={format}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <div>
                            <label className="title-section">
                              LLEGADA PROGRAMADA
                            </label>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "10px"
                              }}
                            >
                              <DatePicker
                                value={moment(
                                  moment(this.state.arrivalDate),
                                  "DD/MM/YYYY"
                                )}
                                onChange={this.handleDateArrival}
                                size="large"
                                className={
                                  "input-antd" +
                                  (this.state.inputsErroneos.includes(2)
                                    ? " errorInput"
                                    : " ")
                                }
                                placeholder="Desde"
                                format="DD/MM/YYYY"
                              />
                              <TimePicker
                                value={moment(
                                  moment(this.state.arrivalHour),
                                  format
                                )}
                                onChange={this.handleHourArrival}
                                size="large"
                                className={
                                  "" +
                                  (this.state.inputsErroneos.includes(2)
                                    ? " errorInput"
                                    : " ")
                                }
                                format={format}
                              />
                            </div>
                            {this.state.inputsErroneos.includes(2) ? (
                              <p className="error-message">Fecha Incorrecta</p>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <div>
                            <label className="title-section">
                              RETORNO PROGRAMADA
                            </label>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "10px"
                              }}
                            >
                              <DatePicker
                                value={moment(
                                  moment(this.state.returnDate),
                                  "DD/MM/YYYY"
                                )}
                                onChange={this.handleDateReturn}
                                size="large"
                                className={
                                  "header-select input-antd " +
                                  (this.state.inputsErroneos.includes(3)
                                    ? " errorInput"
                                    : " ")
                                }
                                placeholder="Desde"
                                format="DD/MM/YYYY"
                              />
                              <TimePicker
                                value={moment(
                                  moment(this.state.returnHour),
                                  format
                                )}
                                onChange={this.handleHourReturn}
                                size="large"
                                className={
                                  "header-select" +
                                  (this.state.inputsErroneos.includes(3)
                                    ? " errorInput"
                                    : " ")
                                }
                                format={format}
                              />
                            </div>
                            {this.state.inputsErroneos.includes(3) ? (
                              <p className="error-message">Fecha Incorrecta</p>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <div>
                            <label className="title-section">
                              FIN PROGRAMADA
                            </label>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "10px"
                              }}
                            >
                              <DatePicker
                                value={moment(
                                  moment(this.state.finDate),
                                  "DD/MM/YYYY"
                                )}
                                onChange={this.handleDateFin}
                                size="large"
                                className={
                                  "header-select input-antd" +
                                  (this.state.inputsErroneos.includes(4)
                                    ? " errorInput"
                                    : " ")
                                }
                                placeholder="Desde"
                                format="DD/MM/YYYY"
                              />
                              <TimePicker
                                value={moment(
                                  moment(this.state.finHour),
                                  format
                                )}
                                onChange={this.handleHourFin}
                                size="large"
                                className={
                                  "header-select" +
                                  (this.state.inputsErroneos.includes(4)
                                    ? " errorInput"
                                    : " ")
                                }
                                format={format}
                              />
                            </div>
                            {this.state.inputsErroneos.includes(4) ? (
                              <p className="error-message">Fecha Incorrecta</p>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          sm={6}
                          className={this.props.classes.containerViewSalida}
                        >
                          <label className="title-section">CONDUCTOR</label>
                          <FormControl
                            variant="outlined"
                            className={this.props.classes.formControl}
                          >
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              name="valueSelectDriver"
                              id="demo-simple-select-outlined"
                              value={this.state.valueSelectDriver}
                              onChange={this.handleSlectValue}
                            >
                              <MenuItem value={0}>
                                Selecciona una opcion
                              </MenuItem>
                              {this.state.driver.map((driv, keyDr) => {
                                return (
                                  <MenuItem key={keyDr} value={driv.id}>
                                    {driv.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          {this.state.inputsErroneos.includes("driver") ? (
                            <p className="error-message">
                              Conductor incorrecto
                            </p>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6} className="detail-modal">
                          <label className="title-section">
                            DETALLE DE RESIDUOS
                          </label>
                          {this.state.detail.subTypes !== undefined
                            ? this.state.detail.subTypes.map(
                                (resDetail, key) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "16px"
                                      }}
                                      key={key}
                                    >
                                      <div
                                        className="circle-ingreso"
                                        style={{
                                          backgroundColor: resDetail.color,
                                          marginRight: "24px",

                                          border:
                                            resDetail.name === "Plásticos"
                                              ? "1px solid #6A6A6A"
                                              : "none"
                                        }}
                                      ></div>
                                      <label style={{ marginRight: "16px" }}>
                                        {resDetail.name}
                                      </label>
                                      {resDetail.amount} kg
                                    </div>
                                  );
                                }
                              )
                            : null}
                          <div>
                            <label style={{ marginRight: "16px" }}>
                              Total:{" "}
                            </label>
                            {this.state.detail !== undefined
                              ? this.state.detail.totalAmount + " kg"
                              : null}
                          </div>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p
                            className={
                              this.props.data.length > 0
                                ? this.props.data[0].outputStateId === 1
                                  ? " background-fucsia"
                                  : this.props.data[0].outputStateId === 7
                                  ? " background-red"
                                  : this.props.data[0].outputStateId === 2
                                  ? " background-yellow-secondary"
                                  : this.props.data[0].outputStateId === 6
                                  ? " background-green-light"
                                  : " background-sky-blue"
                                : null
                            }
                            style={{
                              display: "inline-block",
                              padding: "5px 15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                              color: "#fff"
                            }}
                          >
                            {this.props.data.length > 0
                              ? this.props.data[0].outputState
                              : null}
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      type="button"
                      className="btn-modal-salida"
                      style={{
                        textDecoration: "underline",
                        marginRight: "10px"
                      }}
                      onClick={this.handleAccept}
                    >
                      Acceptar
                    </Button>
                    <Button
                      type="button"
                      className="btn-modal-salida"
                      style={{ textDecoration: "underline" }}
                      onClick={this.handleDenied}
                    >
                      Rechazar
                    </Button>
                  </div>
                </div>
              </div>
              <ModalConfirm
                visibleConfirm={this.state.visibleConfirm}
                data={this.state.dataModalConfirmDenied}
                onOkA={this.onOkA}
                funct={this.props.funct}
                handleClean={this.handleClean}
              />
              <ModalDenied
                visibleDenied={this.state.visibleDenied}
                data={this.state.dataModalConfirmDenied}
                funct={this.props.funct}
                onOkD={this.onOkD}
                handleClean={this.handleClean}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = ({ salidasReducer }) => {
  return { salidasReducer };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ModalSalida));
