const appStyle = theme => ({
  filter: {
    marginBottom: "30px"
  },
  contentInput: {
    paddingRight: "13px"
  },
  formControl: {
    width: "80%",
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px"
    }
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  }
});

export default appStyle;
