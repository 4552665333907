import {
  OBTENER_DATA_VEHICLES,
  LOADING_DATA_VEHICLES,
  ERROR_DATA_VEHICLES,
  LOADING_DATA_VEHICLES_TYPE,
  OBTENER_DATA_VEHICLES_TYPE,
  ERROR_DATA_VEHICLES_TYPE,
  CREATE_UPDATE_VEHICLES,
  MESSAGE_DELETE_VEHICLES,
  UPDATE_DATA_VEHICLES
} from "../Types/Vehicles";

const initialState = {
  vehicle: [],
  loading: false,
  error: "",
  vehicle_type: [],
  loading_type: false,
  error_type: "",
  message: "",
  messageError: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_VEHICLES:
      return {
        ...state,
        loading: true
      };
    case OBTENER_DATA_VEHICLES:
      return {
        ...state,
        vehicle: action.payload,
        loading: false,
        error: ""
      };
    case UPDATE_DATA_VEHICLES:
      return {
        ...state,
        vehicle: action.payload
      };
    case ERROR_DATA_VEHICLES:
      return {
        ...state,
        vehicle: [],
        loading: false,
        error: action.payload
      };
    case LOADING_DATA_VEHICLES_TYPE:
      return {
        ...state,
        loading_type: true
      };
    case OBTENER_DATA_VEHICLES_TYPE:
      return {
        ...state,
        vehicle_type: action.payload,
        loading_type: false,
        error_type: ""
      };
    case ERROR_DATA_VEHICLES_TYPE:
      return {
        ...state,
        vehicle_type: [],
        loading_type: false,
        error_type: action.payload
      };
    case MESSAGE_DELETE_VEHICLES:
      return {
        ...state,
        message: action.payload
      };

    case CREATE_UPDATE_VEHICLES:
      return {
        ...state,
        vehicle: [],
        loading: false,
        error: ""
      };
    default:
      return state;
  }
}
