import {
  backgroundPrimaryBlue,
  backgroundPrimaryYellow,
  backgroundPrimaryGreenDark,
  backgroundPrimaryRed
} from "../../disal-main";
const appStyle = theme => ({
  operation: {
    paddingTop: "35px",
    paddingBottom: "35px",
    paddingLeft: "162px",
    paddingRight: "162px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
      paddingRight: "20px"
    }
  },
  mapaMobile: {
    zIndex: "1000",
    overflow: "hidden",
    "& .MuiDrawer-paper": {
      width: "100%"
    },
    "& .btnCloseMap": {
      position: "absolute",
      zIndex: "10000005",
      width: "50px",
      height: "50px",
      top: "0",
      right: "0",
      backgroundColor: "#000000",
      border: "0",
      padding: "0",

      "& .closeSpan": {
        width: "30px",
        height: "40px",
        position: "relative",
        display: "inline-block",
        overflow: "hidden",
        opacity: "1",
        "&::before, &::after": {
          backgroundColor: "#ffffff",
          content: '""',
          position: "absolute",
          height: "2px",
          width: "100%",
          top: "50%",
          left: "0",
          marginTop: "-1px"
        }
      }
    }
  },
  iconMap: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      width: "20%",
      marginTop: "auto",
      marginLeft: "auto",
      marginBottom: "auto"
    },
    "& button": {
      border: "1px solid #C4C4C4",
      backgroundColor: "#fff",
      display: "flex",
      borderRadius: "50px",
      alignItems: "center",
      padding: "5px"
    }
  },
  boxTitle: {
    display: "flex",
    justifyContent: "space-between",
    color: " #000000",
    fontFamily: "Roboto",
    [theme.breakpoints.down("md")]: {
      display: "block"
    },

    "& h4,& p": {
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "24px"
    }
  },
  boxCenter: {
    paddingRight: "30px",
    paddingLeft: "30px"
  },
  slideStyle: {
    marginTop: "35px",
    padding: "29px 20px",
    boxShadow: "0 0 8px 0 rgba(208, 208, 208, 0.5)"
  },
  boxMonitoring: {
    borderRadius: "30px",
    padding: "18px 25px",
    margin: "5px 0",
    color: "#ffffff",
    display: "flex",
    alignitems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "10px 25px"
    },
    "&.bcgBlue": {
      backgroundColor: backgroundPrimaryBlue
    },
    "&.bcgYellow": {
      backgroundColor: backgroundPrimaryYellow
    },
    "&.bcgGreen": {
      backgroundColor: backgroundPrimaryGreenDark
    },
    "&.bcgRed": {
      backgroundColor: backgroundPrimaryRed
    },
    "& .img-icons": {
      width: "67px",
      height: "67px",
      backgroundColor: "#ffffff",
      borderRadius: "50%",
      display: "flex",
      fontsize: "25px",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "25px",
        height: "25px"
      }
    },
    "& .info-monitoring": {
      marginLeft: "33px",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "500",
      "& .main-word": {
        marginBottom: "12px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "0"
        }
      },
      "& .total-count": {
        marginBottom: "0",
        "& span": {
          fontSize: "30px",
          fontWeight: "bold",
          marginRight: "12px",
          lineHeight: "35px"
        }
      }
    }
  },
  paddingRight: {
    paddingRight: "0.9375rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0"
    }
  },
  paddingLeft: {
    paddingLeft: "0.9375rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0"
    }
  },
  tableStyle: {
    padding: "0 0 0 30px",
    marginTop: "35px",
    boxShadow: "0 0 8px 0 rgba(208,208,208,0.5)",
    justifyContent: "space-between"
  },
  contentTitle: {
    display: "flex"
  },
  titleTable: {
    color: "#000000",
    fontFamily: '"Roboto"',
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "19px",
    marginTop: "31px",
    "& span": {
      fontWeight: "bold"
    },
    [theme.breakpoints.down("md")]: {
      width: "80%"
    }
  },
  infoPage: {
    marginTop: "25px",
    marginBottom: "31px",
    display: "flex",
    flexDirection: "row",

    justifyContent: "space-between ",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",

      marginTop: "5px",
      marginBottom: "20px"
    },
    "& p": {
      display: "block",
      color: "#808080",
      fontFamily: '"Roboto"',
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
      alignSelf: "center",
      marginBottom: "0px"
    }
  },
  transtition: {
    transition: "all .5s linear, visibility 1s linear",
    "&.MuiGrid-grid-sm-11": {
      width: "91.666667%",
      flexBasis: "unset"
    },
    "&.MuiGrid-grid-sm-1": {
      width: "8.333333%",
      flexBasis: "unset"
    },
    "&.otherStyle": {
      flexGrow: "0",
      maxWidth: "50%",
      width: "50%"
    }
  },
  btnSeeMap: {
    height: "100%",
    display: "flex",
    position: "absolute",
    left: "15px",
    top: "0px",
    cursor: "pointer",
    zIndex: "2000000",
    opacity: "1",
    width: "calc(100% - 15px)",

    backgroundColor: "#000000",
    transition: "all .5s linear, opacity 1s linear",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    "&.otherStyleContent": {
      width: "0px",

      left: "0px",
      right: "unset"
    },
    "& p": {
      writingMode: "vertical-lr",
      margin: "auto",
      display: "flex",
      width: "calc(100% - 15px)",
      height: "180px",
      color: "#fff",
      opacity: "1",
      transition: "all .5s linear, opacity 1s linear",

      fontSize: "16px",
      fontWeight: "500",
      alignItems: "center",
      "&.otherStyleMap": {
        width: "0px",
        pointerEvents: "none",

        left: "0px",
        opacity: "0"
      }
    },

    "& img": {
      marginTop: "10px"
    }
  },
  map: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  btnCloseMap: {
    position: "absolute",
    zIndex: "5",
    width: "50px",
    height: "50px",
    top: "0",
    right: "0",
    backgroundColor: "#000000",
    border: "0",
    padding: "0",

    "& .closeSpan": {
      width: "30px",
      height: "40px",
      position: "relative",
      display: "inline-block",
      overflow: "visible",
      opacity: "1",
      "&::before, &::after": {
        backgroundColor: "#ffffff",
        content: '""',
        position: "absolute",
        height: "2px",
        width: "100%",
        top: "50%",
        left: "0",
        marginTop: "-1px"
      }
    }
  },
  contentMap: {
    display: "flex",
    marginLeft: "auto",
    width: "100%",
    "&.transition-map": {}
  }
});

export default appStyle;
