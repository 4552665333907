import React, { Component } from "react";
import { connect } from "react-redux";
import * as gerenciaAction from "../../Actions/GerenciaActions";
import * as modalAction from "../../Actions/ModalActions";

import Grid from "@material-ui/core/Grid";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import style from "../../assets/jss/disal-main/Layouts/Gerencia";
import Modal from "../Modal/ModalExitoGerencia";
import { EndPointCreateUpdateDepartment } from "../../Services/EndPointDepartment";
const { CreateUpdateDepartments, GetDepartments } = gerenciaAction;

const { HandleOpenGerencia, HandleOpenExitGerencia } = modalAction;
class Gerencia extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      outsoursing: -1,
      error: { name: "", outsoursing: "" }
    };
  }
  async componentDidMount() {
    if (Object.values(this.props.data).length > 0) {
      await this.setState({
        id: this.props.data.departmentId,
        name: this.props.data.departmentDescription,
        outsoursing: this.props.data.isOutsourcing,
        error: { name: "", outsoursing: "" }
      });
    } else {
      await this.setState({
        id: "",
        name: "",
        outsoursing: -1,
        error: { name: "", outsoursing: "" }
      });
    }
  }
  componentWillReceiveProps = async nextprops => {
    if (Object.values(nextprops.data).length > 0) {
      await this.setState({
        id: nextprops.data.departmentId,
        name: nextprops.data.departmentDescription,
        outsoursing: nextprops.data.isOutsourcing,
        error: { name: "", outsoursing: "" }
      });
    } else {
      await this.setState({
        id: "",
        name: "",
        outsoursing: -1,
        error: { name: "", outsoursing: "" }
      });
    }
  };
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    });
  };
  handleCancel = async e => {
    await this.setState({
      id: "",
      name: "",
      outsoursing: -1,
      error: { name: "", outsoursing: "" }
    });
    await this.props.handleStatus();
  };
  handleSave = async () => {
    const { error } = this.state;
    let data;
    if (this.state.id !== "") {
      data = {
        departmentId: this.state.id,
        departmentDescription: this.state.name,
        isOutsourcing: this.state.outsoursing === 1 ? true : false
      };
    } else {
      data = {
        departmentDescription: this.state.name,
        isOutsourcing: this.state.outsoursing === 1 ? true : false
      };
    }
    if (this.state.name === "") {
      error.name = "*Completar el campo";
    } else {
      error.name = "";
    }
    if (this.state.outsoursing === -1) {
      error.outsoursing = "*Selecciona una opcion";
    } else {
      error.outsoursing = "";
    }
    this.setState({
      error
    });
    if (this.state.name !== "" && this.state.outsoursing !== -1) {
      const respuestaDepartments = await EndPointCreateUpdateDepartment(data);
      if (respuestaDepartments.data.success) {
        this.props.HandleOpenExitGerencia();
        this.props.GetDepartments();
        this.props.CreateUpdateDepartments();
        this.props.handleClean();
      }
    }
  };
  render() {
    return (
      <div className={this.props.classes.contentForm}>
        <Grid container>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>ID </label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    disabled
                    name="id"
                    variant="outlined"
                    value={this.state.id}
                    className={this.props.classes.textFielInput + " blocked-id"}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Nombre </label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="name"
                    variant="outlined"
                    inputProps={{
                      maxLength: 100
                    }}
                    value={this.state.name}
                    onChange={this.handleChange}
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.name === undefined
                      ? ""
                      : this.state.error.name}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Outsourcing </label>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="outsoursing"
                      value={this.state.outsoursing}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={-1}>Selecciona una opción</MenuItem>
                      <MenuItem value={1}>Sí</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <p>
                    {this.state.error.outsoursing === undefined
                      ? ""
                      : this.state.error.outsoursing}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <div className={this.props.classes.contentButton}>
          <button onClick={this.handleCancel}>Cancelar</button>
          <button onClick={this.handleSave}>Guardar</button>
        </div>
        <Modal />
      </div>
    );
  }
}
const mapStateToProps = ({ gerenciaReducer }) => {
  return { gerenciaReducer };
};
const mapDispatchToProps = {
  CreateUpdateDepartments,
  GetDepartments,
  HandleOpenGerencia,
  HandleOpenExitGerencia
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Gerencia));
