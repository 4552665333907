import React, { Component } from "react";
import PointsCardChart from "../CardTab/CardTabPoint";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Card";
class Charts extends Component {
  ponerContenido = () => {
    const { loading, totalGenericTypes } = this.props;

    if (loading) {
      return <Spinner />;
    }
    if (!totalGenericTypes.length) return;
    return (
      <div className={this.props.classes.contentChart}>
        <h4>{this.props.title}</h4>
        <div>
          <PointsCardChart
            id={this.props.id}
            title={this.props.title}
            codigo={this.props.codigo}
            data={this.props.totalGenericTypes}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <Grid item xs={12} md={11} className={this.props.classes.cardContent}>
        {this.ponerContenido()}
      </Grid>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return dashboardReducer;
};

export default connect(mapStateToProps)(withStyles(style)(Charts));
