import React, { Component } from "react";
import { connect } from "react-redux";
import * as vehicleAction from "../../Actions/VehicleAction";
import * as modalAction from "../../Actions/ModalActions";

import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import style from "../../assets/jss/disal-main/Layouts/Gerencia";
import Modal from "../Modal/ModalExitoVehicle";
import { EndPointCreateUpdateVehicle } from "../../Services/EndPointVehicle";
const { HandleOpenVehicle, HandleOpenExitVehicle } = modalAction;
const { CreateUpdateVehicle, GetVehiclesAdmin } = vehicleAction;
class Vehicle extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      matricula: "",
      vehicleType: 0,
      error: { matricula: "", vehicleType: "" }
    };
  }
  async componentDidMount() {
    if (Object.values(this.props.data).length > 0) {
      await this.setState({
        id: this.props.data.vehicleId,
        matricula: this.props.data.plateNumber,
        vehicleType: this.props.data.vehicleTypeId,
        error: { matricula: "", vehicleType: "" }
      });
    } else {
      await this.setState({
        id: "",
        matricula: "",
        vehicleType: 0,
        error: { matricula: "", vehicleType: "" }
      });
    }
  }
  componentWillReceiveProps = async nextprops => {
    if (Object.values(nextprops.data).length > 0) {
      await this.setState({
        id: nextprops.data.vehicleId,
        matricula: nextprops.data.plateNumber,
        vehicleType: nextprops.data.vehicleTypeId,
        error: { matricula: "", vehicleType: "" }
      });
    } else {
      await this.setState({
        id: "",
        matricula: "",
        vehicleType: 0,
        error: { matricula: "", vehicleType: "" }
      });
    }
  };
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    });
  };
  handleSave = async () => {
    const { error } = this.state;
    let data;
    if (this.state.id !== "") {
      data = {
        id: this.state.id,
        plateNumber: this.state.matricula,
        vehicleTypeId: this.state.vehicleType,
        travelType: "I"
      };
    } else {
      data = {
        plateNumber: this.state.matricula,
        vehicleTypeId: this.state.vehicleType,
        travelType: "I"
      };
    }
    if (this.state.matricula === "") {
      error.matricula = "*Completar el campo";
    } else {
      error.matricula = "";
    }
    if (this.state.vehicleType === 0) {
      error.vehicleType = "*Selecciona una opción";
    } else {
      error.vehicleType = "";
    }

    this.setState({
      error
    });
    if (this.state.matricula !== "" && this.state.vehicleType !== 0) {
      const respuestaVehicle = await EndPointCreateUpdateVehicle(data);
      if (respuestaVehicle.data.success) {
        this.props.HandleOpenExitVehicle();
        this.props.GetVehiclesAdmin();
        this.props.CreateUpdateVehicle();
        this.props.handleClean();
      } else {
        const result =
          respuestaVehicle.data.message[0].details.field === "plateNumber"
            ? "*La  matrícula ya exite"
            : respuestaVehicle.data.message[0].details.errorMessages[0];
        error.messageSave = result;
        this.setState({
          error
        });
      }
    }
  };
  handleCancel = () => {
    this.setState({
      id: "",
      matricula: "",
      vehicleType: 0,
      error: { matricula: "", vehicleType: "" }
    });
    this.props.handleCancel();
  };
  render() {
    return (
      <div className={this.props.classes.contentForm}>
        <Grid container>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>ID </label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    disabled
                    name="id"
                    value={this.state.id}
                    variant="outlined"
                    onChange={this.handleChange}
                    className={this.props.classes.textFielInput + " blocked-id"}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Matrícula</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="matricula"
                    value={this.state.matricula}
                    variant="outlined"
                    inputProps={{
                      maxLength: 7
                    }}
                    onChange={this.handleChange}
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.matricula === ""
                      ? this.state.error.messageSave !== undefined
                        ? this.state.error.messageSave
                        : null
                      : this.state.error.matricula}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={3}>
                  <label>Tipo Vehículo</label>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="vehicleType"
                      value={this.state.vehicleType}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.vehicleReducer.vehicle_type.map(res => {
                        return (
                          <MenuItem value={res.vehicleTypeId}>
                            {res.vehicleTypeDescription}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p>
                    {this.state.error.vehicleType === undefined
                      ? ""
                      : this.state.error.vehicleType}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <div className={this.props.classes.contentButton}>
          <button onClick={this.handleCancel}>Cancelar</button>
          <button onClick={this.handleSave}>Guardar</button>
        </div>
        <Modal />
      </div>
    );
  }
}
const mapStateToProps = ({ vehicleReducer }) => {
  return { vehicleReducer };
};
const mapDispatchToProps = {
  CreateUpdateVehicle,
  GetVehiclesAdmin,
  HandleOpenVehicle,
  HandleOpenExitVehicle
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Vehicle));
