const appStyle = theme => ({
  cardContent: {
    padding: "15px"
  },
  tabHeader: {
    backgroundColor: "#ffffff",
    marginBottom: "10px",
    boxShadow: "none",
    borderBottom: "1px solid #dee2e6",
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .MuiTab-root": {
      padding: " 1rem .5rem 0.5rem",
      color: "#b9b9b9",
      fontSize: "16px",
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: {
        minWidth: "120px"
      }
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#353535",
      borderBottom: "5px solid #353535"
    },
    "& .MuiTabs-flexContainer": {
      overflowX: "auto"
    }
  }
});

export default appStyle;
