import {
  LOADING_DATA_ROUTES,
  OBTENER_DATA_ROUTES,
  ERROR_DATA_ROUTES,
  CREATE_UPDATE_ROUTES
} from "../Types/Routes";

const initialState = {
  routes: [],
  loading: false,
  error: "",

  messageCreate: "",
  messageContainer: "",
  messageCreateContainer: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA_ROUTES:
      return {
        ...state,
        loading: true
      };
    case OBTENER_DATA_ROUTES:
      return {
        ...state,
        routes: action.payload,
        loading: false,
        error: "",
        messageErrorPoint: ""
      };

    case ERROR_DATA_ROUTES:
      return {
        ...state,
        routes: [],
        loading: false,
        error: action.payload
      };
    case CREATE_UPDATE_ROUTES:
      return {
        ...state,
        routes: [],
        loading: false,
        error: ""
      };

    default:
      return state;
  }
}
