const appStyle = (theme) => ({
  ingresos: {
    paddingTop: "35px",
    paddingBottom: "35px",
    paddingLeft: "162px",
    paddingRight: "162px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& h4": {
      color: "#000000",
      fontFamily: '"Roboto"',
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "24px",
      marginBottom: "28px",
    },
  },
  formControl: {
    width: "90%",
    backgroundColor: "#fff",
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: "#e2e2e2",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 32px 10.5px 16px",
    },
  },
  contentCard: {
    marginTop: "40px",
    "& .card-material": {
      padding: "15px",
      "& .header-card": {
        textAlign: "center",
      },
      "& .header-card-stock": {
        display: "flex",
        "& p": {
          marginBottom: "0",
        },
      },
      "& .content-stock-peligroso ": {
        padding: " 20px",

        color: "#808080",
        fontFamily: '"Roboto"',
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "23px",
        textAlign: "left",
        "& label": {
          marginBottom: "5px",
        },
      },
    },
    "& hr": {
      border: "0",
      borderTop: "1px solid rgba(0,0,0,.1)",
    },
  },
  mainTable: {
    marginTop: "50px",
    backgroundColor: "#fff",
    padding: "29px 20px 50px",
  },
  contentTable: {
    backgroundColor: "#fff",
    "& .MuiTableCell-head": {
      color: "#000000",
      fontFamily: '"Roboto"',
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "700",
    },
  },
});

export default appStyle;
