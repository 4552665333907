import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Tab";
import Chart from "../Charts/Charts";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";

class Charts extends Component {
  constructor() {
    super();
    this.state = {
      value: 0
    };
  }

  handleChangeTab = async (event, newValue) => {
    const name = event.currentTarget.name;
    await this.setState({
      value: newValue
    });
    await this.props.handleChange(name);
  };
  a11yProps = index => {
    return {
      id: `barra-tab-${index}`,
      "aria-controls": `barra-tabpanel-${index}`
    };
  };
  render() {
    return (
      <div className={this.props.classes.root}>
        <AppBar position="static" className={this.props.classes.tabHeader}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            aria-label="barra tabs example"
          >
            <Tab name="Diario" label="DIARIO" {...this.a11yProps(0)} />
            <Tab label="SEMANAL" name="Semanal" {...this.a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0} name="barra">
          <Chart
            id={this.props.id}
            codigo={this.props.codigo}
            title={this.props.title}
            data={this.props.data.dataDayli}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={1} name="barra">
          <Chart
            id={this.props.id}
            codigo={this.props.codigo}
            title={this.props.title}
            data={this.props.data.dataWeekly}
          />
        </TabPanel>
      </div>
    );
  }
}
export default withStyles(style)(Charts);
