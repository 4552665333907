import React, { Component } from "react";
import Gerencia from "../../Views/Gerencia";
import Usuarios from "../../Views/Users";
import Vehicles from "../../Views/Vehicles";
import PointAcopio from "../../Views/PointAcopio";
import Routes from "../../Views/Routes";
import { Switch, Redirect, Route } from "react-router-dom";

class PageAdmin extends Component {
  render() {
    return (
      <div>
        <div className="page-admin">
          <Switch>
            <Route path="/Main-Sesion/Admin/Gerencia" component={Gerencia} />
            <Route path="/Main-Sesion/Admin/Usuarios" component={Usuarios} />
            <Route path="/Main-Sesion/Admin/Vehiculos" component={Vehicles} />
            <Route
              path="/Main-Sesion/Admin/Punto-de-Acopio"
              component={PointAcopio}
            />
            <Route path="/Main-Sesion/Admin/Routes" component={Routes} />
            <Redirect to={"/Main-Sesion/Admin/Gerencia"} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default PageAdmin;
