const appStyle = theme => ({
  contentCard: {
    padding: "15px",
    marginBottom: "15px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginRight: "10px",
    "& p": {
      marginBottom: 0,
      fontSize: "12px"
    }
  },
  title: {
    backgroundColor: "#E5E5E5",
    borderRadius: "10px 10px 0px 0px",
    margin: "-15px -15px 10px   -15px",
    padding: "15px"
  },
  content2: {
    borderTop: "2px solid #E5E5E5",
    marginTop: "15px",
    paddingTop: "15px"
  },
  opcion1: {
    display: "flex"
  },
  contentButton: {
    "& button": {
      border: "1px solid #E5E5E5",
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      padding: "5px",
      borderRadius: "50px",
      "&.disab": {
        cursor: "unset",
        background: "#bfbfbf",
        color: "#000",
        border: "1px solid #bfbfbf"
      }
    }
  }
});

export default appStyle;
