import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import style from "../../assets/jss/disal-main/Layouts/Login";
import LoginForm from "../../Component/Login-parts/LoginForm";
import LoginImage from "../../Component/Login-parts/LoginImage";
import Hidden from "@material-ui/core/Hidden";

class Login extends Component {
  render() {
    return (
      <Grid container className={this.props.classes.wrapper}>
        <Hidden smDown>
          <LoginImage />
        </Hidden>
        <LoginForm history={this.props.history} />
      </Grid>
    );
  }
}
export default withStyles(style)(Login);
