import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import * as pointAcopioAction from "../../Actions/PointAcopioAction";
import * as modalAction from "../../Actions/ModalActions";
import List from "@material-ui/core/List";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import style from "../../assets/jss/disal-main/Layouts/PointAcopio";
import Modal from "../Modal/ModalExitoPoints";
import {
  EndPointCreateUpdateVehiclePoint,
  EndPointCreateUpdateCollectPoint
} from "../../Services/EndPointAcopio";
const { HandleOpenPoint, HandleOpenExitPoint } = modalAction;
const {
  GetPointAcopio,
  GetPointAcopioContainer,
  CreateUpdatePoint
} = pointAcopioAction;

class PointAcopio extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      number: "",
      latitude: "",
      longitude: "",
      gerencia: 0,
      container: 0,
      residue: 0,
      addData: [],
      newData: [],
      error: {
        name: "",
        number: "",
        latitude: "",
        longitude: "",
        gerencia: "",
        addData: ""
      }
    };
  }
  async componentDidMount() {
    if (Object.values(this.props.data).length > 0) {
      const estructureArray = this.props.pointAcopioReducer.container.map(
        res => {
          return {
            containerId: res.containerId,
            collectPointId: res.collectPointId,
            containerModelId: res.containerModelId,
            containerModelName: res.containerModelName,
            ResidualSubTypeId: res.residualSubtypeId,
            residualSubtypeName: res.residualSubtypeName,
            isDeleted: 0
          };
        }
      );
      const estructurenewArray = this.props.pointAcopioReducer.container.map(
        res => {
          return {
            containerId: res.containerId,
            collectPointId: res.collectPointId,
            containerModelId: res.containerModelId,
            residualSubTypeId: res.residualSubtypeId,
            isDeleted: 0
          };
        }
      );
      await this.setState({
        id: this.props.data.collectPointId,
        name: this.props.data.collectPointDescription,
        number: this.props.data.pointNumber,
        latitude: this.props.data.latitude,
        longitude: this.props.data.longitude,
        gerencia: this.props.data.departmentId,
        addData: estructureArray,
        newData: estructurenewArray,
        container: 0,
        residue: 0,
        error: {
          name: "",
          number: "",
          latitude: "",
          longitude: "",
          gerencia: "",
          addData: ""
        }
      });
    } else {
      await this.setState({
        id: "",
        name: "",
        number: "",
        latitude: "",
        longitude: "",
        gerencia: 0,
        container: 0,
        residue: 0,
        addData: [],
        newData: [],

        error: {
          name: "",
          number: "",
          latitude: "",
          longitude: "",
          gerencia: "",
          addData: ""
        }
      });
    }
  }
  componentWillReceiveProps = async nextprops => {
    if (Object.values(nextprops.data).length > 0) {
      const estructureArray = nextprops.pointAcopioReducer.container.map(
        res => {
          return {
            containerId: res.containerId,
            collectPointId: res.collectPointId,
            containerModelId: res.containerModelId,
            containerModelName: res.containerModelName,
            residualSubTypeId: res.residualSubtypeId,
            residualSubtypeName: res.residualSubtypeName,
            isDeleted: 0
          };
        }
      );
      const estructurenewArray = nextprops.pointAcopioReducer.container.map(
        res => {
          return {
            containerId: res.containerId,
            collectPointId: res.collectPointId,
            containerModelId: res.containerModelId,
            residualSubTypeId: res.residualSubtypeId,
            isDeleted: 0
          };
        }
      );
      await this.setState({
        id: nextprops.data.collectPointId,
        name: nextprops.data.collectPointDescription,
        number: nextprops.data.pointNumber,
        latitude: nextprops.data.latitude,
        longitude: nextprops.data.longitude,
        gerencia: nextprops.data.departmentId,
        addData: estructureArray,
        newData: estructurenewArray,
        container: 0,
        residue: 0,
        error: {
          name: "",
          number: "",
          latitude: "",
          longitude: "",
          gerencia: "",
          addData: ""
        }
      });
    } else {
      await this.setState({
        id: "",
        name: "",
        number: "",
        latitude: "",
        longitude: "",
        gerencia: 0,
        container: 0,
        residue: 0,
        addData: [],
        newData: [],
        error: {
          name: "",
          number: "",
          latitude: "",
          longitude: "",
          gerencia: "",
          addData: ""
        }
      });
    }
  };
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    var regex_numeros = /[0-9]/;
    var RE = /^-?(?:\d+(?:.\d{0,8})?)$/;

    if (name === "number") {
      if (regex_numeros.test(value) || value === "") {
        this.setState({
          [name]: value
        });
      }
    }
    if (name === "latitude") {
      if (RE.test(value) || value === "") {
        this.setState({
          [name]: value
        });
      }
    } else {
      this.setState({
        [name]: value
      });
    }
  };
  handleChangeSelect = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value
    });
  };
  handleCancel = () => {
    this.setState({
      id: "",
      name: "",
      number: "",
      latitude: "",
      longitude: "",
      gerencia: 0,
      addData: [],
      error: {
        name: "",
        number: "",
        latitude: "",
        longitude: "",
        gerencia: "",
        addData: ""
      }
    });
    this.props.handleCancel();
  };
  handleSave = async () => {
    const { newData, error } = this.state;
    let data = {
      collectPointDescription: this.state.name,
      pointNumber: Number(this.state.number),
      latitude: Number(
        Number.parseFloat(Number(this.state.latitude)).toFixed(8)
      ),
      longitude: Number(
        Number.parseFloat(Number(this.state.longitude)).toFixed(8)
      ),
      departmentId: this.state.gerencia
    };

    const newResult =
      this.state.id !== "" ? Object.assign(data, { id: this.state.id }) : data;
    if (this.state.name === "") {
      error.name = "*Completar campo";
    } else {
      error.name = "";
    }
    if (this.state.number === "") {
      error.number = "*Completar campo";
    } else {
      error.number = "";
    }
    if (this.state.latitude === "") {
      error.latitude = "*Completar campo";
    } else {
      error.latitude = "";
    }
    if (this.state.longitude === "") {
      error.longitude = "*Completar campo";
    } else {
      error.longitude = "";
    }
    if (this.state.gerencia === 0) {
      error.gerencia = "*Seleccione una opción";
    } else {
      error.gerencia = "";
    }
    this.setState({
      error
    });
    if (
      this.state.name !== "" &&
      this.state.number !== "" &&
      this.state.longitude !== "" &&
      this.state.latitude !== "" &&
      this.state.gerencia !== 0
    ) {
      const respuestaVehicle = await EndPointCreateUpdateVehiclePoint(
        newResult
      );
      if (respuestaVehicle.data.success) {
        if (newData.length !== 0) {
          const newResult = newData.map(res =>
            Object.assign(res, {
              collectPointId: respuestaVehicle.data.data[0].collectPointId
            })
          );
          const respuestaContainer = await EndPointCreateUpdateCollectPoint(
            newResult
          );
          if (respuestaContainer.data.success) {
            this.props.HandleOpenExitPoint();
            this.props.GetPointAcopio();
            this.props.CreateUpdatePoint();
            this.props.handleClean();
          } else {
            console.log(respuestaContainer);
          }
        } else {
          this.props.HandleOpenExitPoint();
          this.props.GetPointAcopio();
          this.props.CreateUpdatePoint();
          this.props.handleClean();
        }
      } else {
        const result =
          respuestaVehicle.data.message[0].details.field === "pointNumber"
            ? "* El número del punto ya existe"
            : "*" + respuestaVehicle.data.message[0].details.errorMessages[0];

        error.messageSave = result;
        this.setState({
          error
        });
      }
    }
  };
  handleAdd = () => {
    const { container, residue, addData, newData, error } = this.state;
    const getContainerName = this.props.pointAcopioReducer.type_container.filter(
      res => res.containerModelId === container
    );
    const getResidueName = this.props.pointAcopioReducer.type_residue.filter(
      res => res.WasteSubTypeId === residue
    );

    if (container === 0 || residue === 0) {
      error.addData = "*Selecciona ambas opciones";
    } else {
      error.addData = "";
    }
    this.setState({ error });
    if (container !== 0 && residue !== 0) {
      addData.push({
        containerModelId: container,
        containerModelName: getContainerName[0].containerModelName,
        ResidualSubtypeId: residue,
        residualSubtypeName: getResidueName[0].WasteSubTypeName
      });
      newData.push({
        containerModelId: container,
        residualSubTypeId: residue,
        isDeleted: 0
      });
      this.setState({
        addData,
        newData,
        error: { addData: "" },
        container: 0,
        residue: 0
      });
    }
  };
  handleDelete = e => {
    const { addData, newData } = this.state;
    const id = e.currentTarget.id;
    const name = e.currentTarget.name;
    if (name === "non") {
      const newResult = addData.filter((res, index) => {
        return index !== Number(id);
      });
      const newResultAdd = newData.map((res, index) => {
        if (index === Number(id)) {
          res.isDeleted = 1;
          return res;
        }
        return res;
      });

      this.setState({
        addData: newResult,
        newData: newResultAdd
      });
    } else {
      const newResult = addData.filter((res, index) => {
        return res.containerId !== Number(id);
      });
      const newResultAdd = newData.map((res, index) => {
        if (res.containerId === Number(id)) {
          res.isDeleted = 1;
          return res;
        }
        return res;
      });

      this.setState({
        addData: newResult,
        newData: newResultAdd
      });
    }
  };
  render() {
    return (
      <div className={this.props.classes.contentForm}>
        <Grid container>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>ID </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    disabled
                    name="id"
                    value={this.state.id}
                    onChange={this.handleChange}
                    variant="outlined"
                    className={this.props.classes.textFielInput + " blocked-id"}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Nombre </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    variant="outlined"
                    inputProps={{
                      maxLength: 100
                    }}
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.name === undefined
                      ? ""
                      : this.state.error.name}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Número </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="number"
                    value={this.state.number}
                    inputProps={{
                      maxLength: 10
                    }}
                    onChange={this.handleChange}
                    variant="outlined"
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.number === ""
                      ? this.state.error.messageSave !== undefined
                        ? this.state.error.messageSave
                        : null
                      : this.state.error.number}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={4}>
                  <label>Latitude </label>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    name="latitude"
                    value={this.state.latitude}
                    onChange={this.handleChange}
                    variant="outlined"
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.latitude === undefined
                      ? ""
                      : this.state.error.latitude}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: "15px" }}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={4}>
                  <label>Longitude </label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="longitude"
                    value={this.state.longitude}
                    onChange={this.handleChange}
                    variant="outlined"
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.longitude === undefined
                      ? ""
                      : this.state.error.longitude}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Gerencia </label>
                </Grid>
                <Grid item xs={10}>
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="gerencia"
                      value={this.state.gerencia}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.gerenciaReducer.department.map(res => {
                        return (
                          <MenuItem value={res.departmentId}>
                            {res.departmentDescription}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p>
                    {this.state.error.gerencia === undefined
                      ? ""
                      : this.state.error.gerencia}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div>
          <h3 className={this.props.classes.title}>Contenedores</h3>

          <Grid container>
            <Grid item xs={12} className={this.props.classes.detailItem}>
              <Grid container>
                <Grid item xs={1}>
                  <p>#</p>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingRight: "10px"
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="container"
                      value={this.state.container}
                      onChange={this.handleChangeSelect}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.pointAcopioReducer.type_container.map(res => {
                        return (
                          <MenuItem value={res.containerModelId}>
                            {res.containerModelName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingLeft: "10px"
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="residue"
                      value={this.state.residue}
                      onChange={this.handleChangeSelect}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.pointAcopioReducer.type_residue.map(res => {
                        return (
                          <MenuItem value={res.WasteSubTypeId}>
                            {res.WasteSubTypeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <button
                    onClick={this.handleAdd}
                    className={this.props.classes.iconAddContent}
                  >
                    <AddIcon />
                  </button>
                </Grid>
                <p className="err">{this.state.error.addData}</p>
              </Grid>
            </Grid>
            <Grid item xs={12} className={this.props.classes.listContent}>
              <List className={this.props.classes.listContainer}>
                <Grid container>
                  {this.state.addData.map((val, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        className={this.props.classes.detailItem}
                        key={index}
                      >
                        <Grid container>
                          <Grid item xs={1}>
                            <p>
                              {val.containerId === undefined
                                ? "#"
                                : val.containerId}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{
                              paddingRight: "10px"
                            }}
                          >
                            <p>{val.containerModelName}</p>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{
                              paddingLeft: "10px"
                            }}
                          >
                            <p>{val.residualSubtypeName}</p>
                          </Grid>
                          <Grid item xs={1}>
                            <button
                              id={
                                val.containerId === undefined
                                  ? index
                                  : val.containerId
                              }
                              name={
                                val.containerId === undefined ? "non" : "yes"
                              }
                              onClick={this.handleDelete}
                              className={this.props.classes.iconDelete}
                            >
                              <CloseIcon />
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </List>
            </Grid>
          </Grid>
        </div>

        <div className={this.props.classes.contentButton}>
          <button onClick={this.handleCancel}>Cancelar</button>
          <button onClick={this.handleSave}>Guardar</button>
        </div>
        <Modal />
      </div>
    );
  }
}
const mapStateToProps = ({ pointAcopioReducer, gerenciaReducer }) => {
  return { pointAcopioReducer, gerenciaReducer };
};
const mapDispatchToProps = {
  GetPointAcopio,
  GetPointAcopioContainer,
  CreateUpdatePoint,
  HandleOpenPoint,
  HandleOpenExitPoint
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PointAcopio));
