export const OPEN_GERENCIA_MODAL = "open_departamento_modal";
export const CLOSE_GERENCIA_MODAL = "close_departamento_modal";

export const OPEN_VEHICLE_MODAL = "open_vehicle_modal";
export const CLOSE_VEHICLE_MODAL = "close_vehicle_modal";

export const OPEN_USER_MODAL = "open_usuario_modal";
export const CLOSE_USER_MODAL = "close_usuario_modal";

export const OPEN_POINT_MODAL = "open_puntos_modal";
export const CLOSE_POINT_MODAL = "close_puntos_modal";

export const OPEN_ROUTE_MODAL = "open_rutas_modal";
export const CLOSE_ROUTE_MODAL = "close_rutas_modal";

export const OPEN_EXIT_GERENCIA_MODAL = "open_exit_departamento_modal";
export const CLOSE_EXIT_GERENCIA_MODAL = "close_exit_departamento_modal";

export const OPEN_EXIT_VEHICLE_MODAL = "open_exit_vehicle_modal";
export const CLOSE_EXIT_VEHICLE_MODAL = "close_exit_vehicle_modal";

export const OPEN_EXIT_USER_MODAL = "open_exit_usuario_modal";
export const CLOSE_EXIT_USER_MODAL = "close_exit_usuario_modal";

export const OPEN_EXIT_POINT_MODAL = "open_exit_puntos_modal";
export const CLOSE_EXIT_POINT_MODAL = "close_exit_puntos_modal";

export const OPEN_EXIT_ROUTES_MODAL = "open_exit_rutas_modal";
export const CLOSE_EXIT_ROUTES_MODAL = "close_exit_rutas_modal";
