import React, { Component } from "react";
import Media from "react-media";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/TableGeneral";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";

class table extends Component {
  render() {
    return (
      <Hidden smDown>
        <TableContainer className={this.props.classes.contentTable}>
          <Table
            className={this.props.classes.tableGeneral}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={this.props.classes.cellOperativo}>
                <TableCell>#</TableCell>
                <TableCell>Vehículo</TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  <Media query="(min-width: 768px)">
                    {matches => (matches ? "Tipo de Vehículo" : "Tipo")}
                  </Media>
                </TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  <Media query="(min-width: 768px)">
                    {matches => (matches ? "Programados" : "Prog.")}
                  </Media>
                </TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  <Media query="(min-width: 768px)">
                    {matches => (matches ? "Pendientes" : "Pend.")}
                  </Media>
                </TableCell>
                <TableCell
                  className={!this.props.open ? " showCell" : " hideCell"}
                >
                  <Media query="(min-width: 768px)">
                    {matches => (matches ? "Finalizados" : "Final.")}
                  </Media>
                </TableCell>

                <TableCell className="text-overflow">
                  <Media query="(min-width: 768px)">
                    {matches =>
                      matches
                        ? !this.props.open
                          ? "Último Punto"
                          : "Último"
                        : "Últ. Punto"
                    }
                  </Media>
                </TableCell>
                <TableCell className="text-overflow" style={{ width: "200px" }}>
                  <Media query="(min-width: 768px)">
                    {matches =>
                      matches
                        ? !this.props.open
                          ? "Nombre del Último Punto"
                          : "Nombre del Últ. Punto"
                        : "Nombre del Últ. Punto"
                    }
                  </Media>
                </TableCell>
                <TableCell className="text-overflow">
                  <Media query="(min-width: 768px)">
                    {matches =>
                      matches
                        ? !this.props.open
                          ? "Próximo Punto"
                          : "Próximo"
                        : "Próx. Punto"
                    }
                  </Media>
                </TableCell>
                <TableCell className="text-overflow">
                  <Media query="(min-width: 768px)">
                    {matches =>
                      matches
                        ? !this.props.open
                          ? "Nombre del Próximo Punto"
                          : "Nombre del Próx. Punto"
                        : "Nombre del Próx. Punto"
                    }
                  </Media>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.pageOfItems.map((data, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {data.id}
                  </TableCell>
                  <TableCell>{data.plateNumber}</TableCell>
                  <TableCell
                    className={!this.props.open ? "showCell" : "hideCell"}
                  >
                    {data.type}
                  </TableCell>
                  <TableCell
                    className={!this.props.open ? "showCell" : "hideCell"}
                  >
                    {data.totalPoints}
                  </TableCell>
                  <TableCell
                    className={!this.props.open ? "showCell" : "hideCell"}
                  >
                    {data.totalPending}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={!this.props.open ? "showCell" : "hideCell"}
                  >
                    {data.totalDone}
                  </TableCell>
                  <TableCell>
                    <span className="status background-greenDark">
                      {data.lastCollectPoint === null
                        ? "-"
                        : data.lastCollectPoint.pointNumber}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="status" style={{ color: "#000" }}>
                      {data.lastCollectPoint === null
                        ? "-"
                        : data.lastCollectPoint.name}

                      {data.lastCollectPointName}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="status background-yellow-secondary">
                      {data.nextCollectPoint === null
                        ? "-"
                        : data.nextCollectPoint.pointNumber}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="status" style={{ color: "#000" }}>
                      {data.nextCollectPoint === null
                        ? "-"
                        : data.nextCollectPoint.pointNumber}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
    );
  }
}

export default withStyles(style)(table);
