import React, { Component } from "react";
import Chart from "react-google-charts";
class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graficsBarras: [
        [
          "Fecha",
          props.title,
          {
            role: "annotation"
          }
        ]
      ]
    };
  }
  componentWillMount() {
    const { graficsBarras } = this.state;
    this.props.data.forEach((v, i) => {
      graficsBarras.push([v.date, v.value, v.value]);
    });
    this.setState({ graficsBarras });
  }
  render() {
    if (this.props.data.length === 0) return null;

    return (
      <Chart
        width={"100%"}
        height={"250px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={this.state.graficsBarras}
        options={{
          chartArea: { width: "80%" },
          legend: "none",
          hAxis: {
            minValue: 0,
            maxValue: 9,
            textStyle: {
              fontSize: 12,
              color: "#999999",
              bold: true
            }
          },
          colors: ["#3fa9f5"],
          curveType: "function",
          pointSize: 10,
          vAxis: {
            gridlines: {
              count: 1
            },
            viewWindow: {
              min: 0
            }
          }
        }}
        rootProps={{ "data-testid": "1" }}
      />
    );
  }
}
export default Charts;
