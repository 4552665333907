const appStyle = (theme) => ({
  reportContent: {
    width: "1200px",
    paddingTop: "35px",
    paddingBottom: "35px",
    margin: "0 auto",
    "& h4": {
      color: "#353535",
      fontSize: "17px",
      fontWeight: "500",
      margin: "0",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& label": {
      display: "block",
      marginRight: "15px",
      marginTop: "10px",
      color: "#313131",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "19px",
    },
    "& button ": {
      backgroundColor: "#009b67",
      padding: "10px 50px",
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: "500",
      border: "1px solid #009b67",
      float: "left",
    },

    "& button:disabled": {
      backgroundColor: "rgb(245, 245, 245)",
      color: "rgba(0, 0, 0, 0.25)",
      borderColor: "#d9d9d9",
    },
  },

  message: {
    margin: "20px 0",
    fontSize: "15px",
    fontWeight: "500",
    color: "#009b67",
  },
  boxReport: {
    padding: "20px 31px 40px",
    marginBottom: "30px",
    backgroundColor: "#ffffff",
    boxShadow: "2px 2px 8px 0 rgba(140,140,140,0.5)",
    "& h4": {
      marginBottom: "44px",
    },
  },
  formControl: {
    width: "90%",
    backgroundColor: "#fff",
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: "#e2e2e2",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 32px 10.5px 16px",
      minWidth: "174px",
    },
  },
  groupInput: {
    display: "flex",
    "& button.btn-filterVehicule,& button.btn-filterDate": {
      border: "1px solid #d9d9d9",
      padding: "6px 11px",
      textAlign: "left",
      height: "40px",
      width: "170px",
      fontSize: "14px",
      background: "#fff",
      color: "rgba(0, 0, 0, 0.65)",
      borderRadius: "4px",
      boxSizing: "border-box",
    },
  },
});

export default appStyle;
