import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Fade from "@material-ui/core/Fade";
import { DatePicker, TimePicker } from "antd";
import { URLDestinity, URLConductor, URLGetAccept } from "../../other/apis";
import axios from "axios";
import "antd/dist/antd.css";
import moment from "moment";
const today = moment();
const format = "HH:mm";
class ModalSalida extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      destinity: [],
      driver: [],
      detail: {},
      valueSelectVehicle: 0,
      valueSelectDestinity: 0,
      outDate: moment(today).format("YYYY-MM-DD"),
      arrivalDate: moment(today).format("YYYY-MM-DD"),
      returnDate: moment(today).format("YYYY-MM-DD"),
      finDate: moment(today).format("YYYY-MM-DD"),
      outHour: moment(today).format("HH:mm"),
      arrivalHour: moment(today).format("HH:mm"),
      returnHour: moment(today).format("HH:mm"),
      finHour: moment(today).format("HH:mm"),
      valueSelectDriver: 0
    };
  }
  componentWillReceiveProps = async props => {
    if (props.id !== null) {
      await this.setState({ id: props.id });
      await this.getDetail();
    }
  };
  async componentDidMount() {
    await this.getDestinity();
    await this.getDriver();
  }

  getDetail = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDetail = await axios.get(URLGetAccept(this.state.id), {
      headers
    });
    this.setState({
      valueSelectVehicle: respuestDetail.data.data.vehicleId,
      valueSelectDestinity: respuestDetail.data.data.dispositionId,
      valueSelectDriver: respuestDetail.data.data.driverId,
      outDate:
        respuestDetail.data.data.estimatedArrival !== null
          ? moment(respuestDetail.data.data.estimatedArrival)
          : null,
      arrivalDate:
        respuestDetail.data.data.estimatedDeparture !== null
          ? moment(respuestDetail.data.data.estimatedDeparture)
          : null,
      returnDate:
        respuestDetail.data.data.estimatedReturn !== null
          ? moment(respuestDetail.data.data.estimatedReturn)
          : null,
      finDate:
        respuestDetail.data.data.estimatedEnd !== null
          ? moment(respuestDetail.data.data.estimatedEnd)
          : null,
      outHour:
        respuestDetail.data.data.estimatedArrival !== null
          ? moment(respuestDetail.data.data.estimatedArrival).format("HH:mm")
          : null,
      arrivalHour:
        respuestDetail.data.data.estimatedDeparture !== null
          ? moment(respuestDetail.data.data.estimatedDeparture).format("HH:mm")
          : null,
      returnHour:
        respuestDetail.data.data.estimatedReturn !== null
          ? moment(respuestDetail.data.data.estimatedReturn).format("HH:mm")
          : null,
      finHour:
        respuestDetail.data.data.estimatedEnd !== null
          ? moment(respuestDetail.data.data.estimatedEnd).format("HH:mm")
          : null,
      detail: respuestDetail.data.data
    });
  };
  getDestinity = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDestinity = await axios.get(
      URLDestinity(this.props.idMiningSite),
      { headers }
    );
    this.setState({ destinity: respuestDestinity.data.data });
  };
  getDriver = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDriver = await axios.get(
      URLConductor(this.props.idMiningSite),
      { headers }
    );
    this.setState({ driver: respuestDriver.data.result });
  };
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.open}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.open}>
          <div className={this.props.classes.paper}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className={this.props.classes.modalHeader}>
                  <h5
                    className={this.props.classes.modalTitle}
                    id="exampleModalLabel"
                  >
                    Aprobar/Rechazar salida de residuo
                  </h5>
                  <button
                    className="btn-close"
                    onClick={this.props.handleClose}
                  >
                    <span className="closeSpan big"></span>
                  </button>
                </div>
                <div className="salidas-modal" role="document">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div>
                        <Grid container>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <label className="title-section">VEHICULO</label>
                            <FormControl
                              variant="outlined"
                              className={this.props.classes.formControl}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                name="valueSelectVehicle"
                                id="demo-simple-select-outlined"
                                value={this.state.valueSelectVehicle}
                                onChange={this.handleSlectValue}
                                disabled
                              >
                                {this.props.salidasReducer.vehicles.map(
                                  (res, keyV) => {
                                    return (
                                      <MenuItem key={keyV} value={res.id}>
                                        {res.plate_number}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <label className="title-section">DESTINO</label>
                            <FormControl
                              variant="outlined"
                              className={this.props.classes.formControl}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                name="valueSelectDestinity"
                                id="demo-simple-select-outlined"
                                value={this.state.valueSelectDestinity}
                                onChange={this.handleSlectValue}
                                disabled
                              >
                                {this.state.destinity.map((res, keyD) => {
                                  return (
                                    <MenuItem key={keyD} value={res.id}>
                                      {res.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <div>
                              <label className="title-section">
                                SALIDA PROGRAMADA
                              </label>
                              <div
                                style={{ display: "flex", paddingTop: "10px" }}
                              >
                                <DatePicker
                                  value={
                                    this.state.outDate !== null
                                      ? moment(this.state.outDate, "DD/MM/YYYY")
                                      : null
                                  }
                                  onChange={this.handleDateOut}
                                  size="large"
                                  className="input-antd"
                                  placeholder="--/--/--"
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                                <TimePicker
                                  value={
                                    this.state.outHour !== null
                                      ? moment(this.state.outHour, format)
                                      : null
                                  }
                                  onChange={this.handleHourOut}
                                  size="large"
                                  className="height-antd-time"
                                  format={format}
                                  placeholder="--:--:--"
                                  disabled
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <div>
                              <label className="title-section">
                                LLEGADA PROGRAMADA
                              </label>
                              <div
                                style={{ display: "flex", paddingTop: "10px" }}
                              >
                                <DatePicker
                                  value={
                                    this.state.arrivalDate !== null
                                      ? moment(
                                          this.state.arrivalDate,
                                          "DD/MM/YYYY"
                                        )
                                      : null
                                  }
                                  onChange={this.handleDateArrival}
                                  size="large"
                                  className="input-antd"
                                  placeholder="--/--/--"
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                                <TimePicker
                                  value={
                                    this.state.arrivalHour !== null
                                      ? moment(this.state.arrivalHour, format)
                                      : null
                                  }
                                  onChange={this.handleHourArrival}
                                  size="large"
                                  className="height-antd-time"
                                  format={format}
                                  placeholder="--:--:--"
                                  disabled
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <div>
                              <label className="title-section">
                                RETORNO PROGRAMADA
                              </label>
                              <div
                                style={{ display: "flex", paddingTop: "10px" }}
                              >
                                <DatePicker
                                  value={
                                    this.state.returnDate !== null
                                      ? moment(
                                          this.state.returnDate,
                                          "DD/MM/YYYY"
                                        )
                                      : null
                                  }
                                  onChange={this.handleDateReturn}
                                  size="large"
                                  className="header-select input-antd "
                                  placeholder="--/--/--"
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                                <TimePicker
                                  value={
                                    this.state.returnHour !== null
                                      ? moment(this.state.returnHour, format)
                                      : null
                                  }
                                  onChange={this.handleHourReturn}
                                  size="large"
                                  className="header-select height-antd-time"
                                  format={format}
                                  placeholder="--:--:--"
                                  disabled
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <div>
                              <label className="title-section">
                                FIN PROGRAMADA
                              </label>
                              <div
                                style={{ display: "flex", paddingTop: "10px" }}
                              >
                                <DatePicker
                                  value={
                                    this.state.finDate !== null
                                      ? moment(this.state.finDate, "DD/MM/YYYY")
                                      : null
                                  }
                                  onChange={this.handleDateFin}
                                  size="large"
                                  className="header-select input-antd"
                                  placeholder="--/--/--"
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                                <TimePicker
                                  value={
                                    this.state.finHour !== null
                                      ? moment(this.state.finHour, format)
                                      : null
                                  }
                                  onChange={this.handleHourFin}
                                  size="large"
                                  className="header-select height-antd-time"
                                  format={format}
                                  placeholder="--:--:--"
                                  disabled
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            sm={6}
                            className={this.props.classes.containerViewSalida}
                          >
                            <label className="title-section">CONDUCTOR</label>
                            <FormControl
                              variant="outlined"
                              className={this.props.classes.formControl}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                name="valueSelectDriver"
                                id="demo-simple-select-outlined"
                                value={this.state.valueSelectDriver}
                                onChange={this.handleSlectValue}
                                disabled
                              >
                                {this.state.driver.map((res, keyD) => {
                                  return (
                                    <MenuItem key={keyD} value={res.id}>
                                      {res.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginBottom: "15px" }}>
                          <Grid item sm={6} className="detail-modal">
                            <label className="title-section">
                              DETALLE DE RESIDUOS
                            </label>
                            {this.state.detail.subTypes !== undefined
                              ? this.state.detail.subTypes.map(
                                  (resDetail, key) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: "16px"
                                        }}
                                        key={key}
                                      >
                                        <div
                                          className="circle-ingreso"
                                          style={{
                                            backgroundColor: resDetail.color,
                                            marginRight: "24px",
                                            border:
                                              resDetail.name === "Plásticos"
                                                ? "1px solid #6A6A6A"
                                                : "none"
                                          }}
                                        ></div>
                                        <label style={{ marginRight: "16px" }}>
                                          {resDetail.name}
                                        </label>
                                        {resDetail.amount} kg
                                      </div>
                                    );
                                  }
                                )
                              : null}

                            <div>
                              <label
                                style={{
                                  marginRight: "16px"
                                }}
                              >
                                Total:{" "}
                              </label>
                              {this.state.detail !== undefined
                                ? this.state.detail.totalAmount + " kg"
                                : null}
                            </div>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className={
                                this.state.detail !== {}
                                  ? this.state.detail.stateId === 1
                                    ? " background-fucsia"
                                    : this.state.detail.stateId === 7
                                    ? " background-red"
                                    : this.state.detail.stateId === 2
                                    ? " background-yellow-secondary"
                                    : this.state.detail.stateId === 6
                                    ? " background-green-light"
                                    : " background-sky-blue"
                                  : null
                              }
                              style={{
                                display: "inline-block",
                                padding: "5px 15px",
                                marginTop: "auto",
                                marginBottom: "auto",
                                color: "#fff"
                              }}
                            >
                              {this.state.detail !== {}
                                ? this.state.detail.stateName
                                : null}
                            </p>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
const mapStateToProps = ({ salidasReducer }) => {
  return { salidasReducer };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ModalSalida));
