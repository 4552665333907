import equipo1 from "../assets/img/equipo1 big.png";

import {
  CARGANDO_VEHICLES,
  OBTENER_VEHICLES,
  CARGANDO_DATA_TABLE,
  OBTENER_DATA_TABLE,
  UPDATE_DATA_TABLE_GENERAL,
  UPDATE_DATA_TABLE_BY_ID,
  UPDATE_STATUS_TABLE,
  MONITORING_OPERATION,
  CARGANDO_MONITORING,
} from "../Types/Operation";
import {
  EndPointGetVehicle,
  EndPointGetDashWorkVehicle,
  EndPointGetDashWorkVehiclesId,
  EndPointGetDashWork,
  EndPointGetDashWorkProgramated,
} from "../Services/EndPointOperation";
export const GetVehiclesOperation = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_VEHICLES,
  });

  const resultDataVehicle = await EndPointGetVehicle();
  const newresult = resultDataVehicle.data.vehicles.map((dat) => {
    return Object.assign({}, dat, { url: equipo1 });
  });
  dispatch({
    type: OBTENER_VEHICLES,
    payload: newresult,
  });
};
export const UpdateStatusTable = (value) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_STATUS_TABLE,
    payload: value,
  });
};
export const GetVehicleDataTable = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_DATA_TABLE,
  });

  const resultDataVehicleTable = await EndPointGetDashWorkVehicle();
  const newresultDataTable = resultDataVehicleTable.data.vehicles.map(
    (dat, key) => {
      return Object.assign({}, dat, { idPosition: key + 1 });
    }
  );

  const coordinates = newresultDataTable.map((coords) => {
    return {
      coordPointsLast: {
        id: coords.idPosition,

        status: "lastPoint",
        coord:
          coords.lastCollectPoint === null
            ? coords.lastCollectPoint
            : {
                lat: coords.lastCollectPoint.latitude,
                lng: coords.lastCollectPoint.longitude,
              },
      },
      coordPointsNext: {
        id: coords.idPosition,

        status: "nextPoint",
        coord:
          coords.nextCollectPoint === null
            ? coords.nextCollectPoint
            : {
                lat: coords.nextCollectPoint.latitude,
                lng: coords.nextCollectPoint.longitude,
              },
      },
    };
  });
  const filterCoordinates = coordinates
    .map((filter) => Object.values(filter))
    .reduceRight((a, b) => {
      return a.concat(b);
    }, [])
    .filter((result) => result.coord !== null);

  const data = {
    dataTable: newresultDataTable,
    coordMap: filterCoordinates,
  };
  dispatch({
    type: OBTENER_DATA_TABLE,
    payload: data,
  });
};

export const UpdateVehicleTable = () => async (dispatch, getState) => {
  const resultDataVehicleTable = await EndPointGetDashWorkVehicle();
  const newresultDataTable = resultDataVehicleTable.data.vehicles.map(
    (dat, key) => {
      return Object.assign({}, dat, { idPosition: key + 1 });
    }
  );

  const coordinates = newresultDataTable.map((coords) => {
    return {
      coordPointsLast: {
        id: coords.id,

        status: "lastPoint",
        coord:
          coords.lastCollectPoint === null
            ? coords.lastCollectPoint
            : {
                lat: coords.lastCollectPoint.latitude,
                lng: coords.lastCollectPoint.longitude,
              },
      },
      coordPointsNext: {
        id: coords.id,

        status: "nextPoint",
        coord:
          coords.nextCollectPoint === null
            ? coords.nextCollectPoint
            : {
                lat: coords.nextCollectPoint.latitude,
                lng: coords.nextCollectPoint.longitude,
              },
      },
    };
  });
  const filterCoordinates = coordinates
    .map((filter) => Object.values(filter))
    .reduceRight((a, b) => {
      return a.concat(b);
    }, [])
    .filter((result) => result.coord !== null);

  const data = {
    dataTable: newresultDataTable,
    coordMap: filterCoordinates,
  };

  dispatch({
    type: UPDATE_DATA_TABLE_GENERAL,
    payload: data,
  });
};

export const GetVehicleById = (value) => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_DATA_TABLE,
  });

  const resultDataVehicleTable = await EndPointGetDashWorkVehiclesId(value.id);
  const dataValue = [
    {
      collectPointNumber: "-",
      collectPointLatitude: 0,
      collectPointLongitude: 0,
      collectPointName: "-",
      departmentName: "-",
      totalWeight: "-",
      totalVolume: "-",
      completed: "-",
      segregation: "-",
      estatus: "-",
    },
  ];
  const newresultDataTable =
    resultDataVehicleTable.data.collectPoints.length === 0
      ? dataValue
      : resultDataVehicleTable.data.collectPoints.map((dat, index) => {
          dat.idPosition = index + 1;
          dat.idVehicles = value.id;
          dat.complete =
            dat.completed === undefined
              ? 0 + "/" + dat.totalSegregation
              : dat.completed + "/" + dat.totalSegregation;
          dat.segregatio = dat.segregation + "/" + dat.totalSegregation;
          dat.estatus =
            dat.completed === 0
              ? "Pendiente"
              : dat.completed > 0 && dat.completed < dat.totalSegregation
              ? "En proceso"
              : "Finalizado";

          return dat;
        });

  const coordinates = newresultDataTable.map((dat) => {
    if (
      dat.collectPointLatitude !== null &&
      dat.collectPointLongitude !== null &&
      dat.collectPointLatitude !== 0 &&
      dat.collectPointLongitude !== 0
    ) {
      return {
        id: dat.collectPointNumber,
        name: dat.collectPointName,
        status: dat.hasIncidents === 0 ? dat.estatus : "Incidente",

        coord: {
          lat: dat.collectPointLatitude,
          lng: dat.collectPointLongitude,
        },
      };
    }
    return dat;
  });

  const data = {
    dataTable: newresultDataTable,
    coordMap: coordinates,
    nameVehicle: value.type + " " + value.name,
    activeIndex: value.activeIndex,
    status: "byId",
  };

  dispatch({
    type: UPDATE_DATA_TABLE_BY_ID,
    payload: data,
  });
};

export const GetMonitoringPoints = () => async (dispatch, getState) => {
  dispatch({
    type: CARGANDO_MONITORING,
  });

  const resultDash = await EndPointGetDashWork();
  const data = {
    total: resultDash.data.total || 0,
    finished: resultDash.data.finished || 0,
    pending: resultDash.data.pending || 0,
    noProgramated: resultDash.data.noProgramated || 0,
  };
  dispatch({
    type: MONITORING_OPERATION,
    payload: data,
  });
};

export const GetVehicleNoProgramated = () => async (dispatch, getState) => {
  const resultDataVehicleTable = await EndPointGetDashWorkProgramated();

  const dataValue = [
    {
      collectPointNumber: "-",
      collectPointLatitude: 0,
      collectPointLongitude: 0,
      collectPointName: "-",
      departmentName: "-",
      totalWeight: "-",
      totalVolume: "-",
      completed: "-",
      segregation: "-",
      estatus: "-",
      plateNumber: "-",
    },
  ];
  const newresultDataTable =
    resultDataVehicleTable.data.collectPoints.length === 0
      ? dataValue
      : resultDataVehicleTable.data.collectPoints.map((dat, index) => {
          dat.idPosition = index + 1;
          dat.complete =
            dat.completed === undefined
              ? 0 + "/" + dat.totalSegregation
              : dat.completed + "/" + dat.totalSegregation;
          dat.segregatio = dat.segregation + "/" + dat.totalSegregation;
          dat.estatus =
            dat.completed === 0
              ? "Pendiente"
              : dat.completed > 0 && dat.completed < dat.totalSegregation
              ? "En proceso"
              : "Finalizado";
          return dat;
        });

  const coordinates = newresultDataTable.map((dat) => {
    return {
      id: dat.collectPointNumber,
      name: dat.collectPointName,
      status: dat.hasIncidents === 0 ? dat.estatus : "Incidente",

      coord: {
        lat: dat.collectPointLatitude,
        lng: dat.collectPointLongitude,
      },
    };
  });

  const data = {
    dataTable: newresultDataTable,
    coordMap: coordinates,
    status: "programated",
  };

  dispatch({
    type: UPDATE_DATA_TABLE_BY_ID,
    payload: data,
  });
};
