import {
  CREATE_ERROR_POINTS,
  CREATE_ERROR_OPERATOR,
  CREATE_ERROR_VEHICLES
} from "../Types/Error";

export const ErrorUser = val => async (dispatch, getState) => {
  dispatch({
    type: CREATE_ERROR_OPERATOR,
    payload: val
  });
};

export const ErrorVehicle = val => async (dispatch, getState) => {
  dispatch({
    type: CREATE_ERROR_VEHICLES,
    payload: val
  });
};

export const ErrorPoint = val => async (dispatch, getState) => {
  dispatch({
    type: CREATE_ERROR_POINTS,
    payload: val
  });
};
