import React, { Component } from "react";
import {
  URLDashIncidentDoucment
} from "../../other/apis";
import axios from "axios";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";

import Fade from "@material-ui/core/Fade";
class Modals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      dataFoto: []
    };
  }

  componentWillReceiveProps = async nextProps => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };

    if (nextProps.datamodal.length === 0) {
      this.setState({ dataFoto: [] });
    } else {
      await axios
        .get(URLDashIncidentDoucment(nextProps.datamodal.incidentId), {
          headers
        })
        .then(res => {
          this.setState({ dataFoto: res.data.result });
        });
    }
  };
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.openModal3}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.openModal3}>
          <div className={this.props.classes.paper}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className={this.props.classes.modalHeader}>
                  <h5
                    className={this.props.classes.modalTitle}
                    id="exampleModalLabel"
                  >
                    <span>
                      {this.props.pointNumber + ", " + this.props.namePoint}
                    </span>
                  </h5>
                  <button
                    onClick={this.props.handleClose}
                    className="btn-close"
                  >
                    <span className="closeSpan big"></span>
                  </button>
                </div>
                <div className={this.props.classes.modalBody}>
                  <Grid container>
                    <Grid item sm={6}>
                      <div>
                        <label>Tipo de Incidente:</label>
                        <p>{this.props.datamodal.description}</p>
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <div>
                        <label>Motivo:</label>
                        <p>
                          {this.props.datamodal.incidentCommentDescription ===
                          undefined
                            ? "-"
                            : this.props.datamodal.incidentCommentDescription}
                        </p>
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <div>
                        <label>Usuario:</label>
                        <p>{this.props.datamodal.name}</p>
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <div>
                        <label>Fecha:</label>
                        <p>
                          {moment(this.props.datamodal.saved).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </div>
                    </Grid>
                    <Grid item sm={12}>
                      <hr />
                    </Grid>
                    <Grid item sm={12}>
                      <label>Fotos:</label>
                      <Grid
                        container
                        className={this.props.classes.contentFotos}
                      >
                        {this.state.dataFoto.length &&
                          this.state.dataFoto.map(res => (
                            <Grid item sm={3}>
                              <div className={this.props.classes.contentImage}>
                                <a
                                  href={res.IncidentDocumentUrl}
                                  target="__blank"
                                >
                                  <img
                                    alt="img-doc"
                                    src={res.IncidentDocumentUrl}
                                    className={this.props.classes.img}
                                  />
                                </a>
                              </div>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
export default withStyles(style)(Modals);
