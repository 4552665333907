import { combineReducers } from "redux";

import loginReducer from "./LoginReducer";
import operationReducer from "./OperationReducer";
import dashboardReducer from "./DashboardReducer";
import ingresosReducer from "./IngresosReducer";
import salidasReducer from "./SalidasReducer";
import stockReducer from "./StockReducer";
import gerenciaReducer from "./GerenciaReducer";
import modalReducer from "./ModalReducer";
import operatorReducer from "./OperatorReducer";
import vehicleReducer from "./VehicleReducer";
import pointAcopioReducer from "./PointAcopioReducer";
import routeReducer from "./RutasReducer";
import errorReducer from "./ErrorReducer";
const appReducer = combineReducers({
  loginReducer,
  modalReducer,
  gerenciaReducer,
  operationReducer,
  operatorReducer,
  dashboardReducer,
  errorReducer,
  ingresosReducer,
  salidasReducer,
  routeReducer,
  stockReducer,
  vehicleReducer,
  pointAcopioReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
