import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Modal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";

import Fade from "@material-ui/core/Fade";
import {
  URLDestinity,
  URLConductor,
  URLPutAccept,
  URLGetAccept
} from "../../other/apis";
import axios from "axios";
import "antd/dist/antd.css";
import moment from "moment";
const today = moment();
class Documents extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      destinity: [],
      driver: [],
      detail: {},
      valueSelectVehicle: 0,
      valueSelectDestinity: 0,
      outDate: moment(today).format("YYYY-MM-DD"),
      arrivalDate: moment(today).format("YYYY-MM-DD"),
      returnDate: moment(today).format("YYYY-MM-DD"),
      finDate: moment(today).format("YYYY-MM-DD"),
      outHour: moment(today).format("HH:mm"),
      arrivalHour: moment(today).format("HH:mm"),
      returnHour: moment(today).format("HH:mm"),
      finHour: moment(today).format("HH:mm"),
      valueSelectDriver: 0
    };
  }
  componentWillReceiveProps = async nextProps => {
    if (nextProps.id !== null) {
      await this.setState({ id: nextProps.id });
      await this.getDetail();
    }
  };
  async componentDidMount() {
    await this.getDestinity();
    await this.getDriver();
  }

  getDetail = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDetail = await axios.get(URLGetAccept(this.state.id), {
      headers
    });
    this.setState({ detail: respuestDetail.data.data });
  };
  getDestinity = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDestinity = await axios.get(
      URLDestinity(this.props.idMiningSite),
      { headers }
    );
    this.setState({ destinity: respuestDestinity.data.data });
  };
  getDriver = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token")
    };
    const respuestDriver = await axios.get(
      URLConductor(this.props.idMiningSite),
      { headers }
    );
    this.setState({ driver: respuestDriver.data.result });
  };
  handleSlectValue = e => {
    const name = e.currentTarget.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  };
  handleDateOut = e => {
    this.setState({ outDate: moment(e).format("YYYY-MM-DD") });
  };
  handleDateArrival = e => {
    this.setState({ arrivalDate: moment(e).format("YYYY-MM-DD") });
  };
  handleDateReturn = e => {
    this.setState({ returnDate: moment(e).format("YYYY-MM-DD") });
  };
  handleDateFin = e => {
    this.setState({ finDate: moment(e).format("YYYY-MM-DD") });
  };
  handleHourOut = e => {
    this.setState({ outHour: moment(e).format("HH:MM") });
  };
  handleHourArrival = e => {
    this.setState({ arrivalHour: moment(e).format("HH:MM") });
  };
  handleHourReturn = e => {
    this.setState({ returnHour: moment(e).format("HH:MM") });
  };
  handleHourFin = e => {
    this.setState({ finHour: moment(e).format("HH:MM") });
  };

  showDeleteConfirm = () => {
    Modal.info({
      title: "¿Confirma rechazo del traslado?",
      content:
        "Esta solicitud será eliminada de la programación de salidas de residuos",
      okText: "Rechazar",
      async onOk() {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token")
        };
        await axios.put(URLPutAccept, {}, { headers });
      }
    });
  };

  showPropsConfirm = data => {
    Modal.info({
      title: "¿Confirma la aprobación?",
      content: "Este traslado se asignará al conductor y vehiculo",
      okText: "Confirmar",
      async onOk() {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token")
        };
        await axios.put(URLPutAccept, data, { headers });
      }
    });
  };

  handleAccept = async () => {
    let data = {
      stateId: 2,
      vehicleId: Number(this.state.valueSelectVehicle),
      mineDispositionId: Number(this.state.valueSelectDestinity),
      estimatedDepartureFromMineTime: moment(
        this.state.outDate + " " + this.state.outHour
      )
        .utc()
        .valueOf(),
      estimatedArrivalDestinationTime: moment(
        this.state.arrivalDate + " " + this.state.arrivalHour
      )
        .utc()
        .valueOf(),
      outputId: Number(this.props.id),
      driverId: Number(this.state.valueSelectDriver)
    };
    if (
      this.state.valueSelectVehicle !== 0 &&
      this.state.valueSelectDriver !== 0 &&
      this.state.valueSelectDestinity !== 0
    ) {
      this.showPropsConfirm(data);
    } else {
      alert("Completa todos los campos");
    }
  };

  handleDenied = () => {
    // this.props.onOk();
    this.showDeleteConfirm();
  };
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.open}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.open}>
          <div className={this.props.classes.paper}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className={this.props.classes.modalHeader}>
                  <h5
                    className={this.props.classes.modalTitle}
                    id="exampleModalLabel"
                  >
                    Ver Documentos
                  </h5>
                  <button
                    className="btn-close"
                    onClick={this.props.handleClose}
                  >
                    <span className="closeSpan big"></span>
                  </button>
                </div>
                <div className="modal-body table-responsive">
                  <div className="salidas-modal" role="document">
                    <div className="modal-content">
                      <div className="modal-body">
                        <Grid container>
                          {this.props.data.length !== 0 ? (
                            this.props.data.map(res => {
                              return (
                                <Grid
                                  item
                                  sm={4}
                                  style={{
                                    padding: "15px",
                                    position: "relative"
                                  }}
                                >
                                  <h3 className="title-document">
                                    {res.documentName}
                                  </h3>
                                  <img
                                    style={{ width: "100%" }}
                                    src={res.documentUrl}
                                    alt="img-doc"
                                  />
                                  <a
                                    className="link-document"
                                    href={res.documentUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="hide">Descargar</span>
                                  </a>
                                </Grid>
                              );
                            })
                          ) : (
                            <p className="message-none">
                              No hay documentos ingresados
                            </p>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withStyles(style)(Documents);
