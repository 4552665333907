import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  EndPointReportDaily,
  EndPointReportRange,
  EndPointReportIncident,
  EndPointReportRoadMap,
  EndPointReportWarehouse
} from "../Services/EndPointReport";
import { DatePicker } from "antd";
import download from "downloadjs";
import { withStyles } from "@material-ui/core/styles";

import style from "../assets/jss/disal-main/Layouts/Report";
import Grid from "@material-ui/core/Grid";
import "antd/dist/antd.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import * as vehicleAction from "../Actions/VehicleAction";
import * as ingresosAction from "../Actions/IngresosAcions";


const { GetVehiclesAdmin } = vehicleAction;
const { GetPatios} = ingresosAction;
const dayofWeek = [
  { id: 0, description: `Todos los dias` },
  { id: 1, description: `Lunes` },
  { id: 2, description: `Martes` },
  { id: 3, description: `Miercoles` },
  { id: 4, description: `Jueves` },
  { id: 5, description: `Viernes` },
  { id: 6, description: `Sabado` },
  { id: 7, description: `Domingo` },
];



const today = moment();
class Report extends Component {
  constructor() {
    super();
    this.state = {
      dataDates: [
        {
          id: 0,
          name: "Todos los días",
        },
        {
          id: 1,
          name: "Lunes",
        },
        {
          id: 2,
          name: "Martes",
        },
        {
          id: 3,
          name: "Miércoles",
        },
        {
          id: 4,
          name: "Jueves",
        },
        {
          id: 5,
          name: "Viernes",
        },
        {
          id: 6,
          name: "Sábado",
        },
        {
          id: 7,
          name: "Domingo",
        },
      ],
      dateInicial: moment().format("YYYY-MM-DD"),
      dateInicialDayli: moment().format("YYYY-MM-DD"),
      dateFinalDaily: moment().format("YYYY-MM-DD"),
      dateFinal: moment().format("YYYY-MM-DD"),
      dateInicialReport: moment(),
      dateFinalReport: moment(),
      messageText: false,
      valueSelectDay: -1,
      valueSelectPlateNumber: -1,
      valueSelectPatios: -1,
    };
  }

  componentDidMount = async () => {
    if (!this.props.vehicleReducer.vehicle.length) {
      await this.props.GetVehiclesAdmin();
    }

    const { ingresosReducer } = this.props;

    if (!ingresosReducer.patios.length) {
      await this.props.GetPatios();
    }

  };

  handleChange = (val) => {
    this.setState({ messageText: !val });
  };
  handleChangeInputFrom = (e) => {
    this.setState({
      dateInicial: e,
    });
  };
  handleChangeInputFromD = (e) => {
    this.setState({
      dateInicialDayli: e,
    });
  };
  handleChangeInputToD = (e) => {
    this.setState({
      dateFinalDaily: e
    })
  }

  handleChangeInputTo = (e) => {
    this.setState({
      dateFinal: e,
    });
  };
  handleChangeInputFromR = (e) => {
    this.setState({
      dateInicialReport: e,
    });
  };
  handleChangeInputToR = (e) => {
    this.setState({
      dateFinalReport: e,
    });
  };
  handleDownloadDaily = async () => {
    const { dateInicialDayli, dateFinalDaily } = this.state;

    const newValueI = moment(dateInicialDayli, "YYYY-MM-DD").startOf('day').utc();
    const toDateDaily = moment(dateFinalDaily, "YYYY-MM-DD").startOf('day').utc();
    let response = null; 
    response = await EndPointReportDaily(newValueI.valueOf(), toDateDaily.valueOf());

    if (response.status > 200 && response.status < 400) {
      const content = response.headers["content-type"];
      download(response.data, `liquidacion-desde-${dateInicialDayli}-hasta-${dateFinalDaily}.xlsx`, content);
      this.setState({ messageText: !this.state.messageText });
    } else {
      alert("No se pudo descargar el archivo");
    }
  };
  handleDownloadRange = async () => {
    const { dateInicial, dateFinal } = this.state;

    const newValueI = moment(dateInicial, "YYYY-MM-DD").utc();
    const newValueF = moment(dateFinal, "YYYY-MM-DD").utc();
    const response = await EndPointReportRange(
      newValueI.valueOf(),
      newValueF.valueOf()
    );

    if (response.status > 200 && response.status < 400) {
      const content = response.headers["content-type"];
      download(response.data, `liquidacion-${dateInicial}.xlsx`, content);
      this.setState({ messageText: !this.state.messageText });
    } else {
      alert("No se pudo descargar el archivo");
    }
  };
  handleDownloadIncidentReport = async () => {
    const { dateInicialReport, dateFinalReport } = this.state;
    const minigsite = localStorage.getItem("miningSiteId");

    const newValueI = dateInicialReport.utc().valueOf();
    const newValueF = dateFinalReport.utc().valueOf();
    const response = await EndPointReportIncident(
      newValueI,
      newValueF,
      minigsite
    );

    const dateI = dateInicialReport.format("YYYY-MM-DD");
    const datef = dateFinalReport.format("YYYY-MM-DD");
    if (response.status >= 200 && response.status < 400) {
      const content = response.headers["content-type"];
      download(response.data, `Reporte-entre-${dateI}-${datef}.xlsx`, content);
      this.setState({ messageText: !this.state.messageText });
    } else {
      alert("No se pudo descargar el archivo");
    }
  };

  handleDownloadRoadmapReport = async (e) => {
    const vehicleId = this.state.valueSelectPlateNumber;
    const day = this.state.valueSelectDay;
    const response = await EndPointReportRoadMap(vehicleId, day);
    if (response.status > 200 && response.status < 400) {
      const content = response.headers["content-type"];
      download(response.data, `Reporte-rutas.xlsx`, content);
      this.setState({ messageText: !this.state.messageText });
    } else {
      alert("No se pudo descargar el archivo");
    }
  };

  handleSelectDay = async (e) => {
    await this.setState({ valueSelectDay: e.target.value });
  };

  handleSelectPlateNumber = async (e) => {
    await this.setState({ valueSelectPlateNumber: e.target.value });
  };

  handleSelectPatios = async (e) => {
    await this.setState({ valueSelectPatios: e.target.value });
  };

  handleDownloadWarehouseReport = async () => {
    const { dateInicialReport, dateFinalReport } = this.state;
    const minigsite = localStorage.getItem("miningSiteId");
    const warehouseId = this.state.valueSelectPatios;
    const newValueI = dateInicialReport.utc().valueOf();
    const newValueF = dateFinalReport.utc().valueOf();
    const response = await EndPointReportWarehouse(
      newValueI,
      newValueF,
      minigsite,
      warehouseId
    );

    const dateI = dateInicialReport.format("YYYY-MM-DD");
    const datef = dateFinalReport.format("YYYY-MM-DD");
    if (response.status >= 200 && response.status < 400) {
      const content = response.headers["content-type"];
      download(response.data, `Reporte-entre-${dateI}-${datef}.xlsx`, content);
      this.setState({ messageText: !this.state.messageText });
    } else {
      alert("No se pudo descargar el archivo");
    }
  };

  render() {
    return (
      <div className={this.props.classes.reportContent}>
        <section className="report">
          <Grid container>
            <Grid item md={12} className={this.props.classes.boxReport}>
              <h4>Reporte diario de turno</h4>
              <Grid container>
                <Grid
                  item
                  sm={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item sm={3}>
                    <div className={this.props.classes.groupInput}>
                      <label htmlFor="report2">Desde:</label>
                      <DatePicker
                        defaultValue={moment(today, "DD/MM/YYYY").subtract(1, 'days')}
                        disabledDate={(current) =>
                          current && current >= moment().subtract(1, 'days').endOf("day")
                        }
                        onChange={this.handleChangeInputFromD}
                        size="large"
                        className="header-select"
                        placeholder="Desde"
                        format="DD/MM/YYYY"
                        style={{ width: 150, marginRight: 40 }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={3}>
                    <div className={this.props.classes.groupInput}>
                      <label htmlFor="report2">Hasta:</label>
                      <DatePicker
                        defaultValue={moment(today, "DD/MM/YYYY")}
                        disabledDate={(current) =>
                          current && 
                          (
                            current < moment(this.state.dateInicialDayli).subtract(1, 'days').endOf("day")
                            || current >= moment().add(1, 'days').startOf("day")
                          )
                        }
                        onChange={this.handleChangeInputToD}
                        size="large"
                        className="header-select"
                        placeholder="Hasta"
                        format="DD/MM/YYYY"
                        style={{ width: 150, marginRight: 40 }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={3}>
                  <button type="button" onClick={this.handleDownloadDaily}>
                    Descargar
                  </button>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} className={this.props.classes.boxReport}>
              <h4>Reporte acumulado por punto generador</h4>
              <Grid container>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={this.props.classes.groupInput}>
                    <label htmlFor="report1">Desde:</label>
                    <DatePicker
                      defaultValue={moment(today, "DD/MM/YYYY")}
                      disabledDate={(current) =>
                        current && current >= moment().endOf("day")
                      }
                      onChange={this.handleChangeInputFrom}
                      size="large"
                      className="header-select"
                      placeholder="Desde"
                      format="DD/MM/YYYY"
                      style={{ width: 150, marginRight: 40 }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={this.props.classes.groupInput}>
                    <label htmlFor="report2">Hasta:</label>
                    <DatePicker
                      disabledDate={(current) =>
                        current && current >= moment().endOf("day")
                      }
                      defaultValue={moment(today, "DD/MM/YYYY")}
                      onChange={this.handleChangeInputTo}
                      size="large"
                      className="header-select"
                      placeholder="Desde"
                      format="DD/MM/YYYY"
                      style={{ width: 150, marginRight: 40 }}
                    />
                  </div>
                </Grid>
                <Grid item sm={3}>
                  <button type="button" onClick={this.handleDownloadRange}>
                    Descargar
                  </button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} className={this.props.classes.boxReport}>
              <h4>Reporte de incidencias</h4>
              <Grid container>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={this.props.classes.groupInput}>
                    <label htmlFor="report1">Desde:</label>

                    <DatePicker
                      defaultValue={moment(today, "DD/MM/YYYY")}
                      onChange={this.handleChangeInputFromR}
                      size="large"
                      className="header-select"
                      placeholder="Desde"
                      format="DD/MM/YYYY"
                      style={{ width: 150, marginRight: 40 }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={this.props.classes.groupInput}>
                    <label htmlFor="report2">Hasta:</label>
                    <DatePicker
                      defaultValue={moment(today, "DD/MM/YYYY")}
                      onChange={this.handleChangeInputToR}
                      size="large"
                      className="header-select"
                      placeholder="Desde"
                      format="DD/MM/YYYY"
                      style={{ width: 150, marginRight: 40 }}
                    />
                  </div>
                </Grid>
                <Grid item sm={3}>
                  <button
                    type="button"
                    onClick={this.handleDownloadIncidentReport}
                  >
                    Descargar
                  </button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} className={this.props.classes.boxReport}>
              <h4>Reporte de rutas</h4>
              <Grid container>
                <Grid
                  item
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={this.props.classes.groupInput}>
                    <label htmlFor="report1">Numero de placa:</label>
                    <FormControl
                      variant="outlined"
                      className={this.props.classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={this.handleSelectPlateNumber}
                        value={this.state.valueSelectPlateNumber}
                        style={{
                          border: "1px solid #d9d9d9",
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.65)",
                        }}
                      >
                        <MenuItem value={-1}>Seleccione una opción</MenuItem>
                        {this.props.vehicleReducer.vehicle.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.vehicleId}>
                              {item.plateNumber}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={this.props.classes.groupInput}>
                    <label htmlFor="report1">Dia:</label>
                    <FormControl
                      variant="outlined"
                      className={this.props.classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        style={{
                          border: "1px solid #d9d9d9",
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.65)",
                        }}
                        value={this.state.valueSelectDay}
                        onChange={this.handleSelectDay}
                      >
                        <MenuItem value={-1}>Seleccione una opción</MenuItem>
                        {dayofWeek.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.id}>
                              {item.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item sm={3}>
                  <button
                    type="button"
                    style={{
                      backgroundColor:
                        this.state.valueSelectDay === -1 ||
                        this.state.valueSelectPlateNumber === -1
                          ? "#f5f5f5"
                          : "",
                    }}
                    onClick={this.handleDownloadRoadmapReport}
                    disabled={
                      this.state.valueSelectDay === -1 ||
                      this.state.valueSelectPlateNumber === -1
                        ? true
                        : false
                    }
                  >
                    Descargar
                  </button>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item md={12} className={this.props.classes.boxReport}>
              <h4>Reporte de patios temporales</h4>
              <Grid container>
                <Grid container item sm={6}>
                  <Grid item sm={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className={this.props.classes.groupInput}>
                      <label htmlFor="report1">Desde:</label>

                      <DatePicker
                        defaultValue={moment(today, "DD/MM/YYYY")}
                        onChange={this.handleChangeInputFromR}
                        size="large"
                        className="header-select"
                        placeholder="Desde"
                        format="DD/MM/YYYY"
                        style={{ width: 150, marginRight: 40 }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className={this.props.classes.groupInput}>
                      <label htmlFor="report2">Hasta:</label>
                      <DatePicker
                        defaultValue={moment(today, "DD/MM/YYYY")}
                        onChange={this.handleChangeInputToR}
                        size="large"
                        className="header-select"
                        placeholder="Desde"
                        format="DD/MM/YYYY"
                        style={{ width: 150, marginRight: 40 }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container item sm={6}>
                  <Grid item sm={8} 
                  style={{ display: "flex", alignItems: "center" }}>
                    <div className={this.props.classes.groupInput}>
                      <label htmlFor="report1">Patios:</label>
                      <FormControl
                      variant="outlined"
                      className={this.props.classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        style={{
                          border: "1px solid #d9d9d9",
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.65)",
                        }}
                        value={this.state.valueSelectPatios}
                        onChange={this.handleSelectPatios}
                      >
                        <MenuItem value={-1}>Seleccione una opción</MenuItem>
                        {this.props.ingresosReducer.patios.map((res, key) => {
                          return (
                            <MenuItem key={key} value={res.id}>
                              {res.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    </div>
                  </Grid>
                  <Grid item sm={4}>
                    <button
                      type="button"
                      onClick={this.handleDownloadWarehouseReport}
                      style={{
                        backgroundColor:
                          this.state.valueSelectPatios === -1
                            ? "#f5f5f5"
                            : "",
                      }}
                      disabled={
                        this.state.valueSelectPatios === -1
                          ? true
                          : false
                      }
                    >
                      Descargar
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
            <p className={this.props.classes.message}>
              {!this.state.messageText
                ? ""
                : "El reporte se ha descargado con éxito"}
            </p>
          </Grid>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ vehicleReducer, ingresosReducer }) => {
  return { vehicleReducer, ingresosReducer };
};
const mapDispatchToProps = {
  GetVehiclesAdmin,
  GetPatios
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Report));
