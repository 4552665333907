import React from "react";
import Grid from "@material-ui/core/Grid";

const CardStock = props => {
  return (
    <Grid item sm={6} className="card-material">
      <div style={{ background: "#fff" }}>
        <div className="header-card-stock">
          <div
            className="circle-stock"
            style={{
              backgroundColor: props.color,
              border: props.name === "Plásticos" ? "1px solid #202121" : "none"
            }}
          ></div>
          <p className="mb-0">{props.name}</p>
        </div>
        <hr className="my-0" />
        <div className="content-stock">{props.peso}</div>
      </div>
    </Grid>
  );
};

export default CardStock;
