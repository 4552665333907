import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/CardTable";
import Grid from "@material-ui/core/Grid";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import Modal from "../Modal/Modal";
import ModalIncidencias from "../Modal/ModalIncident";
import ModalQR from "../Modal/ModalQR";
import axios from "axios";
import { URLDashIncident } from "../../other/apis";

import {
  EndpointDashWorkCollect,
} from "../../Services/EndPointOperation";
class CardTableOperat extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      name: "",
      number: "",
      dataModal: [],
      dataIncident: [],
      openModal1: false,
      openModal2: false,
      openModal3: false,
    };
  }
  handleOpenModal = async (e, idV, idP) => {
    const idPoint = e.target.dataset.id;
    const namePoint = e.target.dataset.namepoint;
    await this.setState({
      openModal1: true,
      id: idPoint,
      name: namePoint,
    });
    const valueProgram = this.props.status === "programated" ? 0 : 1;

    if (idV === 0 && idP === 0) {
      await this.setState({ dataModal: [] });
    } else {
      const result = await EndpointDashWorkCollect(idV, idP, valueProgram);
      const reestructure = result.data.containers.map((res) => {
        let tempData = {
          containerId: res.containerId,
          containerModel: res.containerModel,
          residualSubtype: res.residualSubtype,
          weight: res.weight,
          volume: res.volume,
          hasIncident: res.hasIncident ? "Mal Segregado" : "Correcta",
          image: res.photo,
        };
        return tempData;
      });

      await this.setState({ dataModal: reestructure });
    }
  };
  handleClickModalIncident = async (e, idP) => {
    const idPoint = e.target.dataset.id;
    const namePoint = e.target.dataset.namepoint;
    const numberPoint = e.target.dataset.numberpoint;

    await this.setState({
      openModal3: true,
      id: idPoint,
      name: namePoint,
      number: numberPoint,
    });
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    if (idP === 0) {
      await this.setState({ dataIncident: [] });
    } else {
      await axios.get(URLDashIncident(idPoint), { headers }).then((res) => {
        this.setState({
          dataIncident: res.data.data.length === 0 ? [] : res.data.data[0],
        });
      });
    }
  };
  handleClose = () => {
    this.setState({
      openModal1: false,
    });
  };
  handleOpenModal2 = async (e) => {
    const idPoint = e.target.dataset.id;
    const namePoint = e.target.dataset.namepoint;
    await this.setState({
      openModal2: true,
      id: idPoint,
      name: namePoint,
    });
  };
  handleCloseModal2 = () => {
    this.setState({
      openModal2: false,
    });
  };
  handleCloseModal3 = () => {
    this.setState({
      openModal3: false,
    });
  };
  render() {
    return (
      <Grid container>
        {this.props.pageOfItems.map((data, index) => {
          let idP = data.collectPointId;
          let idV = data.idVehicles;

          return (
            <Grid item xs={12} className={this.props.classes.contentCard}>
              <h3 className={this.props.classes.title}>
                {data.collectPointName}
              </h3>
              <Grid container>
                <Grid item xs={12} className={this.props.classes.content1}>
                  <p>Gerencia</p>
                  <p>{data.departmentName}</p>
                </Grid>
                <Grid item xs={12} className={this.props.classes.content2}>
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      style={{ borderRight: "1px solid #E5E5E5" }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className={this.props.classes.opcion1}
                        >
                          <Grid container>
                            <Grid item xs={6}>
                              <p>Volumen</p>
                              <p>{data.totalVolume}</p>
                            </Grid>
                            <Grid item xs={6}>
                              <p>Peso</p>
                              <p> {data.totalWeight}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <p>Completados</p>
                          <p>{data.complete}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p>Segregacion</p>
                          <p>{data.segregatio}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        borderLeft: "1px solid #E5E5E5",
                        padding: "5px",
                      }}
                    >
                      <Grid item xs={12}>
                        <p
                          className={
                            this.props.classes.state +
                            " status" +
                            (data.hasIncidents === 0
                              ? data.estatus === "Pendiente"
                                ? " background-yellow-secondary"
                                : data.estatus === "Finalizado"
                                ? " background-greenDark"
                                : data.estatus === "En proceso"
                                ? " background-sky-blue"
                                : " "
                              : " background-incident")
                          }
                          style={{
                            color: data.estatus === "-" ? "#000" : "#fff",
                            marginBottom: "10px",
                          }}
                        >
                          {data.hasIncidents === 0 ? data.estatus : "Incidente"}
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          className={this.props.classes.contentButton}
                        >
                          <Grid item xs={4}>
                            <button
                              type="button"
                              data-id={data.collectPointId}
                              data-namepoint={data.collectPointName}
                              data-numberpoint={data.collectPointNumber}
                              className={
                                "btn-table " +
                                (data.hasIncidents === 0 ? "disab" : " ")
                              }
                              disabled={data.hasIncidents === 0 ? true : false}
                              onClick={async (e) =>
                                await this.handleClickModalIncident(e, idP)
                              }
                            >
                              <ReportProblemIcon />
                            </button>
                          </Grid>
                          <Grid item xs={4}>
                            <button
                              type="button"
                              data-id={data.collectPointId}
                              data-namepoint={data.collectPointName}
                              className="btn-table"
                              onClick={async (e) =>
                                await this.handleOpenModal(e, idV, idP)
                              }
                            >
                              <CropFreeIcon />
                            </button>
                          </Grid>
                          <Grid item xs={4}>
                            <button
                              type="button"
                              data-id={data.collectPointId}
                              data-namepoint={data.collectPointName}
                              className="btn-table"
                              onClick={async (e) =>
                                await this.handleOpenModal2(e)
                              }
                            >
                              <ModeCommentIcon />
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Modal
          openModal1={this.state.openModal1}
          handleClose={this.handleClose}
          id={this.state.id}
          namePoint={this.state.name}
          datamodal={this.state.dataModal}
        />
        <ModalQR
          openModal2={this.state.openModal2}
          handleClose={this.handleCloseModal2}
          id={this.state.id}
          namePoint={this.state.name}
        />
        <ModalIncidencias
          openModal3={this.state.openModal3}
          handleClose={this.handleCloseModal3}
          id={this.state.id}
          pointNumber={this.state.number}
          namePoint={this.state.name}
          datamodal={this.state.dataIncident}
        />
      </Grid>
    );
  }
}

export default withStyles(style)(CardTableOperat);
