import React, { Component } from "react";
import Chart from "react-google-charts";

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graficsBarras: [["Gerencia", props.title, { role: "annotation" }]]
    };
  }

  componentWillMount() {
    const { graficsBarras } = this.state;
    if (this.props.data.length === 0) {
      graficsBarras.push([]);
      this.setState({ graficsBarras });
    } else {
      this.props.data.forEach(v => {
        const newValue = Number(v.value);
        graficsBarras.push([v.name, newValue, newValue + "kg"]);
      });
      this.setState({ graficsBarras });
    }
  }
  render() {
    if (this.props.data.length === 0)
      return (
        <div
          style={{
            width: " 100%",
            height: " 100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h3>No hay data disponible para visualizar</h3>
        </div>
      );

    return (
      <Chart
        width={"100%"}
        height={"250px"}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={this.state.graficsBarras}
        options={{
          chartArea: { width: "40%", height: "70%" },
          bar: { groupWidth: "21%" },
          hAxis: {
            minValue: 0,
            gridlines: {
              count: 0
            },
            textStyle: {
              fontSize: 12,
              color: "#999999",
              bold: true
            },
            baseline: 6
          },
          vAxis: {
            textStyle: {
              fontSize: 12,
              color: "#999999",
              bold: true
            },
            baseline: 6
          },
          legend: {
            position: "none"
          },
          colors: ["#ec6a05"]
        }}
        // For tests
        rootProps={{ "data-testid": "4" }}
      />
    );
  }
}
export default Charts;
