import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import style from "../../assets/jss/disal-main/components/Header";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: localStorage.getItem("type") === "4" ? "Salidas" : "Operation"
    };
  }
  async componentDidMount() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1]
    });
  }
  async componentWillReceiveProps() {
    const arrayRef = window.location.href.split("/");
    await this.setState({
      name: arrayRef[arrayRef.length - 1]
    });
  }

  handleMenu = async e => {
    await this.setState({
      name: e.currentTarget.name
    });
  };

  render() {
    return (
      <Hidden mdDown>
        <AppBar className={this.props.classes.appBar} position="static">
          {
            <List className={this.props.classes.list}>
              <Link
                to="/Main-Sesion/Admin/Gerencia"
                className={
                  this.props.classes.item +
                  (this.state.name === "Gerencia" ? " selectActive" : "")
                }
                name="Gerencia"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Gerencias
                </ListItem>
              </Link>
              <Link
                to="/Main-Sesion/Admin/Usuarios"
                className={
                  this.props.classes.item +
                  (this.state.name === "Usuarios" ? " selectActive" : "")
                }
                name="Usuarios"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Usuarios
                </ListItem>
              </Link>
              <Link
                to="/Main-Sesion/Admin/Vehiculos"
                className={
                  this.props.classes.item +
                  (this.state.name === "Vehiculos" ? " selectActive" : "")
                }
                name="Vehiculos"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Vehículos
                </ListItem>
              </Link>
              <Link
                to="/Main-Sesion/Admin/Punto-de-Acopio"
                className={
                  this.props.classes.item +
                  (this.state.name === "Punto-de-Acopio" ? " selectActive" : "")
                }
                name="Punto-de-Acopio"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Puntos de acopio
                </ListItem>
              </Link>
              <Link
                to="/Main-Sesion/Admin/Routes"
                className={
                  this.props.classes.item +
                  (this.state.name === "Routes" ? " selectActive" : "")
                }
                name="Routes"
                onClick={this.handleMenu}
              >
                <ListItem button className={this.props.classes.itemLink}>
                  Rutas
                </ListItem>
              </Link>
            </List>
          }
        </AppBar>
      </Hidden>
    );
  }
}

export default withStyles(style)(Header);
