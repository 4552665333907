import {
  OBTENER_DATA_OPERATORS,
  LOADING_DATA_OPERATORS,
  LOADING_DATA_OPERATORS_TYPE,
  OBTENER_DATA_OPERATORS_TYPE,
  MESSAGE_DELETE_OPERATOR,
  CREATE_UPDATE_OPERATOR,
  UPDATE_DATA_OPERATORS
} from "../Types/Operator";
import {
  EndPointGetOperators,
  EndPointGetOperatorType,
  EndPointDeleteOperator,
  EndPointGetOperatorsEspecific
} from "../Services/EndPointOperator";
import { HandleOpenUser } from "./ModalActions";

export const GetOperators = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_OPERATORS
  });

  const respuestaOperators = await EndPointGetOperators();
  dispatch({
    type: OBTENER_DATA_OPERATORS,
    payload: respuestaOperators.data.data
  });
};
export const GetOperatorsId = (id, dni, name, type) => async (
  dispatch,
  getState
) => {
  const value = type === 0 ? null : type;
  const ids = id === "" ? null : id;
  const document = dni === "" ? null : dni;
  const nombre = name === "" ? null : encodeURIComponent(name);
  const respuestaDepartments = await EndPointGetOperatorsEspecific(
    ids,
    document,
    nombre,
    value
  );

  dispatch({
    type: UPDATE_DATA_OPERATORS,
    payload: respuestaDepartments.data.data
  });
};
export const GetOperatorType = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_DATA_OPERATORS_TYPE
  });

  const respuestaOperatorType = await EndPointGetOperatorType();
  dispatch({
    type: OBTENER_DATA_OPERATORS_TYPE,
    payload: respuestaOperatorType.data.data
  });
};
export const DeleteOperator = id => async (dispatch, getState) => {
  const respuestaOperator = await EndPointDeleteOperator(id);
  if (!respuestaOperator.data.success) {
    dispatch(HandleOpenUser());
    dispatch({
      type: MESSAGE_DELETE_OPERATOR,
      payload: respuestaOperator.data.message
    });
  } else {
    dispatch(GetOperators());
  }
};

export const CreateUpdateOperator = () => async (dispatch, getState) => {
  dispatch({
    type: CREATE_UPDATE_OPERATOR
  });
};
