import React, { Component } from "react";
import { connect } from "react-redux";
import CardChart from "../CardTab/CardTabBar";
import Spinner from "../Spinner";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import style from "../../assets/jss/disal-main/components/Card";
class Charts extends Component {
  ponerContenido = () => {
    const { loadingRank, totalDataRank } = this.props;

    if (loadingRank) {
      return <Spinner />;
    }
    if (!totalDataRank.length) return;
    return (
      <div className={this.props.classes.contentChart}>
        <h4>{totalDataRank[0].title}</h4>

        <div>
          <CardChart
            id={totalDataRank[0].id}
            title={totalDataRank[0].title}
            codigo={totalDataRank[0].codigo}
            data={totalDataRank[0].data}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className={this.props.classes.cardContent}
      >
        {this.ponerContenido()}
      </Grid>
    );
  }
}
const mapStateToProps = ({ dashboardReducer }) => {
  return dashboardReducer;
};

export default connect(mapStateToProps)(withStyles(style)(Charts));
