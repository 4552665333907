const backgroundPrimaryBlue = "#1c4d9d";
const backgroundPrimaryYellow = "#ff931f";
const backgroundPrimaryGreenDark = "#009b67";
const backgroundPrimarySkyBlue = "#3fa9f5";
const backgroundSecondaryYellow = "#ff931e";
const backgroundPrimaryRed = "#ff0000";

const primaryGreen = "#009b67";
const primaryGray = "#313131";
const secondaryGray = "#808080";
const defaultFont = {
  fontFamily: '"Roboto"',
  fontWeight: "300",
  lineHeight: "1.5em"
};

const paddingRight = {
  paddingRight: "0.9375rem"
};
const paddingLeft = {
  paddingLeft: "0.9375rem"
};
export {
  defaultFont,
  backgroundPrimaryBlue,
  backgroundPrimaryYellow,
  backgroundPrimaryGreenDark,
  backgroundPrimarySkyBlue,
  backgroundSecondaryYellow,
  primaryGreen,
  backgroundPrimaryRed,
  primaryGray,
  secondaryGray,
  paddingLeft,
  paddingRight
};
