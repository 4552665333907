import React, { Component } from "react";
import Header from "../../Component/Header/HeaderOperation";
import HeaderAdmin from "../../Component/Header/HeaderAdmin";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Dash from "../../Views/Dash";
import Report from "../../Views/Report";
import Operation from "../../Views/Operation";
import Salidas from "../../Views/Salidas";
import Ingresos from "../../Views/Ingresos";
import Stock from "../../Views/Stock";
import PageAdmin from "./PageAdmin";

import { Switch, Redirect, Route } from "react-router-dom";

class PageOperation extends Component {
  constructor() {
    super();
    this.state = {
      nameHeader: null
    };
  }
  async componentDidMount() {
    const arrayRef = window.location.href;
    if (arrayRef.indexOf("Admin") > -1) {
      await this.setState({
        nameHeader: "Admin"
      });
    } else {
      await this.setState({
        nameHeader: null
      });
    }
  }
  async componentWillReceiveProps() {
    const arrayRef = window.location.href;
    if (arrayRef.indexOf("Admin") > -1) {
      await this.setState({
        nameHeader: "Admin"
      });
    } else {
      await this.setState({
        nameHeader: null
      });
    }
  }
  render() {
    return (
      <div>
        <div className="page-admin">
          {this.state.nameHeader === "Admin" ? (
            <HeaderAdmin history={this.props.history} />
          ) : (
            <Header history={this.props.history} />
          )}
          <Sidebar
            open={this.props.open}
            handleChange={this.props.handleChange}
          />
          <Switch>
            <Route path="/Main-Sesion/Operation" component={Operation} />
            <Route path="/Main-Sesion/Ingresos" component={Ingresos} />
            <Route path="/Main-Sesion/Salidas" component={Salidas} />
            <Route path="/Main-Sesion/Stock" component={Stock} />
            <Route path="/Main-Sesion/Report" component={Report} />
            <Route path="/Main-Sesion/Dashboard" component={Dash} />
            <Route path="/Main-Sesion/Admin" component={PageAdmin} />

            <Redirect
              to={
                localStorage.getItem("type") === "4"
                  ? "/Main-Sesion/Salidas"
                  : "/Main-Sesion/Operation"
              }
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default PageOperation;
