import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import * as pointAcopioAction from "../../Actions/PointAcopioAction";
import * as routesAction from "../../Actions/RoutesAction";
import List from "@material-ui/core/List";

import * as modalAction from "../../Actions/ModalActions";
import { DragDropContext } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import style from "../../assets/jss/disal-main/Layouts/Route";
// import Modal from "../Modal/ModalExitoRoutes";
import Checkbox from "@material-ui/core/Checkbox";
import Column from "./Column";
import { EndPointCreateUpdateRoute } from "../../Services/EndPointRoutes";
const { HandleOpenExitRoute } = modalAction;
const { CreateUpdateRoute, GetRoutesList } = routesAction;

const {
  GetPointAcopio,
  GetPointAcopioContainer,
} = pointAcopioAction;

class PointAcopio extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      code: "",
      description: "",
      sapContractNumber: "",
      sapContractPosition: "",
      sapContractDispositionPosition: "",
      vehicle: 0,
      point: 0,
      showButtonCopy: "show",
      addData: [],
      newData: [],
      daysChange: [
        { dayId: 1, dayName: "LUNES", checked: false },
        { dayId: 2, dayName: "MARTES", checked: false },
        { dayId: 3, dayName: "MIERCOLES", checked: false },
        { dayId: 4, dayName: "JUEVES", checked: false },
        { dayId: 5, dayName: "VIERNES", checked: false },
        { dayId: 6, dayName: "SABADO", checked: false },
        { dayId: 7, dayName: "DOMINGO", checked: false },
      ],
      days: [
        { dayId: 1, dayName: "LUNES", checked: false },
        { dayId: 2, dayName: "MARTES", checked: false },
        { dayId: 3, dayName: "MIERCOLES", checked: false },
        { dayId: 4, dayName: "JUEVES", checked: false },
        { dayId: 5, dayName: "VIERNES", checked: false },
        { dayId: 6, dayName: "SABADO", checked: false },
        { dayId: 7, dayName: "DOMINGO", checked: false },
      ],
      error: {
        days: "",
        point: "",
        vehicle: "",
        addData: "",
      },
    };
  }
  async componentDidMount() {
    if (Object.values(this.props.data).length > 0) {
      const result = this.props.data.routes.map((res) => {
        const result1 = this.state.days.map((element, index) => {
          let data = {
            dayId: element.dayId,
            dayName: element.dayName,
            checked: false,
          };
          res.days.forEach((result) => {
            if (result.dayId === element.dayId) {
              data.checked = true;
            }
          });

          return data;
        });

        let data = {
          collectPointId: res.collectPointId,
          collectPointName: res.collectPointName,
          pointNumber: res.pointNumber,
          days: result1,
        };
        return data;
      });

      await this.setState({
        id: Number(this.props.idRoute),
        addData: result,
        code: this.props.data.code === null ? "" : this.props.data.code,
        description:
          this.props.data.description === null
            ? ""
            : this.props.data.description,
        sapContractNumber: this.props.data.sapContractNumber === null ? "" : this.props.data.sapContractNumber,
        sapContractPosition: this.props.data.sapContractPosition === null ? "" : this.props.data.sapContractPosition,
        sapContractDispositionPosition: this.props.data.sapContractPosition === null ? "" : this.props.data.sapContractDispositionPosition,
        vehicle: Number(this.props.data.vehicleId),
        point: 0,
        error: {
          days: "",
          point: "",
          addData: "",
        },
      });
    } else {
      await this.setState({
        id: Number(this.props.idRoute),
        vehicle: 0,
        point: 0,
        addData: [],
        error: {
          days: "",
          vehicle: "",
          addData: "",
        },
      });
    }
  }
  componentWillReceiveProps = async (nextprops) => {
    if (Object.values(nextprops.data).length > 0) {
      const result = nextprops.data.routes.map((res) => {
        const result1 = this.state.days.map((element, index) => {
          let data = {
            dayId: element.dayId,
            dayName: element.dayName,
            checked: false,
          };
          res.days.forEach((result) => {
            if (result.dayId === element.dayId) {
              data.checked = true;
            }
          });
          return data;
        });

        let data = {
          collectPointId: res.collectPointId,
          collectPointName: res.collectPointName,
          pointNumber: res.pointNumber,
          days: result1,
        };
        return data;
      });
      await this.setState({
        id: Number(nextprops.idRoute),
        addData: result,
        vehicle: Number(nextprops.data.vehicleId),
        code: nextprops.data.code === null ? "" : nextprops.data.code,
        sapContractNumber: nextprops.data.sapContractNumber === null ? "" : nextprops.data.sapContractNumber,
        sapContractPosition: nextprops.data.sapContractPosition === null ? "" : nextprops.data.sapContractPosition,
        sapContractDispositionPosition: nextprops.data.sapContractDispositionPosition === null ? "" : nextprops.data.sapContractDispositionPosition,
        description:
          nextprops.data.description === null ? "" : nextprops.data.description,
        point: 0,
        error: {
          days: "",
          point: "",
          vehicle: "",
          addData: "",
        },
      });
    } else {
      await this.setState({
        id: Number(nextprops.idRoute),
        vehicle: 0,
        point: 0,
        addData: [],
        error: {
          days: "",
          vehicle: "",
          addData: "",
        },
      });
    }
  };


  handleInputTypeNumber = (e) => {
    const name = e.currentTarget.name;
    let value = e.currentTarget.value;
  
    if(isNaN(Number(value))){
      return;
    }else{
      this.setState({
        [name]: value,
      });
    }
  }

  handleChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await this.setState({
      [name]: value,
    });
  };



  handleChangeBox = (e) => {
    const { daysChange } = this.state;
    const id = e.target.id;
    const value = e.target.checked;
    daysChange[id].checked = value;
    this.setState({
      daysChange,
    });
  };
  handleChangeCheckNew = (e) => {
    const { addData } = this.state;
    const index = e.target.id;
    const name = Number(e.target.name);
    const value = e.target.checked;
    addData[name].days[index].checked = value;
    this.setState({
      addData,
    });
  };
  handleCancel = () => {
    this.setState({
      id: "",
      name: "",
      number: "",
      latitude: "",
      longitude: "",
      gerencia: 0,
      addData: [],
      error: {
        name: "",
        number: "",
        latitude: "",
        longitude: "",
        gerencia: "",
        addData: "",
      },
    });
    this.props.handleCancel();
  };
  handleDelete = (e) => {
    const { addData } = this.state;
    const id = e.currentTarget.id;
    const newResult = addData.filter((res, index) => {
      return index !== Number(id);
    });
    this.setState({
      addData: newResult,
    });
  };
  handleSave = async () => {
    const { addData } = this.state;
    const obj = {};
    const newResult = addData.map((res) => {
      const daysFilter = res.days.filter((resF) => resF.checked === true);
      const arrayDays = daysFilter.map((resD) => resD.dayId);

      let data = {
        collectPointId: res.collectPointId,
        dayId: arrayDays,
      };
      return data;
    });

    let data = {
      vehicleId: this.state.vehicle === 0 ? null : this.state.vehicle,
      description: this.state.description,
      routeCode: this.state.code,
      sapContractNumber: this.state.sapContractNumber,
      sapContractPosition: Number(this.state.sapContractPosition),
      sapContractDispositionPosition: Number(this.state.sapContractDispositionPosition),
      routeArray: newResult,
    };

    const AddId =
      this.state.id !== ""
        ? Object.assign(data, { routeId: this.state.id })
        : data;

    if (this.state.description !== "") {
      const respuestaVehicle = await EndPointCreateUpdateRoute(AddId);
      if (respuestaVehicle.data.success) {
        await this.props.HandleOpenExitRoute();
        await this.props.CreateUpdateRoute();

        await this.props.GetRoutesList();
        await this.props.handleClean();
        await this.props.handleCancel();
        this.setState({
          showButtonCopy: "show",
          error: {},
        });
      } else {
        respuestaVehicle.data.message.map((res) => {
          if (res.field === "vehicleId") {
            obj.messageSave = res.errorMessages[0];
          }
          return res;
        });
        await this.setState({
          error: obj,
        });
      }
    } else {
      if (this.state.description === "") {
        obj.description = "* Completar el campo";
      }
      await this.setState({
        error: obj,
      });
    }
  };
  handleCopy = () => {
    this.setState({
      id: "",
      code: "(COPIA)" + this.state.code,
      description: "(COPIA)" + this.state.description,
      vehicle: 0,
      showButtonCopy: "none",
      error: {},
    });
  };
  handleAdd = () => {
    const { point, addData, error, daysChange } = this.state;
    const newResult = daysChange.filter((res) => res.checked === true);
    const getContainerName = this.props.pointAcopioReducer.points.filter(
      (res) => res.collectPointId === point
    );

    if (newResult.length === 0) {
      error.days = "*Selecciona una opcion";
    } else {
      error.days = "";
    }
    if (point === 0) {
      error.point = "*Selecciona una opcion";
    } else {
      error.point = "";
    }
    this.setState({ error });
    if (point !== 0 && newResult.length !== 0) {
      addData.push({
        collectPointId: getContainerName[0].collectPointId,
        collectPointName: getContainerName[0].collectPointDescription,
        pointNumber: getContainerName[0].pointNumber,
        days: daysChange,
      });

      this.setState({
        addData,
        error: {
          days: "",
          point: "",
          vehicle: "",
          addData: "",
        },
        point: 0,

        daysChange: [
          { dayId: 1, dayName: "LUNES", checked: false },
          { dayId: 2, dayName: "MARTES", checked: false },
          { dayId: 3, dayName: "MIERCOLES", checked: false },
          { dayId: 4, dayName: "JUEVES", checked: false },
          { dayId: 5, dayName: "VIERNES", checked: false },
          { dayId: 6, dayName: "SABADO", checked: false },
          { dayId: 7, dayName: "DOMINGO", checked: false },
        ],
      });
    }
  };
  onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    const { addData } = this.state;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const getData = this.state.addData.filter(
      (res) => res.collectPointId === Number(draggableId)
    );
    addData.splice(source.index, 1);
    addData.splice(destination.index, 0, getData[0]);
    await this.setState({ addData });
  };
  render() {
    
    return (
      <div className={this.props.classes.contentForm}>
        <Grid container>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>ID </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    disabled
                    name="id"
                    value={this.state.id}
                    onChange={this.handleChange}
                    variant="outlined"
                    className={this.props.classes.textFielInput + " blocked-id"}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Código </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="code"
                    value={this.state.code}
                    onChange={this.handleChange}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    className={this.props.classes.textFielInput}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Descripción </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    className={this.props.classes.textFielInput}
                  />
                  <p>
                    {this.state.error.description === undefined
                      ? ""
                      : this.state.error.description}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>N° Contrato</label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="sapContractNumber"
                    value={this.state.sapContractNumber}
                    onChange={this.handleChange}
                    variant="outlined"
                    inputProps={{
                      maxLength: 10,
                    }}
                    className={this.props.classes.textFielInput}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Posición de recolección </label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="sapContractPosition"
                    value={this.state.sapContractPosition}
                    onChange={this.handleInputTypeNumber}
                    variant="outlined"
                    inputProps={{
                      maxLength: 6,
                    }}
                    className={this.props.classes.textFielInput}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Posición de disposición</label>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="sapContractDispositionPosition"
                    value={this.state.sapContractDispositionPosition}
                    onChange={this.handleInputTypeNumber}
                    variant="outlined"
                    inputProps={{
                      maxLength: 6,
                    }}
                    className={this.props.classes.textFielInput}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>



          <Grid item xs={12}>
            <div className="inputs-form">
              <Grid container>
                <Grid item xs={2}>
                  <label>Vehiculo </label>
                </Grid>
                <Grid item xs={10}>
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="vehicle"
                      value={this.state.vehicle}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.vehicleReducer.vehicle.map((res) => {
                        return (
                          <MenuItem value={res.vehicleId}>
                            {res.plateNumber}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p>
                    {this.state.error.messageSave === undefined
                      ? ""
                      : this.state.error.messageSave}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div>
          <h3 className={this.props.classes.title}>Contenedores</h3>
          <Grid container>
            <Grid item xs={12} className={this.props.classes.detailItem}>
              <Grid container>
                <Grid item xs={1}>
                  <p>#</p>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingRight: "10px",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={this.props.classes.formControl}
                  >
                    <Select
                      name="point"
                      value={this.state.point}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={0}>Selecciona una opción</MenuItem>
                      {this.props.pointAcopioReducer.points.map((res) => {
                        return (
                          <MenuItem value={res.collectPointId}>
                            {`${res.pointNumber}, ${res.collectPointDescription}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    {this.state.error.point === undefined
                      ? ""
                      : this.state.error.point}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <Grid container style={{ justifyContent: "flex-end" }}>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Lu
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="lun"
                            id={0}
                            checked={this.state.daysChange[0].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.handleChangeBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Ma
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="mar"
                            id={1}
                            checked={this.state.daysChange[1].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.handleChangeBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Mi
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="mie"
                            id={2}
                            checked={this.state.daysChange[2].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.handleChangeBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Ju
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="jue"
                            id={3}
                            checked={this.state.daysChange[3].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.handleChangeBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Vi
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="vie"
                            id={4}
                            checked={this.state.daysChange[4].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.handleChangeBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Sa
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="sab"
                            id={5}
                            checked={this.state.daysChange[5].checked}
                            className={this.props.classes.checkBox}
                            onChange={this.handleChangeBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={this.props.classes.contentCheckBox}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          Do
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name="dom"
                            id={6}
                            checked={this.state.daysChange[6].checked}
                            onChange={this.handleChangeBox}
                            className={this.props.classes.checkBox}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <p
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    {this.state.error.days === undefined
                      ? ""
                      : this.state.error.days}
                  </p>
                </Grid>
                <Grid item xs={1}>
                  <button
                    onClick={this.handleAdd}
                    className={this.props.classes.iconAddContent}
                  >
                    <AddIcon />
                  </button>
                </Grid>
                <p className="err">{this.state.error.addData}</p>
              </Grid>
            </Grid>
            <Grid item xs={12} className={this.props.classes.listContent}>
              <List className={this.props.classes.listContainer}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Column
                    addData={this.state.addData}
                    handleChangeCheckNew={this.handleChangeCheckNew}
                    handleDelete={this.handleDelete}
                  />
                </DragDropContext>
              </List>
            </Grid>
          </Grid>
        </div>
        <div className={this.props.classes.contentButton}>
          {this.state.showButtonCopy === "none" ? null : (
            <button onClick={this.handleCopy}>Copiar</button>
          )}

          <button onClick={this.handleCancel}>Cancelar</button>
          <button onClick={this.handleSave}>Guardar</button>
        </div>
        {/* <Modal /> */}
      </div>
    );
  }
}
const mapStateToProps = ({
  routeReducer,
  vehicleReducer,
  pointAcopioReducer,
}) => {
  return { routeReducer, vehicleReducer, pointAcopioReducer };
};
const mapDispatchToProps = {
  GetPointAcopio,
  GetPointAcopioContainer,
  CreateUpdateRoute,
  HandleOpenExitRoute,
  GetRoutesList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PointAcopio));
