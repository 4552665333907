import React, { Component } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
class Charts extends Component {
  render() {
    return (
      <div
        className="table-responsive dash"
        style={{
          width: " 100%",
          height: " 100%"
        }}
      >
        {this.props.data.length === 0 ? (
          <div
            style={{
              width: " 100%",
              height: " 100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <h3>No hay data disponible para visualizar</h3>
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>GERENCIA</th>
                <th>CUMPLIMIENTO %</th>
              </tr>
            </thead>
            <tbody className="">
              {this.props.data.map((data, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{data.name}</td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {data.hasIncreased === true ? (
                        <ArrowUpwardIcon className="greenColor" />
                      ) : (
                        <ArrowDownwardIcon className="redColor" />
                      )}
                      {data.value}
                      {"%"}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
export default Charts;
