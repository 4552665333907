const appStyle = theme => ({
  cardContent: {
    padding: "15px"
  },
  contentChart: {
    backgroundColor: "#ffffff",
    boxShadow: "2px 2px 8px 0 rgba(140, 140, 140, 0.5)",
    padding: "calc(15px + 1rem - 10px)",
    height: "100%",
    "& h4": {
      color: "#353535",
      fontSize: "17px",
      fontWeight: "500",
      margin: "0",
      paddingBottom: "15px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px"
      }
    },
    "& .MuiTypography-body1": {
      width: "100%",
      height: "300px"
    }
  },
  borderTop: {
    borderTop: "1px solid #dee2e6"
  }
});

export default appStyle;
