import React, { Component } from "react";
import { connect } from "react-redux";
import * as gerenciaAction from "../../Actions/GerenciaActions";
import * as modalAction from "../../Actions/ModalActions";
import { withStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import style from "../../assets/jss/disal-main/components/Modal";
const { GetDepartments, DeleteDepartments } = gerenciaAction;
const { HandleCloseExitGerencia } = modalAction;

class ModalDelete extends Component {
  render() {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={this.props.classes.modal}
        open={this.props.modalReducer.openExitGerencia}
        onClose={this.props.HandleCloseExitGerencia}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.props.modalReducer.openExitGerencia}>
          <div className={this.props.classes.paper} style={{ width: "500px" }}>
            <div className="modal-confirm">
              <h3>Guardado</h3>
              <p>La gerencia se guardo con éxito</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-modal-salida"
                style={{ textDecoration: "underline", marginRight: "10px" }}
                onClick={() => this.props.HandleCloseExitGerencia()}
              >
                OK
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}
const mapStateToProps = ({ modalReducer }) => {
  return { modalReducer };
};
const mapDispatchToProps = {
  GetDepartments,
  DeleteDepartments,
  HandleCloseExitGerencia
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ModalDelete));
