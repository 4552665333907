const appStyle = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .title-section ": {
      color: "#050505",
      fontFamily: '"Roboto"',
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "16px",
      textAlign: "left"
    },
    "& .detail-modal": {
      color: "#0a0a0a",
      fontFamily: '"Roboto"',
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "26px",
      textAlign: "left"
    },
    "& .ant-input,& .ant-time-picker-input": {
      borderRadius: "0px"
    }
  },
  contentModal: {
    paddingRight: "15px",
    paddingBottom: "0",
    height: "500px",
    "& .MuiTableCell-root": {
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        padding: "5px",
        lineHeight: "normal"
      }
    }
  },
  containerViewSalida: {
    paddingRight: "15px",
    paddingBottom: "0"
  },
  formControl: {
    width: "100%",
    paddingTop: "10px",
    "& .MuiOutlinedInput-input": {
      paddingTop: "5px",
      paddingBottom: "5px"
    },
    "& .MuiSelect-outlined, & .MuiOutlinedInput-root,": {
      borderRadius: "0px"
    }
  },
  paper: {
    width: 800,

    backgroundColor: theme.palette.background.paper,
    position: "relative",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]: {
      width: 320,
      padding: "5px"
    },
    "& .modalQR": {
      padding: "0 25px"
    },
    "& h4,& h5": {
      fontFamily: "'Roboto'",
      fontSize: "16px",
      lineHeight: "19px",
      "& span": {
        fontWeight: "bold"
      }
    }
  },
  modalBody: {
    "& label": {
      fontSize: "15px",
      fontFamily: "'Roboto'",
      marginBottom: "10px",
      fontWeight: "700",
      textTransform: "uppercase"
    },
    "& hr": {
      border: "0",
      borderTop: "1px solid rgba(0,0,0,.1)"
    },
    "& p": {
      marginTop: "10px"
    }
  },
  contentFotos: {
    marginTop: "35px"
  },
  contentImage: { width: "100px" },
  modalHeader: {
    paddingTop: "17px",
    paddingBottom: "11px",
    borderBottom: "none",
    borderRadius: "0",
    "& .btn-close": {
      position: "absolute",
      zIndex: "5",
      top: "0",
      right: "15px",
      backgroundColor: "transparent",
      border: "0",
      padding: "6px 0"
    },
    "& .closeSpan": {
      width: "30px",
      height: "40px",
      position: "relative",
      display: "inline-block",
      overflow: "hidden",
      opacity: "1",
      "&::before, &::after": {
        backgroundColor: "#1c4d9d",
        content: '""',
        position: "absolute",
        height: "2px",
        width: "100%",
        top: "50%",
        left: "0",
        marginTop: "-1px"
        // background: "#fff"
      }
    }
  },
  contentInput: {},
  modalTitle: {
    color: "#000000",
    fontFamily: '"Roboto"',
    width: "550px",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    [theme.breakpoints.down("md")]: {
      width: 320,
      padding: "5px"
    },
    "& span": {
      fontWeight: "bold"
    }
  },
  img: {
    width: "100%"
  }
});

export default appStyle;
