
import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import style from "../assets/jss/disal-main/Layouts/Operation";
import Grid from "@material-ui/core/Grid";
import RoomIcon from "@material-ui/icons/Room";
import Drawer from "@material-ui/core/Drawer";

// ACTIONS
import * as operationAction from "../Actions/OperationActions";
import Map from "../Component/MapsOperation";
import MapMobile from "../Component/MapsOperationMobile";

import Slide from "../Component/Slide/Slide";
import ContentOperation from "../Component/Content/ContentOperation";
import ContentGeneral from "../Component/Content/ContentGeneral";
import calendarIcon from "../assets/img/calendario icon.svg";
import masIcon from "../assets/img/add.svg";

import pendingIcon from "../assets/img/pendientes icon.svg";
import checkIcon from "../assets/img/check icon.svg";
import mapIcon from "../assets/img/mundo icon.svg";
import Pagination from "../Component/Pagination/PaginationReestructure";
import VerResultados from "./../Component/Pagination/VerResultados";

import { getCurrentDate } from "../Component/date";
const {
  GetVehiclesOperation,
  GetVehicleDataTable,
  UpdateVehicleTable,
  GetVehicleById,
  UpdateStatusTable,
  GetMonitoringPoints,
  GetVehicleNoProgramated,
} = operationAction;
class Operation extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      infoOpen: false,
      infoOpenMobile: false,

      totalRecords: "",
      totalPages: "",
      pageLimit: "",
      currentPage: "",
      initialPage: 1,
      startIndex: "",
      endIndex: "",
      openMap: false,
    };
  }
  componentDidMount = async () => {
    const { operationReducer } = this.props;
    if (!operationReducer.arrayVehicle.length) {
      await this.props.GetVehiclesOperation();
    }
    if (!operationReducer.coordMap.length) {
      await this.props.GetVehicleDataTable();
    }
    await this.props.GetMonitoringPoints();
  };
  componentWillUnmount = async () => {
    await this.setState({
      pageLimit: "",
      totalPages: "",
      currentPage: "",
      initialPage: 1,

      startIndex: "",
      endIndex: "",
    });
  };

  handleClickMap = async (e) => {
    await this.setState({ open: !this.state.open });
  };

  onChangePage = async (data) => {
    await this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
      startIndex: data.startIndex,
      endIndex: data.endIndex,
    });
  };

  handleClickVehicle = async (val) => {
    await this.setState({
      nameVehicle: val,
    });
  };

  handleInfoOpen = async (val) => {
    await this.setState({ infoOpen: val });
  };
  handleInfoOpenMobile = async (val) => {
    await this.setState({ infoOpenMobile: val });
  };
  handleClick = async () => {
    await this.setState({ open: !this.state.open });
  };

  handleClickMapMobileOpen = async () => {
    await this.setState({ openMap: true });
  };
  handleClickMapMobileClose = async () => {
    await this.setState({ openMap: false, infoOpenMobile: false });
  };
  componentWillReceiveProps = async () => {
    await this.setState({
      infoOpen: false,
      initialPage: 1,
      infoOpenMobile: false,
    });
  };
  render() {
    const { startIndex, endIndex } = this.state;
    let rowsPerPage1 = [];
    let rowsPerPage2 = [];
    rowsPerPage1 = this.props.operationReducer.dataTableGeneral.slice(
      startIndex,
      endIndex + 1
    );
    rowsPerPage2 = this.props.operationReducer.dataTableById.slice(
      startIndex,
      endIndex + 1
    );


    let mainContent;

    if (this.props.operationReducer.loading_data_Table) {
      mainContent = <div class="tabla-loader">
        <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>;
    } else {
      if (this.props.operationReducer.statusTable !== "general") {
        mainContent = (
          <ContentOperation
            status={this.props.operationReducer.statusTable}
            open={this.state.open}
            pageOfItems={rowsPerPage2}
          />
        );
      } else {
        mainContent = (
          <ContentGeneral
            pageOfItems={rowsPerPage1}
            open={this.state.open}
          />
        );
      }
    }

    return (
      <Grid container className={this.props.classes.operation}>
        <Grid item md={12}>
          <div className={this.props.classes.boxTitle}>
            <h4>Monitoreo de puntos generadores</h4>
            <p>
              <span>{getCurrentDate().date}</span>
              <span className={this.props.classes.slash}>/</span>
              <span>{getCurrentDate().monthNumber}</span>
              <span className={this.props.classes.slash}>/</span>
              <span>{getCurrentDate().year}</span>
            </p>
          </div>
        </Grid>
        <Grid item md={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={3}
              className={this.props.classes.paddingRight}
            >
              <div className={this.props.classes.boxMonitoring + " bcgBlue"}>
                <div className="img-icons">
                  <img src={calendarIcon} alt="Calendar Icon" />
                </div>
                <div className="info-monitoring">
                  <p className="main-word">Programados</p>
                  <p className="total-count">
                    <span>{this.props.operationReducer.total}</span>hoy
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={
                this.props.classes.paddingRight +
                " " +
                this.props.classes.paddingLeft
              }
            >
              <div className={this.props.classes.boxMonitoring + " bcgYellow"}>
                <div className="img-icons">
                  <img src={pendingIcon} alt="Pendiente Icon" />
                </div>
                <div className="info-monitoring">
                  <p className="main-word">Pendientes</p>
                  <p className="total-count">
                    <span>{this.props.operationReducer.pending}</span>hoy
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={
                this.props.classes.paddingRight +
                " " +
                this.props.classes.paddingLeft
              }
            >
              <div className={this.props.classes.boxMonitoring + " bcgGreen"}>
                <div className="img-icons">
                  <img src={checkIcon} alt="Check Icon" />
                </div>
                <div className="info-monitoring">
                  <p className="main-word">Finalizados</p>
                  <p className="total-count">
                    <span>{this.props.operationReducer.finished}</span>hoy
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={this.props.classes.paddingLeft}
            >
              <div className={this.props.classes.boxMonitoring + " bcgRed"}>
                <div className="img-icons">
                  <img src={masIcon} alt="Check Icon" />
                </div>
                <div className="info-monitoring">
                  <p className="main-word">No programados</p>
                  <p className="total-count">
                    <span>{this.props.operationReducer.noProgramated}</span>hoy
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          style={{ overflowX: "hidden" }}
          className={this.props.classes.slideStyle}
        >
          <Slide
            handleClickVehicle={this.handleClickVehicle}
            sliderWidth="220"
            sliderHeight="250"
            handleChangeStatusTable={this.handleChangeStatusTable}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={this.props.classes.tableStyle}>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid
              item
              sm={11}
              className={
                (this.state.open ? "otherStyle " : "") +
                this.props.classes.transtition
              }
            >
              <div className={this.props.classes.contentTitle}>
                <p className={this.props.classes.titleTable}>
                  {this.props.operationReducer.statusTable === "general"
                    ? "Mostrando resultados de la Vista General"
                    : "Mostrando resultados para el equipo"}
                  {this.props.operationReducer.statusTable === "general" ? (
                    ""
                  ) : (
                    <span> {this.props.operationReducer.nameVehicle}</span>
                  )}
                </p>
                <div className={this.props.classes.iconMap}>
                  <button onClick={this.handleClickMapMobileOpen}>
                    <RoomIcon />
                  </button>
                </div>
              </div>
              { mainContent }
              <div className={this.props.classes.infoPage}>
                <VerResultados
                  rowsPerPage={
                    this.props.operationReducer.statusTable !== "general"
                      ? rowsPerPage2
                      : rowsPerPage1
                  }
                  totalRowsPerPage={
                    this.props.operationReducer.statusTable !== "general"
                      ? this.props.operationReducer.dataTableById
                      : this.props.operationReducer.dataTableGeneral
                  }
                />
                <Pagination
                  totalRecords={
                    this.props.operationReducer.statusTable !== "general"
                      ? this.props.operationReducer.dataTableById.length
                      : this.props.operationReducer.dataTableGeneral.length
                  }
                  pageLimit={this.state.pageLimit || 10}
                  initialPage={1}
                  pagesToShow={3}
                  onChangePage={this.onChangePage}
                />
              </div>
            </Grid>
            <Grid
              item
              sm={1}
              className={
                this.props.classes.transtition +
                (this.state.open ? " otherStyle " : "")
              }
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  paddingLeft: "15px",
                }}
              >
                <div
                  className={
                    this.props.classes.btnSeeMap +
                    (this.state.open ? " otherStyleContent" : "")
                  }
                  onClick={this.handleClickMap}
                >
                  <p className={this.state.open ? " otherStyleMap" : ""}>
                    VER MAPA <img src={mapIcon} alt="Map Icon " />
                  </p>
                </div>
                <div
                  className={this.props.classes.map}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {this.state.open ? (
                    <button
                      onClick={this.handleClick}
                      className={this.props.classes.btnCloseMap}
                    >
                      <span className="closeSpan big"></span>
                    </button>
                  ) : (
                    ""
                  )}
                  {this.props.operationReducer.loading_data_Table ? (
                    <div>load</div>
                  ) : (
                    <Map
                      handleInfoOpen={this.handleInfoOpen}
                      infoOpen={this.state.infoOpen}
                      google={this.props.google}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Drawer
            open={this.state.openMap}
            onClose={this.handleClickMapMobileClose}
            className={this.props.classes.mapaMobile}
          >
            <div></div>
            <button
              onClick={this.handleClickMapMobileClose}
              className={"btnCloseMap"}
            >
              <span className="closeSpan big"></span>
            </button>
            <MapMobile
              handleInfoOpen={this.handleInfoOpenMobile}
              infoOpen={this.state.infoOpenMobile}
              google={this.props.google}
              open={this.state.openMap}
            />
          </Drawer>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = ({ operationReducer }) => {
  return { operationReducer };
};
const mapDispatchToProps = {
  GetVehiclesOperation,
  GetVehicleDataTable,
  UpdateVehicleTable,
  UpdateStatusTable,
  GetMonitoringPoints,
  GetVehicleById,
  GetVehicleNoProgramated,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Operation));
